import { getData, authGet, authPost, authPatch } from '../common'

export const getAccounts = () =>
    authGet({ url: `/account` })
        .catch((err) => {
            throw err
        })
        .then(getData)

export const getAccount = (accountId) =>
    authGet({ url: `/account/${accountId}` })
        .catch((err) => {
            throw err
        })
        .then(getData)

export const getUsersAccount = (accountId, params = null) => {
    return authGet({
        url: `/account/${accountId}/users`,
        params,
    }).then(getData)
}

export const addUserAccount = (accountId, data) => {
    return authPost({
        url: `/account/${accountId}/users`,
        data,
    }).then(getData)
}

export const assignRoleAccount = (accountId, data) => {
    return authPost({
        url: `/account/${accountId}/roles`,
        data,
    }).then(getData)
}

export const patchRolesAccount = (accountId, data) => {
    return authPatch({
        url: `/account/${accountId}/roles`,
        data,
    }).then(getData)
}
