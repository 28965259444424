const getExtension = (filename) => filename.split('.').slice(-1)[0] || ''

const bytesSuffix = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb']
const bytesToSize = (bytes) => {
    let lvl = 0
    while (bytes > 1024) {
        bytes /= 1000
        lvl += 1
    }
    return Number(bytes).toFixed(2) + bytesSuffix[lvl]
}

const downloadFile = async (filename, mime, data) => {
    const element = document.createElement('a')
    element.setAttribute(
        'href',
        'data:' + mime + ';charset=utf-8,' + encodeURIComponent(data),
    )
    element.setAttribute('download', filename)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()
}

const buildCSV = (data, fields) => {
    const sep = ','
    const header = fields.join(sep)
    const rows = data
        .map((item) => {
            return fields
                .map((field) => {
                    const value = item[field]
                    if (['string', 'object'].includes(typeof value)) {
                        return JSON.stringify(value)
                    }
                    return item[field]
                })
                .join(sep)
        })
        .join('\n')

    return `${header}\n${rows}`
}

export { getExtension, bytesToSize, buildCSV, downloadFile }
