import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@fluentui/react'

import { registerSingle, makeLabel } from './utils.js'

const boolValue = (first = false) => {
    if (typeof first === 'string') {
        return first === 'true'
    } else {
        return first
    }
}

const SectionInput = ({ schema, readonly = false }) => {
    const {
        name,
        title,
        required,
        default: defaultValue = false,
        calc,
    } = schema

    // Don't allow calculated fields to be edited
    if (calc) {
        readonly = true
    }

    const [indeterminate, setIndeterminate] = React.useState(required || false)

    const { onChange, value, errorMessage } = registerSingle(name)
    const onRenderLabel = makeLabel(schema)

    const _onChange = (e, value) => {
        if (indeterminate) {
            setIndeterminate(false)
            onChange(null, String(defaultValue))
        } else {
            onChange(null, String(value))
        }
    }

    const opts = {
        label: title,
        checked: boolValue(value),
        indeterminate,
        onRenderLabel,
        onChange: _onChange,
        disabled: readonly,
    }

    return (
        <>
            <Checkbox {...opts} />
            {errorMessage}
        </>
    )
}

SectionInput.propTypes = {
    schema: PropTypes.any,
}

export default SectionInput
