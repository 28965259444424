import React from 'react'
import {
    CommandBar,
    Dropdown,
    Panel,
    Persona,
    PersonaSize,
    TextField,
    Text,
    Stack,
} from '@fluentui/react'
import { getUsersAccount, updateTransactionRole } from '../../../api'
import { useAuth } from '../../../hooks/useAuth'
const accessOptions = [
    {
        key: 'standard',
        text: 'Standard',
    },
    {
        key: 'view',
        text: 'View Only',
    },
]

const roleOptions = (roles) => {
    return roles.map(({ role }) => {
        return { key: role, text: role }
    })
}

const getTeamForRole = (roles, roleName) => {
    const role = roles.find(({ role }) => role === roleName)
    return role ? role.team : ''
}

const RoleAssignPanel = ({
    open = false,
    setOpenTrue = () => {},
    setOpenFalse = () => {},
    roles = [],
    transactionId = '',
    loadData,
}) => {
    // console.log('ROLES', roles)
    const { currentAccount } = useAuth()
    // console.log('ROLES', roles)
    const firstRole = roles[0]
    const isRestricted = !!firstRole.restrict
    const fixedRole = roles.length == 1

    // console.log('YOYO', firstRole, isRestricted, fixedRole)
    // const defaultRole = roles[0].role
    const [values, setValues] = React.useState({
        email: '',
        firstName: '',
        lastName: '',
        access: 'standard',
        // role: defaultRole,
        // team: getTeamForRole(roles, defaultRole),
    })
    const [users, setUsers] = React.useState([])
    const [loadingUsers, setLoadingUsers] = React.useState(false)
    const [showNameField, setShowNameField] = React.useState(false)

    const setValue = (field) => {
        return (e, value = {}) => {
            setValues((values) => {
                const newValues = {
                    ...values,
                    [field]: e.target.value || value.key,
                }

                return newValues
            })
        }
    }

    const loadUsersData = React.useCallback(async () => {
        setLoadingUsers(true)
        const accountId = currentAccount.account._id
        const accountRole = firstRole.restrict.accountRole
        const users = await getUsersAccount(accountId, { role: accountRole })
        setUsers(users)
        setLoadingUsers(false)
        console.log('HERE', users, accountId, accountRole, currentAccount)
    }, [setUsers])

    React.useEffect(() => {
        if (!isRestricted || loadingUsers) return
        loadUsersData()
    }, [])

    const onSubmit = React.useCallback(() => {
        const payload = fixedRole
            ? {
                  role: firstRole.role,
                  ...values,
              }
            : values
        updateTransactionRole(transactionId, payload)
            .then(() => {
                // Updated roles
                setShowNameField(false)
                setOpenFalse()
                loadData()
            })
            .catch((err) => {
                console.log('Error', err)
                if (
                    err &&
                    err.response &&
                    err.response.data.error === 'Missing name for new user'
                ) {
                    // We need a new usre name
                    setShowNameField(true)
                } else {
                    // Do something here?
                }
            })
    }, [transactionId, roles, values])

    const commands = [
        {
            key: 'cancel',
            name: 'Cancel',
            iconProps: { iconName: 'Cancel' },
            onClick: setOpenFalse,
        },
        {
            key: 'invite',
            name: 'Invite',
            iconProps: { iconName: 'Send' },
            onClick: onSubmit,
        },
    ]

    console.log('USERS', users, values)
    const selectedKeys = []

    const usersOptions = users

    return (
        <Panel isOpen={open} headerText='Assign Role' onDismiss={setOpenFalse}>
            <CommandBar items={commands} />
            <Dropdown
                label='Role'
                options={roleOptions(roles)}
                disabled={fixedRole}
                defaultSelectedKey={roles[0].role}
            />
            {isRestricted ? (
                <>
                    <p>
                        Restriced to users with role:{' '}
                        {firstRole.restrict.accountRole}
                    </p>
                    <Dropdown
                        label='User'
                        options={usersOptions}
                        onChange={(e, user) => {
                            console.log('CHANGE', user, user.email)
                            setValues((values) => {
                                return Object.assign({}, values, {
                                    email: user.email,
                                })
                            })
                        }}
                        onRenderOption={(user) => {
                            return (
                                <Persona
                                    size={PersonaSize.size40}
                                    key={user._id}
                                    text={`${user.firstName} ${user.lastName}`}
                                    secondaryText={user.email}
                                />
                            )
                        }}
                        onRenderTitle={([user], ...args) => {
                            return (
                                <Stack>
                                    <Persona
                                        size={PersonaSize.size40}
                                        key={user._id}
                                        text={`${user.firstName} ${user.lastName}`}
                                        secondaryText={user.email}
                                    />
                                </Stack>
                            )
                        }}
                        styles={{
                            title: {
                                height: 60,
                                display: 'flex',
                                alignItems: 'center',
                            },
                            dropdownItem: { height: 50 },
                            dropdownItemSelected: { height: 50 },
                        }}
                    />
                </>
            ) : (
                <TextField
                    label='Email Address'
                    onChange={setValue('email')}
                    value={values.email}
                />
            )}

            <TextField
                label='Team'
                disabled={true}
                value={getTeamForRole(roles, roles[0].role)}
                readOnly
            />
            <Dropdown
                label='Access'
                options={accessOptions}
                onChange={setValue('access')}
                defaultSelectedKey={values.access}
            />

            {showNameField ? (
                <Stack style={notificationStyle}>
                    <p>
                        User not found, they will be invited by email. Please
                        enter the name of new user.
                    </p>
                    <TextField
                        label='First Name'
                        value={values.firstName}
                        onChange={setValue('firstName')}
                    />
                    <TextField
                        label='Last Name'
                        value={values.lastName}
                        onChange={setValue('lastName')}
                    />
                    <Text variant='small'>
                        User can change their name on signup.
                    </Text>
                </Stack>
            ) : null}
        </Panel>
    )
}

const notificationStyle = {
    background: 'papayawhip',
    padding: '10px 20px 30px 20px',
    margin: '20px 0',
    borderRadius: 8,
}

export default RoleAssignPanel
