import React from 'react'
import { Navigate } from 'react-router-dom'
import { Text } from '@fluentui/react'

import { useAuth } from '../hooks/useAuth'
import FullPage from '../components/layout/FullPage'
import { Button, PrimaryButton } from './auth/Buttons'
import { useNavigate } from 'react-router-dom'

const commentStyle = {
    fontWeight: 600,
    color: '#ccc',
    textAlign: 'center',
}

const Homepage = (props) => {
    const { loggedIn } = useAuth()
    const navigate = useNavigate()

    if (loggedIn) {
        return <Navigate to='/dashboard' />
    }

    const clickLogin = () => navigate('/login')
    const clickSignup = () => navigate('/signup')

    return (
        <FullPage
            background='url("https://source.unsplash.com/1600x900/?building")'
            style={{ gap: 24 }}>
            <Text variant='xxLarge'>Welcome to leasle portal</Text>
            <Text variant='large' style={commentStyle}>
                Have a great day 👍
            </Text>
            <PrimaryButton onClick={clickLogin}>Sign in</PrimaryButton>
            <Button onClick={clickSignup}>Sign up</Button>
        </FullPage>
    )
}

export default Homepage
