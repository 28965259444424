import React from 'react'

export const TransactionError = ({ title, msg }) => {
    return (
        <>
            {title ? <h1>{title}</h1> : null}
            {msg ? <p>{msg}</p> : null}
        </>
    )
}
