import React from 'react'
import {
    Selection,
    SelectionMode,
    DetailsList,
    Persona,
    PersonaSize,
    CommandBar,
} from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { useNavigate } from 'react-router-dom'

import { getTheme } from '@fluentui/style-utilities'
import StatusBadge from '@/components/StatusBadge'
import { formatShortDateTime } from '@/lib/date'
import RoleAssignPanel from './RoleAssignPanel'
import RoleManagePanel from './RoleManagePanel'
import RoleActivityPanel from './RoleActivityPanel'
import RoleRevokeDialog from './RoleRevokeDialog'
import { updateTransactionRolesRevoke } from '@/api'

import { getRolesData } from '@/lib/roles'
import { capitalize, pascalToTitle } from '@/lib/string'
import { useAuth } from '../../../hooks/useAuth'
import { isTransactionCreator } from '../../../lib/transaction'
import RoleRecallDialog from './RoleRecallDialog'
import { transactionRolesRecall } from '../../../api'

const theme = getTheme()

const renderRoleName = ({ role }) => pascalToTitle(role)
const renderAccess = ({ access = '' }) => capitalize(access)
const renderSince = ({ since }) => (since ? formatShortDateTime(since) : '')

const statusLabels = {
    'unassigned': 'Unassigned',
    'active': 'Active',
    'pending': 'Pending',
    'revoked': 'Revoked',
    'Current User': 'Current User',
}

console.log('theme', theme)

const statusBadgeStyles = {
    'unassigned': {
        background: '#ec008c',
    },
    'active': {
        background: theme.palette.green,
    },
    'pending': {
        background: theme.palette.yellow,
    },
    'revoked': {
        background: theme.palette.neutralDark,
    },
    'Current User': {
        background: theme.palette.neutralDark,
    },
}

const renderStatus = ({ status }) => {
    return (
        <StatusBadge
            status={status}
            labels={statusLabels}
            badgeStyles={statusBadgeStyles}
        />
    )
}

const renderCurrentUser = ({ active }) => {
    return (
        <>
            {active ? (
                <StatusBadge
                    status={'Current User'}
                    labels={statusLabels}
                    badgeStyles={statusBadgeStyles}
                />
            ) : null}
        </>
    )
}

const columns = [
    {
        key: 'role',
        name: 'Role',
        fieldName: 'role',
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
        onRender: renderRoleName,
    },
    {
        key: 'user',
        name: 'User',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onRender: (row) => {
            if (!row.user) return null
            return (
                <Persona
                    text={`${row.user.firstName || '-'} ${
                        row.user.lastName || ''
                    }`}
                    // imageUrl={avatar}
                    size={PersonaSize.size24}
                />
            )
        },
    },
    {
        key: 'email',
        name: 'Email',
        fieldName: 'email',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'team',
        name: 'Team',
        fieldName: 'team',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'access',
        name: 'Access',
        fieldName: 'access',
        onRender: renderAccess,
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
    },
    {
        key: 'restrict',
        name: 'Restriction',
        fieldName: 'access',
        onRender: (row) => {
            if (!row.restrict) return null
            const content = []
            if (row.restrict.accountRole) {
                content.push(
                    <span key='accountRole'>
                        Role: {row.restrict.accountRole}
                    </span>,
                )
            }
            return <>{content}</>
        },
        minWidth: 50,
        maxWidth: 100,
        isResizable: true,
    },
    {
        key: 'since',
        name: 'Since',
        fieldName: 'since',
        minWidth: 100,
        maxWidth: 150,
        onRender: renderSince,
        isResizable: true,
    },
    {
        key: 'status',
        name: 'Status',
        fieldName: 'status',
        onRender: renderStatus,
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
    },
    {
        key: 'currentUser',
        name: 'Current User',
        onRender: renderCurrentUser,
        minWidth: 150,
        maxWidth: 400,
        isResizable: true,
    },
]

const useModal = () => {
    const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false)
    return [isOpen, open, close]
}

const Roles = ({ transaction, loadData, transactionActivity }) => {
    const navigate = useNavigate()
    const { userId } = useAuth()
    const [isAssignOpen, openAssignPanel, dismissAssignPanel] = useModal()
    const [isManageOpen, openManagePanel, dismissManagePanel] = useModal()
    const [isActivityOpen, openActivityPanel, dismissActivityPanel] = useModal()
    const [isRevokeOpen, openRevokeDialog, dismissRevokeDialog] = useModal()
    const [isRecallOpen, openRecallDialog, dismissRecallDialog] = useModal()

    const [selectedItem, setSelectedItem] = React.useState(undefined)
    const data = React.useMemo(() => getRolesData(transaction), [transaction])
    const selection = React.useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    setSelectedItem(selection.getSelection()[0])
                },
            }),
        [],
    )

    const hasRoles = !!transaction.workflow.allRoles.length

    if (!hasRoles) {
        return null
    }

    const onRevokeConfirm = () => {
        updateTransactionRolesRevoke(transaction._id, selectedItem)
            .then(() => {
                // Updated roles
                loadData()
            })
            .catch((err) => {
                // Error updating roles
            })
    }

    const onRecallConfirm = () => {
        transactionRolesRecall(transaction._id)
            .then(() => {
                // Updated roles
                loadData()
            })
            .catch((err) => {
                // Error updating roles
            })
    }

    const hasSelectedRole = Boolean(selectedItem)
    const hasSelectedRoleAssigned =
        hasSelectedRole && selectedItem.status !== 'unassigned'
    const hasSelectedRoleUnassigned =
        hasSelectedRole && selectedItem.status === 'unassigned'
    const recallVisible = isTransactionCreator(transaction, userId)
    const recallEnabled = recallVisible && transaction.currentRole !== 'creator'

    const commands = [
        {
            key: 'back',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => navigate(-1),
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: loadData,
        },
        {
            key: 'assign',
            text: 'Assign Role',
            iconProps: { iconName: 'Add' },
            disabled: !hasSelectedRoleUnassigned,
            onClick: openAssignPanel,
        },
        {
            key: 'manage',
            text: 'Manage Role',
            iconProps: { iconName: 'Edit' },
            disabled: !hasSelectedRoleAssigned,
            onClick: openManagePanel,
        },
        {
            key: 'revoke',
            text: 'Revoke Role',
            iconProps: { iconName: 'Delete' },
            disabled: !hasSelectedRoleAssigned,
            onClick: openRevokeDialog,
        },
        recallVisible
            ? {
                  key: 'recall',
                  text: 'Recall',
                  iconProps: { iconName: 'DoubleChevronLeft12' },
                  disabled: !recallEnabled,
                  onClick: openRecallDialog,
              }
            : null,
        {
            key: 'activity',
            text: 'View Activity',
            iconProps: { iconName: 'View' },
            disabled: !hasSelectedRoleAssigned,
            onClick: openActivityPanel,
        },
    ].filter((a) => !!a)

    return (
        <div>
            <CommandBar items={commands} />
            <DetailsList
                items={data}
                columns={columns}
                selectionMode={SelectionMode.single}
                selection={selection}
            />
            {isAssignOpen ? (
                <RoleAssignPanel
                    transactionId={transaction._id}
                    roles={selectedItem ? [selectedItem] : data}
                    open={isAssignOpen}
                    setOpenTrue={openAssignPanel}
                    setOpenFalse={dismissAssignPanel}
                    loadData={loadData}
                />
            ) : null}
            {isManageOpen ? (
                <RoleManagePanel
                    transactionId={transaction._id}
                    roles={selectedItem ? [selectedItem] : data}
                    open={isManageOpen}
                    setOpenTrue={openManagePanel}
                    setOpenFalse={dismissManagePanel}
                    onCofirm={onRevokeConfirm}
                />
            ) : null}
            {isActivityOpen ? (
                <RoleActivityPanel
                    transactionId={transaction._id}
                    role={selectedItem ? selectedItem : null}
                    open={isActivityOpen}
                    setOpenTrue={openActivityPanel}
                    setOpenFalse={dismissActivityPanel}
                    transactionActivity={transactionActivity}
                />
            ) : null}
            <RoleRevokeDialog
                open={isRevokeOpen}
                isCurrentUser={selectedItem?.active}
                setOpenFalse={dismissRevokeDialog}
                onConfirm={onRevokeConfirm}
            />
            <RoleRecallDialog
                open={isRecallOpen}
                setOpenFalse={dismissRecallDialog}
                onConfirm={onRecallConfirm}
            />
        </div>
    )
}

export default Roles
