import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, ChoiceGroup } from '@fluentui/react'
import { registerSingle, makeLabel } from './utils.js'

const generateOptions = (items) => {
    return items
        .map((obj) => {
            return {
                key: obj.value,
                text: obj.title,
            }
        })
        .filter((v) => v !== null)
}

// Basic elemeznt types for combo and choice
const elementTypeMap = {
    combo: Dropdown,
    choice: ChoiceGroup,
}

const ListControl = ({ schema, readonly = false }) => {
    const { name, title, items, format = 'combo', calc, required } = schema

    // Don't allow calculated fields to be edited
    if (calc) {
        readonly = true
    }

    const { onChange, value, errorMessage } = registerSingle(name, null, true)
    const onRenderLabel = makeLabel(schema)

    const options = generateOptions(items)

    const ElementName = elementTypeMap[format]

    const _onChange = (e, item) => onChange(null, item.key)

    const opts = {
        label: title,
        options,
        selectedKey: value,
        placeholder: 'Select an option',
        onChange: _onChange,
        onRenderLabel,
        styles: { dropdown: { width: 300 } },
        disabled: readonly,
        errorMessage,
        required,
    }

    return (
        <>
            <ElementName {...opts} />
            {schema.format === 'choice' ? errorMessage : null}
        </>
    )
}

ListControl.propTypes = {
    schema: PropTypes.any,
}

export default ListControl
