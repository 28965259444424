import React from 'react'
import {
    Text,
    DetailsList,
    SelectionMode,
    Selection,
    CommandBar,
    TextField,
    Persona,
    PersonaSize,
} from '@fluentui/react'
import {
    addUserGroupsEmail,
    getGroupMembers,
    patchGroupMembers,
} from '../../../../api'

export const Users = ({ group, loadData }) => {
    const { currentAccount } = useAuth()
    const [selected, setSelected] = React.useState([])
    const [selection] = React.useState(
        new Selection({
            onSelectionChanged: () =>
                setSelected(selection.getSelection() || []),
        }),
    )
    const [addModalOpen, setAddModalOpen] = React.useState(false)
    const [users, setUsers] = React.useState([])

    const onAddModalClose = () => {
        setAddModalOpen(false)
        loadData()
    }
    const onDelete = () => {
        console.log('selected', selected)
        const users = selected.map(({ _id }) => _id)
        patchGroupMembers(group._id, {
            users,
            accountId: currentAccount.account._id,
        }).then(() => {
            loadData()
        })
    }

    React.useEffect(() => {
        getGroupMembers(group._id).then(setUsers)
    }, [])

    const columns = [
        {
            key: 'icon',
            name: 'User',
            onRender: (col) => (
                <Persona
                    text={`${col.firstName} ${col.lastName}`}
                    size={PersonaSize.size24}
                />
            ),
            minWidth: 200,
            maxWidth: 350,
        },
        {
            key: 'email',
            name: 'Email',
            fieldName: 'email',
            minWidth: 200,
            maxWidth: 350,
        },
    ]

    const actions = [
        {
            key: 'add',
            name: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => setAddModalOpen(true),
        },
        {
            key: 'delete',
            name: 'Remove',
            iconProps: { iconName: 'Delete' },
            disabled: !selected.length,
            onClick: onDelete,
        },
    ]
    return (
        <>
            <Text variant='xLarge'>Users</Text>
            <CommandBar items={actions} />
            <DetailsList
                items={users}
                columns={columns}
                selection={selection}
                selectionMode={SelectionMode.multiple}
            />
            <AddUserModal
                group={group}
                isOpen={addModalOpen}
                onClose={onAddModalClose}
            />
        </>
    )
}

import {
    Modal,
    Stack,
    Dropdown,
    IconButton,
    PrimaryButton,
    DefaultButton,
} from '@fluentui/react'
import { useAuth } from '../../../../hooks/useAuth'

const AddUserModal = ({ group, isOpen, onClose = () => {} }) => {
    const title = 'Add User'
    const [data, setData] = React.useState('')
    const { currentAccount } = useAuth()

    // console.log('currentAccount', currentAccount)

    const onDataChange = (event) => {
        setData(event.target.value)
    }

    const onAdd = () => {
        if (!currentAccount) {
            throw new Error('Missing currentAccount')
        }
        const payload = {
            email: data,
            accountId: currentAccount.account._id,
            groups: [group._id],
        }
        // @TODO
        addUserGroupsEmail(payload).then(() => {
            setData('')
            onClose()
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em' }}
                tokens={{ childrenGap: '1em' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        // styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </div>

                <Stack
                    horizontalAlign='start'
                    tokens={{ childrenGap: 10 }}
                    style={{ width: '100%' }}>
                    <TextField
                        label='Email'
                        value={data}
                        onChange={onDataChange}
                        styles={{
                            root: { width: '100%' },
                        }}
                    />
                </Stack>

                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='end'>
                    <PrimaryButton onClick={onAdd} disabled={!data.length}>
                        Add
                    </PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}
