import { authDelete, authGet, authPatch, authPost, getData } from '../common'

export const getGroups = () => {
    return authGet({
        url: `/group`,
    }).then(getData)
}

export const getGroup = (id) => {
    return authGet({
        url: `/group/${id}`,
    }).then(getData)
}

export const createGroup = (payload) => {
    return authPost({
        url: `/group`,
        data: payload,
    }).then(getData)
}

export const deleteGroup = (id) => {
    return authDelete({
        url: `/group/${id}`,
    }).then(getData)
}

export const getGroupMembers = (id) => {
    return authGet({
        url: `/group/${id}/members`,
    }).then(getData)
}

export const patchGroupMembers = (id, payload) => {
    return authPatch({
        url: `/group/${id}/members`,
        data: payload,
    }).then(getData)
}

export const patchGroupPermissions = (id, payload) => {
    return authPatch({
        url: `/group/${id}/permissions`,
        data: payload,
    }).then(getData)
}
