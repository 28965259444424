import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@fluentui/react'

import { registerSingle, makeLabel } from './utils.js'

import { formatNumber, parseNumber, calcWidth } from '../../lib/number.js'

const NumberInput = ({ schema, readonly = false }) => {
    if (schema.type !== 'NUMBER') {
        throw new Error('NumberInput given incorrect schema type')
    }

    const { name, required, title, calc, format = true, precision = 0 } = schema
    const formatter = (value) => formatNumber(value, format, precision)
    const { onChange, localValue, setLocalValue, errorMessage } =
        registerSingle(name, formatter)

    const onRenderLabel = makeLabel(schema)

    // Don't allow calculated fields to be edited
    if (calc) {
        readonly = true
    }

    const width = calcWidth(schema)

    const change = (e, value) => setLocalValue(value)

    const changeBlur = (e) => {
        if (!localValue) {
            onChange(null, '')
            return
        }
        const newValue = parseNumber(localValue)
        onChange(null, newValue)
        setLocalValue(formatNumber(newValue, format, precision))
    }

    const opts = {
        name,
        required,
        label: title,
        value: localValue,
        errorMessage,
        onRenderLabel,
        onChange: change,
        onBlur: changeBlur,
        styles: {
            fieldGroup: { width },
            field: calc ? { background: 'papayawhip !important' } : {},
        },
        readOnly: readonly,
    }
    console.log('OPTS', opts)

    return (
        <>
            <TextField {...opts} />
        </>
    )
}

NumberInput.propTypes = {
    schema: PropTypes.any,
}

export default NumberInput
