import React from 'react'

import Routes from './Routes'
import LoadingSpinner from './components/LoadingSpinner'

import { useAuth } from './hooks/useAuth'
import ErrorBoundary from './components/ErrorBoundary'

import './_styles/index.css'
import { useNavigate } from 'react-router-dom'
import { useSettings } from './hooks/useSettingsNew'

const App = () => {
    const { loading, loggedIn, userId } = useAuth()
    const navigate = useNavigate()
    const { getSetting } = useSettings()

    const directionRtl = getSetting('rtl')

    React.useEffect(() => {
        document.dir = directionRtl ? 'rtl' : 'ltr'
    }, [directionRtl])

    return loading ? (
        <LoadingSpinner />
    ) : (
        <ErrorBoundary>
            <Routes />
        </ErrorBoundary>
    )
}

export default App
