const hostMapping = {
    '__default__': 'development',
    'localhost': 'development',
    '127.0.0.1': 'development',
    'portal.dev.leasle.com': 'dev',
    'portal.qa.leasle.com': 'qa',
    'portal.stage.leasle.com': 'stage',
    'portal.pilot.leasle.com': 'pilot',
    'portal.leasle.com': 'live',
}

const getEnv = () => {
    const host = document.location.host
    return hostMapping[host] || hostMapping['__default__']
}

const env = getEnv()

console.debug('Env', env)

const configs = {
    development: {
        API_BASE: 'http://127.0.0.1:3000',
    },
    dev: {
        API_BASE: 'https://api.dev.leasle.com',
    },
    qa: {
        API_BASE: 'https://api.qa.leasle.com',
    },
    stage: {
        API_BASE: 'https://api.stage.leasle.com',
    },
    pilot: {
        API_BASE: 'https://api.pilot.leasle.com',
    },
    live: {
        API_BASE: 'https://api.leasle.com',
    },
}

const getSetting = (name) => {
    const value = configs[env][name]
    if (!value) {
        console.warn(`Missing setting for ${env}:${name}`)
    }
    return value
}

export { getSetting, env }
