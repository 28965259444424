import React from 'react'
import {
    Stack,
    TextField,
    PrimaryButton,
    Icon,
    Text,
    Persona,
    PersonaSize,
    Spinner,
    SpinnerSize,
    CommandBar,
    DefaultButton,
} from '@fluentui/react'
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons'
import { capitalize } from '../../../lib/string.js'
import { nudgeTransaction } from '../../../api/index.js'
import { useNavigate } from 'react-router-dom'
import EmailOverview from './EmailOverview.js'

import RollbackModal from '../RollbackModal.js'
import { getRolesData } from '../../../lib/roles.js'
import RoleAssignPanel from '../../../views/Transaction/Roles/RoleAssignPanel.js'
import { useAuth } from '../../../hooks/useAuth.js'
import Template from '../../../components/template/Template'
import { isTransactionCreator } from '../../../lib/transaction.js'
import EmailPreview from './EmailPreview.js'
import { useStateMachine } from '../../../hooks/useStateMachine.js'

const EmailStage = ({
    transaction,
    transactionFiles = [],
    loadData,
    transactionHistory = [],
    transactionData = null,
    template = null,
}) => {
    const { userId } = useAuth()
    const navigate = useNavigate()
    const sm = useStateMachine()
    const [data, setDataRaw] = React.useState({
        to: {
            // name: 'phil poore',
            // email: 'phil.poore@leasle.com',
        },
    })
    const [loading, setLoading] = React.useState(false)
    const [serverError, setServerError] = React.useState(null)
    const [rollbackModelOpen, setRollbackModelOpen] = React.useState(false)

    const setValue = (signer, field, value) => {
        setDataRaw((dataIn) => {
            const data = { ...dataIn }
            if (!data[signer]) {
                data[signer] = {}
            }

            data[signer][field] = value
            return data
        })
    }

    console.log('transactionFiles', transactionFiles)

    const getValue = (signer, field) =>
        data[signer] ? data[signer][field] || '' : ''

    const hasRole = (role) =>
        transaction.roles.find(({ name }) => name === role)

    const findFile = ({ document }) =>
        transactionFiles.find(
            (transactionFile) => transactionFile.filename === document,
        )

    const currentStage = transaction.stages[transaction.currentStage] || {}

    const lastSave = transactionHistory.slice(-1)[0]
    const emailLastSave =
        lastSave?.type === 'email' &&
        lastSave?.stage === transaction.currentStage &&
        lastSave?.status === 'valid'
    const emailData = lastSave.emailData

    console.log('emailData', emailData)

    let content
    const status = emailLastSave ? emailData.status : ''
    const documents = currentStage.documents
    const signers = emailLastSave ? emailData.signers : currentStage.signers

    const signersData = Object.keys(signers || {}).map((signer) => {
        const who = signers[signer]
        if (who.role) {
            return {
                role: true,
                hasRole: hasRole(who.role),
                who: who.role,
                roleType: who.roleType,
            }
        }
    })

    const onSubmit = () => {
        if (loading) return

        const payload = {
            type: 'email',
            data: {
                signers: data,
            },
        }

        setLoading(true)
        nudgeTransaction(transaction._id, payload)
            .then(() => loadData())
            .catch((err) => {
                setLoading(false)
                setServerError({
                    title: 'Error nudging email stage',
                    details: err,
                })
            })
    }

    if (['sent', 'declined'].includes(status)) {
        content = (
            <Stack tokens={{ childrenGap: 10 }} horizontalAlign='center'>
                <h1>Email sent</h1>
                <p>Please check back later</p>
                <EmailOverview emailData={emailData} />
                <Stack
                    style={{ color: '#aaa' }}
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign='center'>
                    <p>Envelope ID: {emailData.envelopeId}</p>
                    {emailData.updated ? (
                        <p>Last Updated: {currentStage.updated}</p>
                    ) : null}
                </Stack>
            </Stack>
        )
    } else if (['submitted'].includes(status)) {
        content = (
            <EmailPreview
                {...{
                    transaction,
                    transactionData,
                    transactionHistory,
                    emailData,
                }}
            />
        )
    } else {
        const currentRole = transaction.roles.find(
            ({ name }) => name === transaction.currentRole,
        )

        const isWithCurrentUser = currentRole
            ? userId === currentRole.user._id
            : false

        const uploadFiles = currentStage.email.uploadFiles || []

        const isWithCurrentUserOrCreator =
            isWithCurrentUser || isTransactionCreator(transaction, userId)

        const name = getValue('to', 'name')
        const email = getValue('to', 'email')

        console.log('uploadFiles', uploadFiles)

        // Calculate when's for uploadFiles
        const filteredUploadFiles = uploadFiles.filter((uploadFile) => {
            if (!uploadFile.when) return true
            return sm.calcWhen(uploadFile.when)
        })

        content = (
            <Stack tokens={{ childrenGap: 10 }} verticalAlign='center'>
                <Template
                    contents={template.content.contents}
                    readonly={true}
                />
                <Text>File requests in email:</Text>
                <div>
                    {filteredUploadFiles.map((uploadFile, i) => {
                        return <UploadFile key={i} uploadFile={uploadFile} />
                    })}
                </div>
                <Stack tokens={{ childrenGap: 10 }}>
                    <>
                        <TextField
                            label={'To Name'}
                            style={{ width: 300 }}
                            required
                            value={name}
                            onChange={(e, v) => setValue('to', 'name', v)}
                        />
                        <TextField
                            label={'To Email'}
                            style={{ width: 300 }}
                            required
                            value={email}
                            onChange={(e, v) => setValue('to', 'email', v)}
                        />
                    </>
                </Stack>
                {isWithCurrentUserOrCreator ? (
                    <PrimaryButton disabled={loading} onClick={onSubmit}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            {loading ? (
                                <Spinner size={SpinnerSize.small} />
                            ) : null}
                            <span>{loading ? 'Sending' : 'Send'}</span>
                        </Stack>
                    </PrimaryButton>
                ) : null}
                {serverError ? (
                    <p style={{ color: 'red' }}>{serverError.title}</p>
                ) : null}
            </Stack>
        )
    }

    const actions = [
        {
            key: 'back',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => {
                navigate(-1)
            },
        },
        {
            key: 'rollback',
            text: 'Rollback',
            iconProps: { iconName: 'Rewind' },
            onClick: () => {
                setRollbackModelOpen(true)
            },
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {
                loadData()
            },
        },
    ]

    return (
        <>
            <CommandBar items={actions} />
            <Stack
                horizontalAlign='center'
                styles={{ minHeight: 500, minWidth: '50%' }}>
                <Text variant='xLargePlus'>Email</Text>
                {content}
            </Stack>
            <RollbackModal
                isOpen={rollbackModelOpen}
                onClose={() => setRollbackModelOpen(false)}
                transaction={transaction}
            />
        </>
    )
}

const documentStyle = {
    border: '2px solid lightgrey',
    padding: 10,
    marginBottom: 5,
    borderRadius: 5,
}

const mapToIcon = ({ accept, icon }) => {
    if (icon) return icon
    if (accept.match('image/')) return 'png'
    if (accept.match('text/')) return 'txt'
    if (accept.match('application/pdf')) return 'pdf'
    return ''
}

const UploadFile = ({ uploadFile }) => {
    console.log('uploadFile', uploadFile)

    const extension = mapToIcon(uploadFile)
    return (
        <Stack style={documentStyle}>
            <File
                filename={uploadFile.title}
                extension={extension}
                required={uploadFile.required}
            />
        </Stack>
    )
}

const SignersList = ({ document, signers }) => {
    const content = document.signers.map((signer, i) => {
        return <span key={i}>{capitalize(signers[signer].role)}</span>
    })
    return (
        <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign='center'>
            <Text variant='small'>Signed by: </Text>
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                {content}
            </Stack>
        </Stack>
    )
}

const File = ({ filename, extension = 'txt', required = false }) => {
    return (
        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
            <Icon
                {...getFileTypeIconProps({
                    extension,
                    size: 24,
                    imageFileType: 'png',
                })}
            />
            <Stack>
                <Text>{filename}</Text>
                {required ? (
                    <Text style={{ color: 'red' }}>Required</Text>
                ) : null}
            </Stack>
        </Stack>
    )
}

const parseFilename = (filename) => {
    const parts = filename.split('.')
    return [parts.slice(0, -1).join('.'), parts.slice(-1)[0]]
}

export default EmailStage
