import React from 'react'
import { Text, Spinner } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { getGroup } from '../../../../api'
import TabSet from '../../../../components/TabSet'
import { Permissions } from './Permissions'
import { Users } from './Users'

export const GroupSection = () => {
    const { subSection: groupId } = useParams()
    const [group, setGroup] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate()

    const loadData = React.useCallback(() => {
        setLoading(true)
        getGroup(groupId)
            .then(setGroup)
            .then(() => setLoading(false))
    })

    React.useEffect(loadData, [groupId, setGroup])

    if (loading) {
        return (
            <>
                <Spinner />
            </>
        )
    }

    console.log('group', group)

    return (
        <>
            <Text variant='xxLarge'>Group: {group.name}</Text>
            <TabSet>
                <div label='Permissions'>
                    <Permissions group={group} loadData={loadData} />
                </div>
                <div label='Users'>
                    <Users group={group} loadData={loadData} />
                </div>
            </TabSet>
        </>
    )
}
