import { authGet, authPatch, authPost, getData } from '../common'

export const getEntitiesByType = (entityType) => {
    return authGet({ url: `/entity?q=${JSON.stringify({ entityType })}` }).then(
        getData,
    )
}

export const getEntitiesByTypeName = async (entityTypeName, filter = false) => {
    const [entityType] = await authGet({
        url: `/entityType?q=${JSON.stringify({ name: entityTypeName })}`,
    }).then(getData)

    const query = { entityType: entityType._id }
    if (filter) {
        const filters = []
        Object.keys(filter).forEach((field) => {
            const value = filter[field]
            filters.push({
                data: {
                    $elemMatch: {
                        values: value,
                        field,
                    },
                },
            })
        })

        query['$and'] = filters
    }
    return authGet({
        url: `/entity?q=${JSON.stringify(query)}`,
    }).then(getData)
}
export const getEntity = (id) => authGet({ url: `/entity/${id}` }).then(getData)
export const createEntity = (entity) =>
    authPost({ url: `/entity`, data: entity, json: true }).then(getData)
export const updateEntity = (id, entity) =>
    authPatch({ url: `/entity/${id}`, data: entity, json: true }).then(getData)
