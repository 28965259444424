const getCurrentStage = (transaction) =>
    transaction
        ? transaction.workflow.stages[transaction.currentStage] || null
        : null

const getNextRealStage = (transaction) =>
    Array.from(transaction.workflow.stages)
        .splice(transaction.currentStage + 1)
        .find((stage) => ['data', 'document'].includes(stage.kind))

const roleInStage = (givenRole, stage) =>
    stage.roles.filter((role) => role === givenRole).length > 0

const userInNextRealStage = (transaction, userId) => {
    const { currentRole } = transaction
    const nextStage = getNextRealStage(transaction)

    if (!nextStage || !nextStage.role || !nextStage.role.length) {
        return true
    }

    const currentRoleInNextStage = roleInStage(currentRole, nextStage)

    return currentRoleInNextStage
}

const getValues = (transactionData) => {
    return transactionData.fieldData.reduce((acc, item) => {
        acc[item.field] = item.values
        return acc
    }, {})
}

const getFields = (template = false) => {
    return template
        ? template.fields.reduce((acc, field) => {
              acc[field.name] = field
              return acc
          }, {})
        : {}
}

const isTransactionCreator = (transaction, userId) => {
    return transaction.createdBy._id === userId
}

export {
    getCurrentStage,
    getValues,
    getFields,
    getNextRealStage,
    userInNextRealStage,
    roleInStage,
    isTransactionCreator,
}
