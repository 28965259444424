const collectIntoObject = (field) => (acc, item) => {
    acc[item[field]] = item
    return acc
}

const collectIntoSet = (acc, arr) => {
    arr.forEach((field) => acc.add(field))
    return acc
}

const collectFromTo = (from, to) => {
    return (acc, field) => {
        acc[field[to]] = field[from]
        return acc
    }
}

const sortBy = (field) => (a, b) => a[field] < b[field] ? -1 : 1

const sortArray = (sortFunc) => (arr) => arr.sort(sortFunc)

const pick = (field) => {
    return (item) => item[field]
}

const formatUser = (firstName, lastName) => `${firstName} ${lastName}`

export {
    pick,
    sortArray,
    sortBy,
    collectFromTo,
    collectIntoObject,
    collectIntoSet,
    formatUser,
}
