import React from 'react'

import {
    DetailsList,
    SelectionMode,
    Persona,
    PersonaSize,
} from '@fluentui/react'
import { formatShortDateTime } from '../lib/date'
import { capitalize } from '../lib/string'
import { activityText } from '../lib/activity'

const onRenderCapitalize = (item, i, col) => capitalize(item[col.fieldName])

const formatType = (type) => {
    return type
        .split('-')
        .map((word) => capitalize(word))
        .join(' ')
}

const columns = [
    {
        key: 'date',
        name: 'Date',
        fieldName: 'created',
        minWidth: 100,
        maxWidth: 150,
        onRender(item, index, col) {
            return formatShortDateTime(item[col.fieldName])
        },
    },
    {
        key: 'type',
        name: 'Type',
        minWidth: 100,
        maxWidth: 150,
        onRender: (row) => formatType(row.type),
    },
    {
        key: 'activity',
        name: 'Activity',
        onRender: (row) => {
            return <Activity {...row} />
        },
    },
    {
        key: 'user',
        name: 'User',
        minWidth: 150,
        maxWidth: 250,
        onRender: (row) => {
            return row.user ? (
                <Persona
                    text={`${row.user.firstName} ${row.user.lastName}`}
                    size={PersonaSize.size24}
                />
            ) : (
                '-'
            )
        },
    },
    {
        key: 'role',
        name: 'Role',
        minWidth: 150,
        maxWidth: 250,
        fieldName: 'role',
        onRender: onRenderCapitalize,
    },
]

const ActivityDetailsList = ({ activity = [] }) => {
    return (
        <DetailsList
            columns={columns}
            items={activity}
            selectionMode={SelectionMode.none}
        />
    )
}

const Activity = (activity) => {
    return activityText(activity)
}

export default ActivityDetailsList
