import React, { useState, useEffect } from 'react'
import {
    getTemplate,
    getTransaction,
    getTransactionData,
    getTransactionHistory,
    getTransactionActivity,
    getTransactionFiles,
    getDomainTemplate,
    getOverrides,
    // commentTransaction,
} from '../api'
import { useForm } from '../components/controls/useForm'
import { useAuth } from './useAuth'
import { useStateMachine } from './useStateMachine'
import { getCurrentStage, getFields } from '../lib/transaction'
import useSocket from './useSocket'

const useTransaction = (transactionId) => {
    const sm = useStateMachine()
    const { setParams } = useForm()
    const { userId } = useAuth()
    const socket = useSocket('reload-transaction', () => loadData())

    const [loading, setLoading] = React.useState(true)
    const [serverError, setServerError] = useState('')
    const [transactionError, setTransactionError] = useState(null)
    const [transaction, setTransaction] = useState(null)
    const [transactionData, setTransactionData] = useState(null)
    const [transactionHistory, setTransactionHistory] = useState(null)
    const [transactionActivity, setTransactionActivity] = useState(null)
    const [transactionFiles, setTransactionFiles] = useState(null)
    const [template, setTemplate] = useState(null)
    const [portalTemplate, setPortalTemplate] = useState(null)
    const [domainTemplate, setDomainTemplate] = useState(null)

    const currentStage = getCurrentStage(transaction) || {}
    const fields = getFields(template)
    const domainFields = getFields(domainTemplate)

    // const hasRoles = transaction
    //     ? !!transaction.workflow.allRoles.length
    //     : false

    // Comments
    const comments = transactionActivity
        ? transactionActivity
              .filter(
                  (ta) =>
                      ta.type === 'comment' &&
                      ta.stage === transaction.currentStage,
              )
              .reverse()
        : []

    // Get transaction domain template
    useEffect(() => {
        ;(async () => {
            if (!transaction) return
            if (domainTemplate && domainTemplate._id === transaction.domain)
                return
            const template = await getDomainTemplate(transaction.domain)
            setDomainTemplate(template)
        })()
    }, [transaction ? transaction.domain : null])

    useEffect(() => {
        loadData()
    }, [transactionId, sm])

    React.useEffect(() => {
        if (!transaction) {
            setParams({})
            return
        }
        setParams(transaction.params)
    }, [transaction])

    const getRoles = (transaction) => {
        const currentRoles = transaction.roles
            .filter(({ user }) => user._id === userId)
            .map(({ name }) => name)
            .concat([
                transaction.createdBy._id === userId ? 'creator' : undefined,
            ])

        console.log('currentRoles', currentRoles)
        const tmpRoles = (
            new URLSearchParams(window.location.search).get('tmpRoles') || ''
        )
            .split(',')
            .filter((a) => !!a)

        return currentRoles.concat(tmpRoles)
    }

    const loadData = async () => {
        setLoading(true)

        if (socket) {
            socket.emit('load-transaction', { transaction: transactionId })
        } else {
            console.log(
                'SOCKET MISSING, turning off socket update for load-transaction',
            )
        }
        const [
            transaction,
            transactionData,
            transactionHistory,
            transactionActivity,
            transactionFiles,
        ] = await Promise.all([
            getTransaction(transactionId),
            getTransactionData(transactionId),
            getTransactionHistory(transactionId),
            getTransactionActivity(transactionId),
            getTransactionFiles(transactionId),
        ]).catch((err) => {
            console.log('ERROR HERE', err)
            if (err.response.statusText === 'Forbidden') {
                setServerError('Forbidden')
            }
            return [null, null, null]
        })

        if (!transaction) {
            setLoading(false)
            setTransactionError(null)
            return
        }

        setTransaction(transaction)
        setTransactionData(transactionData)
        setTransactionHistory(transactionHistory)
        setTransactionActivity(transactionActivity)
        setTransactionFiles(transactionFiles)

        // Load full template for data views
        const currentStage = getCurrentStage(transaction)

        console.log('Current stage', currentStage)
        if (
            currentStage &&
            currentStage.templates &&
            currentStage.templates.length
        ) {
            const template = await getTemplate(currentStage.templates[0]._id)
            setTemplate(template)

            const overrides = await getOverrides({
                template: template._id,
                workflow: transaction.workflow._id,
            })

            const roles = getRoles(transaction)

            // setSelectedTab('Template')
            sm.initData({
                template,
                transaction,
                transactionData,
                overrides: overrides.items,
                roles,
            })

            // sm.clearDirty()
        } else if (currentStage && currentStage.kind === 'docusign') {
            console.log('Run this docusign stage')
            const template = await getDomainTemplate(transaction.domain)

            const roles = getRoles(transaction)

            sm.initData({
                template,
                transaction,
                transactionData,
                overrides: [],
                roles,
            })

            setDomainTemplate(template)
            setTemplate(template)
        } else {
            setTemplate(null)
        }

        // If we are an email stage, also load portalTemplate
        if (
            currentStage &&
            currentStage.kind === 'email' &&
            currentStage.email.portalTemplate
        ) {
            // @TODO: portal templates are not loading overrides
            const portalTemplate = await getTemplate(
                currentStage.email.portalTemplate,
            )
            setPortalTemplate(portalTemplate)
        }

        setLoading(false)
        setTransactionError(null)
    }

    const loadActivity = async () => {
        const activity = await getTransactionActivity(transactionId)
        setTransactionActivity(activity)
    }

    return {
        loading,
        serverError,
        transactionError,
        transaction,
        transactionData,
        transactionActivity,
        transactionHistory,
        transactionFiles,
        currentStage,
        template,
        portalTemplate,
        fields,
        domainTemplate,
        domainFields,
        // hasRoles,
        comments,
        loadData,
        loadActivity,
        sm,
    }
}

export default useTransaction
