import React from 'react'
import PortalPage from '../components/layout/PortalPage'
import EmailPortal from '../components/template/EmailStage/EmailPortal'

const Portal = (props) => {
    return (
        <PortalPage>
            <EmailPortal />
        </PortalPage>
    )
}

export default Portal
