import React from 'react'

import {
    Modal,
    Stack,
    Dropdown,
    IconButton,
    PrimaryButton,
    DefaultButton,
    TextField,
    Icon,
    Text,
    MessageBar,
    MessageBarType,
} from '@fluentui/react'
import { rollbackTransaction } from '../../api'

const RollbackModal = ({ isOpen = false, onClose = () => {}, transaction }) => {
    const title = 'Rollback transaction'
    const stages = transaction.stages.slice(0, transaction.currentStage)
    const [toStage, setToStage] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [serverError, setServerError] = React.useState(null)

    const onSubmit = () => {
        if (loading) return
        setLoading(true)

        rollbackTransaction(transaction._id, { toStage })
            .then(() => {
                window.location.reload()
            })
            .catch((err) => {
                setLoading(false)
                setServerError({
                    title: 'Error rolling back transaction',
                    err,
                })
            })
    }

    const stageItems = stages.map((stage, i) => {
        return {
            key: i,
            text: stage.title,
            value: i,
            checked: toStage === i,
            disabled:
                stage.status === 'skipped' ||
                stage.kind === 'import-remote-file',
        }
    })

    const onChange = (event, item) => {
        setToStage(item.value)
    }

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em', position: 'relative' }}
                tokens={{ childrenGap: '1em' }}>
                <Stack horizontal>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        style={{ position: 'absolute', top: 10, right: 10 }}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </Stack>

                <Text>Select where to rollback to:</Text>

                <Dropdown
                    label='Transaction Stage'
                    options={stageItems}
                    styles={{ dropdown: { width: 300 } }}
                    onChange={onChange}
                />
                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='center'>
                    <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>

                {serverError ? (
                    <MessageBar messageBarType={MessageBarType.error}>
                        Error: {serverError.title}
                    </MessageBar>
                ) : (
                    <MessageBar messageBarType={MessageBarType.warning}>
                        Warning! Rolling back a transaction cannot be undone,
                        are you sure you want to?
                    </MessageBar>
                )}
            </Stack>
        </Modal>
    )
}

export default RollbackModal
