import NumberParser from './NumberParser.js'

// Setup number formatters and parsers for locale
const locale = 'en'
const numberParser = new NumberParser(locale)

const parseNumber = (value) => {
    return '' + numberParser.parse(value)
}

const formatNumber = (value, format, precision = 0) => {
    if (format === 'false' || format === false || format === 'off') return value

    const numberFormatter = new Intl.NumberFormat(locale, {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
    })

    return value ? numberFormatter.format(value) : ''
}

const CHAR_WIDTH = 9
const CHAR_MINIMUM = 25

const calcWidth = (fieldDef) => {
    const { maxLength, max } = fieldDef
    // Calculate input width based on maxLength or max
    let width = 150
    if (maxLength) {
        width = maxLength * CHAR_WIDTH + CHAR_MINIMUM
    } else if (max) {
        const length = Math.log10(max) + 1
        width = length * CHAR_WIDTH + CHAR_MINIMUM
    }

    return Math.floor(width)
}

export { parseNumber, formatNumber, calcWidth }
