import React from 'react'
import { Stack } from '@fluentui/react'
import { Link } from 'react-router-dom'

const Logo = () => (
    <Link to='/'>
        <img
            src='/images/logo-standard.svg'
            alt='leasle Logo'
            style={{ width: 200 }}
        />
    </Link>
)

const MiniHeader = () => {
    return (
        <Stack horizontalAlign='center' style={{ padding: 50 }}>
            <Logo />
        </Stack>
    )
}

export default MiniHeader
