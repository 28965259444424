import React from 'react'
import { Stack, Text, PrimaryButton } from '@fluentui/react'
import File from './File.js'

import { getExtension, bytesToSize } from '../lib/file.js'

const FileUploadInput = ({
    onChange = () => {},
    files = [],
    multiple = true,
    buttonText = '',
    styles = {},
}) => {
    const ref = React.useRef()
    const onClick = () => {
        if (ref.current) {
            ref.current.click()
        }
    }

    buttonText = buttonText
        ? buttonText
        : multiple
        ? 'Choose Files'
        : 'Choose File'

    return (
        <>
            <input
                ref={ref}
                type='file'
                multiple={multiple}
                onChange={onChange}
                style={{ display: 'none' }}
            />
            <PrimaryButton onClick={onClick} style={styles.button || {}}>
                {buttonText}
            </PrimaryButton>

            <div>
                {files.map((file, i) => (
                    <UploadFileItem key={i} file={file} />
                ))}
            </div>
        </>
    )
}

const uploadItemStyle = {
    border: '2px solid lightgray',
    padding: 8,
    borderRadius: 5,
    marginBottom: 5,
    cursor: 'pointer',
    userSelect: 'none',
}

const UploadFileItem = ({ file }) => {
    return (
        <Stack style={uploadItemStyle}>
            <File filename={file.name} extension={getExtension(file.name)} />
            <Text variant='small'>Size: {bytesToSize(file.size)}</Text>
        </Stack>
    )
}

export default FileUploadInput
