import { authGet, getData } from '../common'

export const getTemplates = () =>
    authGet({ url: `/template?exclude=content,fields,clauses` })
        .catch((err) => {
            throw err
        })
        .then(getData)

export const getTemplate = (id) =>
    authGet({ url: `/template/${id}?exclude=clauses` }).then(getData)
export const getTemplateByName = async (name) => {
    const [template] = await authGet({
        url: `/template?q=${JSON.stringify({ name })}`,
    }).then(getData)

    if (!template) {
        return {
            templateError: `Missing template: ${name}`,
        }
    }

    return authGet({ url: `/template/${template._id}` }).then(getData)
}

export const getDomainTemplate = (domainId) =>
    authGet({ url: `/domain/${domainId}/template` }).then(getData)
