import React from 'react'

import {
    Modal,
    Stack,
    Dropdown,
    IconButton,
    PrimaryButton,
    DefaultButton,
} from '@fluentui/react'

import { useAuth } from '@/hooks/useAuth'
import { getNextRealStage } from '../../../lib/transaction'

const ApproveModal = ({
    isOpen,
    onClose = () => {},
    loadData = () => {},
    onApproveAssign = () => {},
    transaction = null,
}) => {
    const title = 'Approve'
    const { userId } = useAuth()
    // const currentStage = transaction.stages[transaction.currentStage]
    const nextStage = getNextRealStage(transaction)

    const [sendTo, setSendTo] = React.useState(null)
    if (!transaction || !nextStage) {
        return null
    }

    const items = nextStage.roles.map((role) => {
        const assignedRole = transaction.roles.find(({ name }) => name === role)

        const text = assignedRole
            ? `${assignedRole.user.firstName} ${assignedRole.user.lastName} [${role}]`
            : role

        return {
            key: role,
            text,
            disabled: !assignedRole,
        }
    })

    const onChange = (e, item, i) => {
        setSendTo(item.key)
    }

    const onSend = () => {
        onApproveAssign(sendTo).then(() => {
            onClose()
            loadData()
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em' }}
                tokens={{ childrenGap: '1em' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        // styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </div>
                <p>
                    Please select who to assign transaction to after approval.
                </p>
                <Dropdown
                    label='Assign to'
                    options={items}
                    styles={{ dropdown: { width: 300 } }}
                    onChange={onChange}
                    defaultValue={sendTo}
                />
                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='end'>
                    <PrimaryButton onClick={onSend}>Send</PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default ApproveModal
