import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@fluentui/react'
import { registerSingle, makeLabel } from './utils.js'

const LongTextInput = ({ schema, readonly = false }) => {
    if (!['LONGTEXT', 'LONG_TEXT'].includes(schema.type)) {
        throw new Error('LongTextInput given incorrect schema type')
    }

    const { name, required, title, calc } = schema

    const { onChange, value, errorMessage } = registerSingle(name)
    const onRenderLabel = makeLabel(schema)

    // Don't allow calculated fields to be edited
    if (calc) {
        readonly = true
    }

    const opts = {
        name,
        required,
        label: title,
        value,
        errorMessage,
        multiline: true,
        rows: 3,
        onRenderLabel,
        onChange: onChange,
        readOnly: readonly,
    }

    return <TextField {...opts} />
}

LongTextInput.propTypes = {
    schema: PropTypes.any,
}

export default LongTextInput
