// leasle DateControl v1.0 handles full and part date input

import {
    Calendar,
    Callout,
    DateRangeType,
    DayOfWeek,
    DirectionalHint,
    TextField,
} from '@fluentui/react'
import React, { useRef, useState } from 'react'

import { registerSingle, makeLabel } from './utils.js'

import dayjs from 'dayjs'
// ===========================================================
//      Date Control
// ===========================================================

/*

"dmy" : default, d/m/y
saves full date

"dm" : d/m

"my" : m/y

*/

const settings = {
    dmy: {
        options: {
            isDayPickerVisible: true,
            isMonthPickerVisible: true,
            dateRangeType: DateRangeType.Day,
        },
        renderFormat: 'DD/MM/YYYY',
        inputDateFormats: [
            'Do MMM YYYY',
            'DD/MM/YYYY',
            'DD/M/YYYY',
            'D/MM/YYYY',
            'D/M/YYYY',
            'DD/MM/YY',
            'DD/M/YY',
            'D/MM/YY',
            'D/M/YY',
        ],
    },
    dm: {
        options: {
            isDayPickerVisible: true,
            isMonthPickerVisible: true,
            dateRangeType: DateRangeType.Day,
        },
        renderFormat: 'DD/MM',
        inputDateFormats: ['Do MMM', 'DD/MM', 'DD/M', 'D/MM', 'D/M'],
    },
    my: {
        options: {
            isDayPickerVisible: false,
            isMonthPickerVisible: true,
            dateRangeType: DateRangeType.Month,
        },
        renderFormat: 'MM/YYYY',
        inputDateFormats: [
            'MMM YYYY',
            'MM/YYYY',
            'M/YYYY',
            'MMM YY',
            'MM/YY',
            'M/YY',
        ],
    },
}

const renderDate = (dateString, format = 'dmy') => {
    if (!dateString) return ''
    const date = dayjs(dateString)
    if (!date.isValid()) return ''
    return date.format(settings[format].renderFormat)
}

const getDateValue = (value) => {
    if (!value || value === 'DATE_ERROR') return undefined
    return new Date(value)
}

const DateControl = ({ schema, readonly = false }) => {
    const {
        name,
        title,
        format = 'dmy',
        help,
        helpUrl,
        minValue,
        maxValue,
        required,
        calc,
    } = schema

    const formatSettings = settings[format].options
    if (typeof formatSettings === 'undefined') {
        throw new Error(`Unknown format (${format}) for date field ${name}`)
    }

    const dateControlRef = useRef()
    const dateInputFieldRef = useRef()
    const [isCallOutVisible, setIsCallOutVisible] = useState(false)

    const { onChange, value, errorMessage } = registerSingle(name)
    // Don't allow calculated fields to be edited
    if (calc) {
        readonly = true
    }

    const onRenderLabel = makeLabel(schema)

    const [dateValue, setDateValue] = useState(getDateValue(value))
    const [displayDate, setDisplayDate] = useState(renderDate(value, format))

    const [inputChanged, setInputChanged] = useState()

    React.useEffect(() => {
        setDateValue(getDateValue(value))
        setDisplayDate(renderDate(value, format))
    }, [name, value])

    const onCalloutDismiss = () => setIsCallOutVisible(false)

    const onTextChange = ({ target: { value } }) => {
        setInputChanged(true)
        setDisplayDate(value)
        setIsCallOutVisible(false)
    }

    const onTextSaved = ({ target: { value } }) => {
        if (!inputChanged) return
        console.debug(`value: ${value}`)
        setInputChanged(false)

        // Empty values
        if (value === '') {
            onChange(null, '')
            return
        }

        const date = dayjs(value, settings[format].inputDateFormats)
        if (!date.isValid()) {
            onChange(null, 'DATE_ERROR')
            return
        }
        const dateString = date.toISOString()

        onChange(null, dateString)
    }

    const onSelectDate = (date) => {
        const dateString = date.toISOString()

        onChange(null, dateString)

        setIsCallOutVisible(false)
        setInputChanged(false)
        dateInputFieldRef.current.focus()
    }

    return (
        <>
            <div ref={dateControlRef}>
                <TextField
                    componentRef={dateInputFieldRef}
                    name={name}
                    label={title}
                    value={displayDate}
                    styles={{ fieldGroup: { width: 300 } }}
                    iconProps={{ iconName: 'Calendar' }}
                    onRenderLabel={onRenderLabel}
                    onChange={onTextChange}
                    onBlur={onTextSaved}
                    onClick={() => {
                        if (!readonly) setIsCallOutVisible(!isCallOutVisible)
                    }}
                    errorMessage={errorMessage}
                    required={required}
                    readOnly={readonly}
                />
            </div>
            {isCallOutVisible && (
                <Callout
                    role='alertdialog'
                    isBeakVisible={false}
                    gapSpace={5}
                    target={dateControlRef.current}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    onDismiss={onCalloutDismiss}
                    shouldRestoreFocus>
                    <Calendar
                        onSelectDate={onSelectDate}
                        value={dateValue}
                        autoNavigateOnSelection
                        firstDayOfWeek={DayOfWeek.Sunday}
                        // strings={DayPickerStrings}
                        highlightSelectedMonth
                        {...formatSettings}
                    />
                </Callout>
            )}
        </>
    )
}

export default DateControl
