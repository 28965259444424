import { buildCSV } from './file'

const activityText = ({ type, data }) => {
    if (type === 'create') {
        return 'Transaction Created'
    }
    if (type === 'save') {
        return 'Saved transaction'
    }
    if (type === 'comment') {
        return data.comment
    }
    if (type === 'rollback') {
        return 'Transaction rolled back'
    }
    if (type === 'file-uploaded') {
        return data.filename ? data.filename : ''
    }

    // Docusign
    if (type === 'docusign-complete') {
        return 'Docusign envelope completed'
    }
    if (type === 'docusign-declined') {
        return 'Docusign envelope declined'
    }
    if (type === 'docusign-recipient-sent') {
        return 'Docusign envelope sent to recipient'
    }
    if (type === 'docusign-recipient-completed') {
        return 'Docusign envelope completed by recipient'
    }
    if (type === 'docusign-recipient-declined') {
        return 'Docusign envelope declined by recipient'
    }

    // Approve
    if (type === 'approve-created') {
        return 'Approval created'
    }
    if (type === 'approve-complete') {
        return 'Approval completed'
    }
    if (type === 'approve-declined') {
        return 'Approval declined'
    }
    if (type === 'approve-recipient-sent') {
        return 'Approval sent to recipient'
    }
    if (type === 'approve-recipient-completed') {
        return 'Approval completed by recipient'
    }
    if (type === 'approve-recipient-declined') {
        return 'Approval declined by recipient'
    }

    return type
}

const populateActivity = (activity) => {
    activity.text = activityText(activity)
    activity.userText = activity.user
        ? `${activity.user.firstName} ${activity.user.lastName}`
        : '-'
    return activity
}

const buildActivityFileExport = (activity) => {
    return buildCSV(activity, ['created', 'type', 'text', 'userText', 'role'])
}

export { activityText, populateActivity, buildActivityFileExport }
