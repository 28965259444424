import React from 'react'

import { Text, Stack } from '@fluentui/react'

import Page from '../../components/layout/Page'
import LoadingSpinner from '../../components/LoadingSpinner'
import TabSet from '../../components/TabSet'

import { useNavigate, useLocation } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import useTransaction from '../../hooks/useTransaction.js'

import StateMachine from 'leasle-state-machine'
import { StateMachineProvider } from '../../hooks/useStateMachine.js'

import Comments from './Comments.js'
import Roles from './Roles'

import TransactionData from './TransactionData'
import TransactionOverview from './TransactionOverview'
import TransactionActivity from './TransactionActivity'
import TransactionFiles from './TransactionFiles'

import { TransactionError } from './TransactionError.js'
import { currentStageTemplateWrap } from './currentStageTemplateWrap.js'

const Transaction = (props) => {
    const { transactionId, tab } = useParams()

    const navigate = useNavigate()
    const location = useLocation()

    const {
        loading,
        serverError,
        // transactionError,
        transaction,
        transactionData,
        transactionActivity,
        transactionHistory,
        transactionFiles,
        currentStage,
        template,
        fields,
        domainTemplate,
        domainFields,
        // hasRoles,
        comments,
        loadData,
        loadActivity,
    } = useTransaction(transactionId)

    if (serverError) {
        return (
            <Page>
                <TransactionError title={serverError} />
            </Page>
        )
    }
    if (loading || !domainTemplate) {
        return (
            <Page>
                <LoadingSpinner />
            </Page>
        )
    }

    const onTabClick = (activeTab) =>
        navigate(`/transactions/${transactionId}`, {
            state: {
                tab: activeTab,
            },
        })

    return (
        <Page>
            <Stack horizontal verticalAlign='center'>
                <Text variant='xxLarge' style={{ flexGrow: 1 }}>
                    {transaction ? transaction.title : 'Transaction'}
                </Text>
                <Comments
                    comments={comments}
                    reload={loadActivity}
                    transaction={transaction}
                />
            </Stack>

            <TabSet
                active={location.state ? location.state.tab : false}
                onTabClick={onTabClick}>
                {currentStageTemplateWrap({
                    transaction,
                    currentStage,
                    transactionFiles,
                    transactionHistory,
                    transactionData,
                    loadData,
                    template,
                    fields,
                })}
                <div label='Overview'>
                    <StateMachineProvider
                        value={new StateMachine({ word: false })}>
                        <TransactionOverview
                            transaction={transaction}
                            template={domainTemplate}
                            transactionHistory={transactionHistory}
                            fields={domainFields}
                            loadData={loadData}
                        />
                    </StateMachineProvider>
                </div>
                <div label='Roles'>
                    <Roles
                        transaction={transaction}
                        loadData={loadData}
                        transactionActivity={transactionActivity}
                    />
                </div>
                <div label='Data'>
                    <TransactionData
                        domainTemplate={domainTemplate}
                        transactionData={transactionData}
                        domainFields={domainFields}
                        loadData={loadData}
                    />
                </div>
                <div label='Activity'>
                    <TransactionActivity
                        transactionActivity={transactionActivity}
                        loadData={loadData}
                    />
                </div>

                <div label='Files'>
                    <TransactionFiles
                        transaction={transaction}
                        transactionFiles={transactionFiles}
                        loadData={loadData}
                    />
                </div>
            </TabSet>
        </Page>
    )
}

export default Transaction
