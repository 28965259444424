import React from 'react'
import { Stack, Spinner, SpinnerSize } from '@fluentui/react'

const style = { flexGrow: 1, height: '100%' }

const LoadingSpinner = ({ label = 'Loading...', size = SpinnerSize.large }) => (
    <Stack verticalAlign='center' style={style}>
        <Spinner size={size} label={label} />
    </Stack>
)

export default LoadingSpinner
