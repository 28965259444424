import { authGet, getData } from '../common'

export const getOverrides = (filter) => {
    return authGet({
        url: `/override/filter`,
        params: {
            ...filter,
        },
    }).then(getData)
}
