/* global io */
import React from 'react'
import { useId } from '@fluentui/react-hooks'
import {
    Stack,
    DefaultButton,
    Callout,
    Icon,
    IconButton,
    Text,
} from '@fluentui/react'

import { formatShortDateTime } from '../../lib/date'
import {
    getNotifications,
    getNotificationsCount,
    readNotifications,
    readAllNotifications,
    deleteNotifications,
} from '../../api'
import { useAuth } from '../../hooks/useAuth.js'
import { useNavigate } from 'react-router-dom'

import { getSetting } from '../../api/config'
import LoadingSpinner from '../LoadingSpinner'
const API_BASE = getSetting('API_BASE')

const calloutStyle = {
    minWidth: 300,
}

const Notifications = () => {
    const buttonId = useId('notifications-button')
    const menuRef = React.useRef(null)
    const { userId } = useAuth()

    const socket = React.useMemo(() => {
        return typeof io !== 'undefined' ? io(API_BASE) : null
    }, [])
    const [notifications, setNotifications] = React.useState([])
    const [count, setCount] = React.useState(-1)
    const [loading, setLoading] = React.useState(false)
    const [notificationsOpen, setNotificationsOpen] = React.useState(false)

    const loadData = React.useCallback(() => {
        setLoading(true)
        getNotifications(userId).then((notifications) => {
            setNotifications(notifications)
            setLoading(false)
        })
    }, [setLoading, getNotifications, userId, setNotifications])

    // console.log('SOCKET', socket)

    const toggleNotifications = () => {
        setNotificationsOpen((notificationsOpen) => !notificationsOpen)
    }

    const updateCount = () => {
        getNotificationsCount(userId).then((counts) => {
            const newCount = counts.unread || 0

            // Play sound when we get a new notification
            if (count !== -1 && count !== newCount) {
                document.getElementById('beep').play()
            }

            setCount(newCount)
        })
    }

    const onClickAllRead = async () => {
        await readAllNotifications(userId).then(() => {
            updateCount()
            return loadData()
        })
    }

    React.useEffect(() => {
        updateCount()

        console.log('SOCKET', socket)
        if (!socket) {
            console.log('SOCKET MISSING, turning off socket')
            return
        }
        socket.emit('load-notifications', { user: userId })

        socket.on('reload-notifications', () => {
            console.log('Got reload-notifications')
            updateCount()
        })

        return () => {
            console.log('Disconnecting socket')
            socket.disconnect()
        }
    }, [setCount, socket])

    React.useEffect(() => {
        if (!notificationsOpen) return

        loadData()
    }, [notificationsOpen, setNotifications])

    const onDelete = async (notificationId, e) => {
        e.preventDefault()
        e.stopPropagation()
        await deleteNotifications(userId, [notificationId]).then(() => {
            updateCount()
            return loadData()
        })
    }

    return (
        <>
            <NotificationButton
                buttonId={buttonId}
                menuRef={menuRef}
                onClick={toggleNotifications}
                count={count}
            />
            <audio id='beep'>
                <source src='/beep.wav' />
            </audio>
            <Callout
                hidden={!notificationsOpen}
                role='dialog'
                gapSpace={0}
                target={`#${buttonId}`}
                // onDismiss={onDismiss}
                setInitialFocus
                style={calloutStyle}>
                <Stack style={{ padding: 20 }} tokens={{ childrenGap: 10 }}>
                    {loading ? <LoadingSpinner /> : null}
                    {!loading && notifications.length === 0 ? (
                        <Text>No notifications</Text>
                    ) : null}
                    {notifications.slice(0, 5).map((notification, i) => {
                        return (
                            <Notification
                                notification={notification}
                                key={i}
                                onDelete={onDelete}
                            />
                        )
                    })}
                    {!loading && count > 0 ? (
                        <DefaultButton
                            style={{ width: 100 }}
                            onClick={onClickAllRead}>
                            Mark all read
                        </DefaultButton>
                    ) : null}
                </Stack>
            </Callout>
        </>
    )
}

const NotificationButton = ({ buttonId, menuRef, onClick, count = 0 }) => {
    const wrapStyle = { position: 'relative', marginRight: 20, display: 'flex' }
    const iconStyle = {
        alignSelf: 'center',
    }
    const badgeStyle = {
        position: 'absolute',
        right: -10,
        top: 0,
        display: 'inline-block',
        background: 'red',
        height: 20,
        width: 20,
        borderRadius: 10,
        fontSize: 'smaller',
        fontWeight: 'bold',
        textAlign: 'center',
    }
    return (
        <div style={wrapStyle}>
            <IconButton
                iconProps={{ iconName: 'Ringer' }}
                id={buttonId}
                ref={menuRef}
                onClick={onClick}
                style={iconStyle}
            />
            {count > 0 && <div style={badgeStyle}>{count}</div>}
        </div>
    )
}

const getIconNameForType = (type = null) => {
    if (!type) return 'Message'

    if (type === 'default') return 'Message'

    return 'Message'
}

const Notification = ({ userId, notification = null, onDelete = () => {} }) => {
    const navigate = useNavigate()
    if (!notification) return null

    console.log(notification)

    const {
        user,
        title,
        text,
        type,
        created,
        read = false,
        action = null,
    } = notification
    const iconName = getIconNameForType(type)

    const notificationStyle = {
        border: '1px solid #ccc',
        padding: 10,
        borderRadius: 8,
        position: 'relative',
    }

    const iconStyle = {
        fontSize: '20px',
        lineHeight: '20px',
        marginTop: 10,
    }
    const indicatorStyle = {
        position: 'absolute',
        display: 'block',
        width: 10,
        height: 10,
        borderRadius: 5,
        background: 'red',
        top: 10,
        right: 10,
    }

    const readIndicator = read ? null : <span style={indicatorStyle}></span>

    const onClick = async () => {
        // Mark notification as read
        await readNotifications(user, [notification._id])

        if (!action) return

        if (action.startsWith('/')) {
            navigate(action, { relative: 'route' })
        } else if (
            action.startsWith('http://') ||
            action.startsWith('https://')
        ) {
            window.location = action
        }
    }

    return (
        <Stack
            style={notificationStyle}
            horizontal
            verticalAlign='start'
            tokens={{ childrenGap: 10 }}
            onClick={onClick}>
            <Icon iconName={iconName} style={iconStyle} />
            <div style={{ flexGrow: 1 }}>
                <h3 style={{ margin: 0, padding: 0 }}>{title}</h3>
                <p style={{ margin: 0, padding: 0 }}>{text}</p>
                <p style={{ margin: 0, padding: 0 }}>
                    {formatShortDateTime(created)}
                </p>
            </div>
            {readIndicator}
            <IconButton
                onClick={onDelete.bind(null, notification._id)}
                iconProps={{ iconName: 'Delete' }}
            />
        </Stack>
    )
}

export default Notifications
