import React from 'react'

import {
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    MessageBar,
} from '@fluentui/react'

const dialogContentProps = {
    type: DialogType.normal,
    title: 'Confirm revoke access',
    closeButtonAriaLabel: 'Close',
    subText:
        'The selected users access to this transaction will be revoked, are you sure you want to continue?',
}

const RoleRevokeDialog = ({
    open = false,
    setOpenFalse = () => {},
    onConfirm = () => {},
    isCurrentUser = false,
}) => {
    return (
        <Dialog
            hidden={!open}
            onDismiss={setOpenFalse}
            dialogContentProps={dialogContentProps}>
            {isCurrentUser ? (
                <MessageBar>
                    You are revoking the current users role, current user will
                    default to the transaction creator.
                </MessageBar>
            ) : null}
            <DialogFooter>
                <PrimaryButton onClick={onConfirm} text='Confirm' />
                <DefaultButton onClick={setOpenFalse} text='Cancel' />
            </DialogFooter>
        </Dialog>
    )
}

export default RoleRevokeDialog
