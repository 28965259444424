import React from 'react'
import { DefaultButton, Stack, CommandBar } from '@fluentui/react'
import { useStateMachine } from '../../hooks/useStateMachine.js'

import Template from '../../components/template/Template'
import { reduceTemplate } from '../../lib/template'
import { useNavigate } from 'react-router-dom'

const TransactionData = ({
    domainTemplate,
    transactionData,
    domainFields,
    loadData,
}) => {
    const navigate = useNavigate()
    const sm = useStateMachine()
    const [showDebug, setShowDebug] = React.useState(false)

    const fieldData = sm.getFieldData()

    const data = fieldData.reduce((acc, { field, values }) => {
        if (values.length) acc[field] = values
        return acc
    }, {})

    const cutdownDomainTemplateContent = reduceTemplate(
        domainTemplate.content.contents,
        data,
    )

    const onClickDebug = () => {
        setShowDebug((showDebug) => !showDebug)
    }

    const actions = [
        {
            key: 'back',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => navigate(-1),
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: loadData,
        },
    ]

    return (
        <Stack>
            <CommandBar items={actions} />
            <Template
                readonly={true}
                contents={cutdownDomainTemplateContent}
                fields={domainFields}
            />
            <Stack tokens={{ childrenGap: 10 }}>
                <br />
                <DefaultButton onClick={onClickDebug} style={{ width: 150 }}>
                    Toggle debug
                </DefaultButton>
                {showDebug ? (
                    <pre className='debug'>
                        {JSON.stringify(transactionData, null, 4)}
                    </pre>
                ) : null}
            </Stack>
        </Stack>
    )
}

export default TransactionData
