import React, { useCallback, useEffect } from 'react'
import Page from '../components/layout/Page'
import {
    Stack,
    CommandBar,
    Persona,
    Text,
    PrimaryButton,
} from '@fluentui/react'
import { useAuth } from '../hooks/useAuth'
import FullPage from '../components/layout/FullPage'

const generateLoginUrl = (email = false, location) => {
    return [
        '/login',
        email ? '?email=' : '',
        email ? encodeURIComponent(email) : '',
        '&after=',
        encodeURIComponent(location),
    ].join('')
}

const UserSwitch = ({ userSwitch = {} }) => {
    const { email, firstName, lastName } = useAuth()
    const currentUrl = window.location.pathname + window.location.search

    if (userSwitch.type === 'user') {
        const loginUrl = generateLoginUrl(userSwitch.email, currentUrl)
        return (
            <FullPage background='url("https://source.unsplash.com/1600x900/?building")'>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Text>
                        Trying to view a link intended for a different user.
                    </Text>
                    <Text>You are currently logged in as:</Text>
                    <Stack style={{ background: '#eee', padding: 10 }}>
                        <Persona
                            text={firstName + ' ' + lastName}
                            secondaryText={email}
                        />
                    </Stack>
                    <Text>The link is for:</Text>
                    <Stack style={{ background: '#eee', padding: 10 }}>
                        <Persona
                            text={userSwitch.displayName}
                            secondaryText={userSwitch.email}
                        />
                    </Stack>

                    <PrimaryButton href={loginUrl}>
                        Login as {userSwitch.email}
                    </PrimaryButton>
                </Stack>
            </FullPage>
        )
    } else {
        const loginUrl = generateLoginUrl(null, currentUrl)
        return (
            <FullPage background='url("https://source.unsplash.com/1600x900/?building")'>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Text>
                        Trying to view a link intended for an account you do not
                        have access to.
                    </Text>
                    <Text>You are currently logged in as:</Text>
                    <Stack style={{ background: '#eee', padding: 10 }}>
                        <Persona
                            text={firstName + ' ' + lastName}
                            secondaryText={email}
                        />
                    </Stack>
                    <Text>The link is for account:</Text>
                    <Stack style={{ background: '#eee', padding: 10 }}>
                        <Text>{userSwitch.accountName}</Text>
                    </Stack>
                    <Text>Please login with a user that has access.</Text>

                    <PrimaryButton href={loginUrl}>Login</PrimaryButton>
                </Stack>
            </FullPage>
        )
    }
}

export default UserSwitch
