import React from 'react'

import { lookupValue } from '../../lib/field'

import { useTheme } from '@fluentui/react'

const ListFieldDiff = (props) => {
    const theme = useTheme()
    const { dataVal, prevVal, fieldSchema = {}, first = false } = props
    const changed = !first && prevVal !== dataVal

    return (
        <span
            style={{ color: changed ? theme.palette.themePrimary : undefined }}>
            {lookupValue(fieldSchema, dataVal)}
        </span>
    )
}

export default ListFieldDiff
