import React from 'react'
import { Link } from 'react-router-dom'
import { Text, Stack, Icon } from '@fluentui/react'

import Page from '../../components/layout/Page'

const AdminIndex = (props) => {
    return (
        <Page>
            <Text variant='xxLarge'>Admin</Text>
            <ul>
                <li>
                    <Link to='/admin/workflows'>Workflows</Link>
                </li>
                <li>
                    <Link to='/admin/templates'>Templates</Link>
                </li>
                <li>
                    <Link to='/admin/accounts'>Accounts</Link>
                </li>
            </ul>
        </Page>
    )
}

export default AdminIndex
