/* global io */
import React, { useEffect } from 'react'
import { getSetting } from '../api/config'

const API_BASE = getSetting('API_BASE')

const useSocket = (event, func = () => {}) => {
    const socket = React.useMemo(
        () => (typeof io !== 'undefined' ? io(API_BASE) : null),
        [],
    )

    useEffect(() => {
        if (!socket) {
            console.log(
                'SOCKET MISSING: turning off socket for reload-transaction',
            )
            return
        }
        socket.on(event, func)
        return () => {
            socket.off(event, func)
        }
    }, [])

    return socket
}

export default useSocket
