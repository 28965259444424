import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from '@/hooks/useAuth'

const Logout = () => {
    const { logout } = useAuth()

    React.useEffect(() => {
        logout()
    }, [])

    return <Navigate to='/' />
}

export default Logout
