import React from 'react'
// import PropTypes from 'prop-types'

import { useTheme } from '@fluentui/react'

const ListClauseDiff = (props, first = false) => {
    const theme = useTheme()
    const { dataVal } = props
    let output
    let color
    if (typeof dataVal === 'undefined') output = ''
    if (dataVal === false) {
        output = 'Standard'
        color = theme.palette.green
    }
    if (dataVal === true) {
        output = 'Edited'
        color = theme.palette.themePrimary
    }
    return (
        <span
            style={{
                color: 'white',
                backgroundColor: color,
                borderRadius: 8,
                padding: '0.25em 0.5em',
            }}>
            {/* {lookupValue(clauseSchema, dataVal)} */}
            {output}
        </span>
    )
}

ListClauseDiff.propTypes = {
    // fieldId: PropTypes.string.isRequired,
    // dataVal: PropTypes.string.isRequired,
    // fieldSchema: PropTypes.object.isRequired,
}

export default ListClauseDiff
