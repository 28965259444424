import React from 'react'
// import PropTypes from 'prop-types'

import { lookupValue } from '../../lib/field'

const ListField = (props) => {
    const { dataVal, fieldSchema = {} } = props
    return <>{lookupValue(fieldSchema, dataVal)}</>
}

ListField.propTypes = {
    // fieldId: PropTypes.string.isRequired,
    // dataVal: PropTypes.string.isRequired,
    // fieldSchema: PropTypes.object.isRequired,
}

export default ListField
