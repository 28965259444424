import { maxChildWidth, sumChildHeight } from '../utils.js'

export default {
    render: ({ stages }, render) => {
        return stages.map(render).join('\n')
    },
    background: (stage, render, { stepY, stepX, debug }) => {
        const x = stage.x
        const y2 = stage.y + stage.spaceBefore * stepY - stepY / 2
        const y1 = stage.y - stepY / 2
        const spacersBefore = stage.spaceBefore
            ? `<line x1="${x}" x2="${x}" y1="${y1}" y2="${y2}" />`
            : ``

        const say1 =
            stage.y +
            stage.height * stepY -
            stepY / 2 +
            stage.spaceBefore * stepY
        const say2 = say1 + stage.spaceAfter * stepY

        const spacersAfter = stage.spaceAfter
            ? `<line x1="${x}" x2="${x}" y1="${say1}" y2="${say2}" />`
            : ``

        const childrenContent = stage.stages.map(render).join('\n')

        // Show background rectangle for parallel stages in debug
        let bg = ''
        if (debug) {
            const x1 = stage.x - 0.5 * stepX - stage.halfWidth * stepX
            const y1 = stage.y + (stage.spaceBefore - 0.5) * stepY
            const w = stage.width * stepX
            const h = stage.height * stepY
            bg = `<rect x="${x1}" y="${y1}" width="${w}" height="${h}" fill="rgba(0,0,255,0.2)" stroke="none" />`
        }

        return [spacersBefore, childrenContent, spacersAfter, bg].join('\n')
    },
    size: (stage) => {
        // max of all children widths
        stage.width = maxChildWidth(stage.stages)

        // combined height
        stage.height = sumChildHeight(stage.stages)

        stage.halfWidth = (stage.width - 1) / 2
        stage.halfHeight = stage.height / 2

        stage.spaceBefore = 0
        stage.spaceAfter = 0
    },
    layout: (stage, layout, { stepY }) => {
        // Layout children running down the page by incrementing
        // by stepY times the child height
        let childStartY = stage.y + stage.spaceBefore * stepY
        stage.stages.map((child) => {
            layout(stage.x, childStartY, child)
            childStartY += stepY * child.height
        })
    },
}
