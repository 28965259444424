import down from './down.js'
import up from './up.js'
import circle from './circle.js'
import dot from './dot.js'
import pipeline from './pipeline.js'
import parallel from './parallel.js'
import def from './default.js'

export default {
    down,
    up,
    circle,
    dot,
    pipeline,
    parallel,
    default: def,
}
