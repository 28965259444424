import React, { useState, useCallback, useMemo } from 'react'

import { renderPipelineSVG } from 'svg-pipeline'
import {
    DetailsRowCheck,
    Persona,
    PersonaSize,
    Icon,
    Stack,
    Text,
} from '@fluentui/react'
import { formatDateTime } from '../lib/date'

const noop = () => {}

const styles = {
    root: { position: 'relative', width: 500 },
    svg: {
        position: 'absolute',
        top: 16,
        left: 48,
        zIndex: 100,
    },
    row: {
        height: 31,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #ddd',
    },
    rowRoot: { position: 'relative', top: 16 },
    icon: { cursor: 'pointer', userSelect: 'none' },
}

/*
    ===============================
    Pipeline
    ===============================
*/

const toggleArray = (arr, value) => {
    const idx = arr.indexOf(value)
    const arr2 = [...arr]
    if (idx === -1) {
        return arr2.concat([value])
    }

    arr2.splice(idx, 1)
    return arr2
}

const Pipeline = ({
    selected = null,
    onClick = noop,
    pipeline = null,
    onSelected = noop,
}) => {
    const [hiddenStages, setHiddenStages] = useState([])

    const onClickExpandable = useCallback(
        (stage) => setHiddenStages(toggleArray(hiddenStages, stage.id)),
        [setHiddenStages, hiddenStages],
    )

    const { svg, width, order, stageMap } = useMemo(() => {
        return renderPipelineSVG(pipeline.toPipeline(), {
            hiddenParents: hiddenStages,
        })
    }, [hiddenStages, pipeline])

    console.log('STAGEMAP', stageMap, order)

    const onRenderRow = useCallback(
        (item) => {
            const type = item?.data?.type
            if (type === 'stage') {
                return (
                    <PipelineStageRow
                        item={item}
                        hiddenStages={hiddenStages}
                        onClickExpandable={onClickExpandable}
                    />
                )
            }
            if (type === 'save') {
                return <PipelineSaveRow item={item} />
            }
        },
        [hiddenStages, onClickExpandable],
    )

    return (
        <div style={styles.root}>
            <Html style={styles.svg}>{svg}</Html>
            <div style={styles.rowRoot}>
                {order.map((a, i) => {
                    const stageKey = stageMap[a] ? stageMap[a].id : i + 1000
                    return (
                        <PipelineRow
                            key={stageKey}
                            item={stageMap[a] || null}
                            space={width + 15}
                            onRenderRow={onRenderRow}
                            selected={selected && selected.includes(stageKey)}
                            onSelected={onSelected}
                            onClickRow={onClick}
                        />
                    )
                })}
            </div>
        </div>
    )
}

const Html = ({ children, ...props }) => (
    <div {...props} dangerouslySetInnerHTML={{ __html: children }}></div>
)

/*
    ===============================
    Pipeline Row
    ===============================
*/

const PipelineRow = ({
    item = null,
    space = 0,
    onRenderRow = (stage) => stage.id,
    selected = false,
    onSelected = noop,
    onClickRow = noop,
}) => {
    if (item === null) {
        return <div style={styles.row}></div>
    }

    const canSelect = item?.data?.type === 'save'
    const onClick = canSelect
        ? (e) => {
              e.preventDefault()
              e.stopPropagation()
              onSelected(item)
          }
        : noop

    return (
        <div style={styles.row} onClick={() => onClickRow(item)}>
            <DetailsRowCheck
                selected={selected}
                isVisible={true}
                canSelect={canSelect}
                onClick={onClick}
                style={{ marginLeft: item.type == 'dot' ? 10 : 0 }}
            />
            <div style={{ minWidth: space }}></div>
            {onRenderRow(item)}
        </div>
    )
}

const PipelineStageRow = ({ item, hiddenStages, onClickExpandable }) => {
    console.log('ITEM', item)
    const { status } = item.data
    const titleStyle = status === 'skipped' ? { opacity: 0.3 } : {}
    const handleClickExpandable = (e) => {
        e.stopPropagation()
        onClickExpandable(item)
    }
    return (
        <Stack horizontal tokens={{ childrenGap: 4 }} verticalAlign='center'>
            <b style={titleStyle}>{item.data.title}</b>&nbsp;
            {item.data.kind === 'document' ? (
                <Icon iconName='TextDocument' />
            ) : null}
            {item.data.expandable ? (
                <Icon
                    style={styles.icon}
                    iconName={
                        hiddenStages.includes(item.id)
                            ? 'ChevronRight'
                            : 'ChevronDown'
                    }
                    onClick={handleClickExpandable}
                />
            ) : null}
        </Stack>
    )
}

const PipelineSaveRow = ({ item }) => {
    const { firstName, lastName, avatar } = item.data.user ? item.data.user : {}
    const style = item.data.status === 'archive' ? { opacity: 0.6 } : {}
    return (
        <Stack
            horizontal
            style={style}
            tokens={{ childrenGap: 4 }}
            verticalAlign='center'>
            {/* User */}
            {item.data.user ? (
                <Persona
                    text={`${firstName} ${lastName}`}
                    imageUrl={avatar}
                    size={PersonaSize.size24}
                />
            ) : null}

            {/* Icon */}
            {item.data.document ? <Icon iconName='TextDocument' /> : null}

            {/* Datetime */}
            {item.data.date ? (
                <Text variant='small'>{formatDateTime(item.data.date)}</Text>
            ) : null}
        </Stack>
    )
}

export default Pipeline
