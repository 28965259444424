import React from 'react'
import {
    Text,
    DetailsList,
    SelectionMode,
    Persona,
    PersonaSize,
    Icon,
    Stack,
    Checkbox,
} from '@fluentui/react'
import { getAllPermissions, getUserGroups, getUserPermissions } from '../../api'
import { useAuth } from '../../hooks/useAuth'
import { usePermissions } from '../../hooks/usePermissions'

export const PermissionsSection = () => {
    const [permissions, setPermissions] = React.useState([])
    const [allPermissions, setAllPermissions] = React.useState([])
    const { userId, currentAccount, accountGroups } = useAuth()

    console.log('currentAccount', currentAccount, accountGroups, allPermissions)

    React.useEffect(() => {
        getUserPermissions(userId).then(setPermissions)
        getAllPermissions().then(setAllPermissions)
    }, [setPermissions])

    const columns = [
        {
            key: 'name',
            name: 'Permission',
            fieldName: 'name',
            minWidth: 300,
            onRender(col) {
                const text = getPermissionText(col)
                return (
                    <>
                        <PermissionIcon permission={col} />
                        <span title={col}>{text}</span>
                    </>
                )
            },
        },
    ]

    return (
        <>
            <Text variant='xxLarge'>
                Permissions for {currentAccount.account.name}
            </Text>
            {/* <DetailsList
                items={permissions}
                columns={columns}
                selectionMode={SelectionMode.none}
            /> */}

            <PermissionsMatrix permissions={allPermissions} readonly />
        </>
    )
}

const getPermissionIconName = (permission) => {
    return 'ChevronRight'
}

const replacements = [
    ['getAll_', 'Read all '],
    ['getOne_', 'Read '],
    ['create_', 'Create '],
    ['update_', 'Update '],
    ['delete_', 'Delete '],
]

const getPermissionText = (permission) => {
    let found = false
    for (const [from, to] of replacements) {
        if (permission.startsWith(from)) {
            permission = permission.replace(from, to)
            found = true
            break
        }
    }

    if (!found) {
        return permission
    }

    // Space out the ":" at the end.
    permission = permission.replace(':', ' : ')

    return permission
}

const PermissionIcon = ({ permission }) => {
    const iconName = getPermissionIconName(permission)
    return (
        <div
            style={{
                display: 'inline-block',
                marginRight: 15,
                verticalAlign: 'middle',
            }}>
            <div
                style={{
                    width: 32,
                    height: 32,
                    background: '#ddd',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 16,
                }}>
                <Icon iconName={iconName} style={{ color: 'white' }} />
            </div>
        </div>
    )
}

const PermissionsMatrix = ({ permissions = {}, readonly = false }) => {
    const resourses = Object.keys(permissions)

    return (
        <Stack>
            {resourses.map((resourse, i) => {
                return (
                    <PermissionsMatrixGroup
                        key={i}
                        resourse={resourse}
                        permissions={permissions[resourse]}
                        readonly={readonly}
                    />
                )
            })}
        </Stack>
    )
}

const PermissionsMatrixGroup = ({
    resourse,
    permissions,
    readonly = false,
}) => {
    return (
        <Stack>
            <Text variant='large'>{resourse}</Text>
            <Stack horizontal wrap>
                {permissions.map((permission, i) => {
                    return (
                        <PermissionsMatrixItem
                            key={i}
                            permission={permission}
                            resourse={resourse}
                            readonly={readonly}
                        />
                    )
                })}
            </Stack>
        </Stack>
    )
}

const PermissionsMatrixItem = ({ permission, resourse, readonly = false }) => {
    const { hasPermission } = usePermissions()
    const fullPermission = permission + '_' + resourse
    const checked = hasPermission(fullPermission)

    const label = permission
    return (
        <div>
            <Checkbox
                styles={{ root: { margin: 10, display: 'inline-block' } }}
                label={label}
                checked={checked}
                disabled={readonly}
            />
        </div>
    )
}
