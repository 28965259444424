import axios from 'axios'
import { authPost, getData, refreshToken } from '../common'

export const resetPassword = (values) =>
    authPost({
        url: '/reset-password',
        headers: { 'X-Authorization': `Bearer ${refreshToken}` },
        json: true,
        data: values,
    }).then(getData)

export const fetchSiteInfo = async () => {
    return axios.get('/siteinfo.txt').then(getData)
}

export const fetchSiteVersion = async () => {
    return axios.get('/version.txt').then(getData)
}
