import React from 'react'

import { Stack, CommandBar } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import ActivityDetailsList from '../../components/ActivityDetailsList'
import { buildActivityFileExport } from '../../lib/activity'
import { downloadFile } from '../../lib/file'
import { populateActivity } from '../../lib/activity'

const TransactionActivity = ({ transactionActivity, loadData }) => {
    const role = false
    const activity = React.useMemo(() => {
        return transactionActivity
            .filter(({ user }) => (role ? user._id === role.user._id : true))
            .map(populateActivity)
    }, [transactionActivity, role])
    const navigate = useNavigate()

    const handleExport = async () => {
        const timestamp = new Date().toISOString()
        const fileName = `Transaction-Activity-${timestamp}.csv`
        const downloadContent = buildActivityFileExport(activity)

        await downloadFile(fileName, 'text/csv', downloadContent)
    }

    const actions = [
        {
            key: 'back',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => navigate(-1),
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: loadData,
        },
        {
            key: 'export',
            text: 'Export',
            iconProps: { iconName: 'Export' },
            onClick: handleExport,
        },
    ]

    return (
        <Stack>
            <CommandBar items={actions} />
            <ActivityDetailsList activity={activity} />
        </Stack>
    )
}

export default TransactionActivity
