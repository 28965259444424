import {
    loadAuthState,
    clearAuthState,
    mergeAuthState,
    saveAuthState,
    setAuthTokens,
} from './common'

export * from './module/index.js'

// Profile
{
    window.saveProfile = (name) => {
        const json = JSON.stringify(loadAuthState())
        localStorage.setItem(`leasle-auth-profile-${name}`, json)
    }

    window.clearAllProfiles = () => {
        for (let i = 0; i < localStorage.length; i += 1) {
            const key = localStorage.key(i)
            if (!key.startsWith('leasle-auth-profile-')) continue
            localStorage.removeItem(key)
        }
        window.location.reload()
    }

    window.clearProfile = (name) => {
        localStorage.removeItem(`leasle-auth-profile-${name}`)
        window.location.reload()
    }

    window.loadProfile = (name) => {
        const key = `leasle-auth-profile-${name}`
        if (!localStorage.getItem(key)) {
            throw new Error(`Unknown profile name: ${key}`)
        }
        const json = JSON.parse(localStorage.getItem(key))
        saveAuthState(json)
        window.location.reload()
    }

    window.getProfiles = () => {
        return Object.keys(localStorage)
            .filter((key) => key.match(/^leasle-auth-profile-/))
            .map((key) => key.replace(/^leasle-auth-profile-/, ''))
    }
}

export {
    loadAuthState,
    saveAuthState,
    clearAuthState,
    mergeAuthState,
    setAuthTokens,
}
