import React from 'react'
import { IconButton, getTheme } from '@fluentui/react'

const theme = getTheme()

const FieldDetailsButton = ({ id, onClick = () => {} }) => (
    <IconButton
        id={id}
        iconProps={{ iconName: 'ColumnOptions' }}
        tabIndex='-1'
        title='Field details'
        ariaLabel='Field details'
        onClick={onClick}
        styles={{
            root: {
                marginBottom: -3,
                color: theme.palette.themeLight,
            },
        }}
    />
)

export default FieldDetailsButton
