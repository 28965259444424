const getFieldByDataVal = (schemaItems, value) => {
    const upperCaseDataVal = value.toUpperCase()
    return schemaItems.find(
        ({ value }) => value.toUpperCase() === upperCaseDataVal,
    )
}

const lookupValue = (fieldSchema, value) => {
    if (!value || fieldSchema.type !== 'LIST') {
        return value || ''
    }

    if (!fieldSchema.items) {
        return 'Invalid Value'
    }

    const field = getFieldByDataVal(fieldSchema.items, value)
    if (!field) {
        return 'Invalid Value'
    }
    return field.title
}

export { lookupValue }
