import { useLocation } from 'react-router-dom'

const useSearchParams = () => {
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)

    return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
        acc[key] = value
        return acc
    }, {})
}

export { useSearchParams }
