import React from 'react'
import TemplateContent from './TemplateContent'
import Docusign from '../../components/template/Docusign/Docusign'
import ApproveStage from '../../components/template/ApproveStage/ApproveStage'
import EmailStage from '../../components/template/EmailStage/EmailStage'
import { TransactionError } from './TransactionError.js'

export const currentStageTemplateWrap = ({
    transaction,
    currentStage,
    transactionFiles,
    transactionHistory,
    transactionData,
    loadData,
    template,
    fields,
}) => {
    if (transaction.error) {
        return (
            <div label='Template'>
                <TransactionError
                    title={transaction.error}
                    msg={`Transaction currently with: ${transaction.currentRole}`}
                />
            </div>
        )
    } else if (currentStage.kind === 'docusign') {
        return (
            <div label='DocuSign'>
                <Docusign
                    transaction={transaction}
                    transactionFiles={transactionFiles}
                    transactionHistory={transactionHistory}
                    loadData={loadData}
                />
            </div>
        )
    } else if (currentStage.kind === 'approve') {
        return (
            <div label='Approve'>
                <ApproveStage
                    transaction={transaction}
                    transactionFiles={transactionFiles}
                    transactionHistory={transactionHistory}
                    loadData={loadData}
                    template={template}
                    fields={fields}
                />
            </div>
        )
    } else if (currentStage.kind === 'email') {
        return (
            <div label='Email'>
                <EmailStage
                    transaction={transaction}
                    transactionFiles={transactionFiles}
                    transactionHistory={transactionHistory}
                    transactionData={transactionData}
                    loadData={loadData}
                    template={template}
                    fields={fields}
                />
            </div>
        )
    } else if (template) {
        return (
            <div label='Template'>
                <TemplateContent
                    transaction={transaction}
                    template={template}
                    transactionHistory={transactionHistory}
                    fields={fields}
                    currentStage={currentStage}
                    loadData={loadData}
                />
            </div>
        )
    }

    return null
}
