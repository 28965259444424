import React from 'react'

import {
    Modal,
    Stack,
    Dropdown,
    IconButton,
    PrimaryButton,
    DefaultButton,
    TextField,
} from '@fluentui/react'

import { patchRolesAccount } from '../../../api'
import { useAuth } from '@/hooks/useAuth'

const EditRolesModal = ({
    isOpen,
    onClose = () => {},
    accountId = null,
    loadData = () => {},
    user = null,
}) => {
    if (!accountId) return null
    const title = 'Edit Roles'

    const accountRole = user?.accountGroups.find(
        (accountRole) => accountRole.account === accountId,
    )

    console.log('USER', user, accountId, accountRole)

    const [data, setData] = React.useState([])

    React.useEffect(() => {
        setData(accountRole?.roles || [])
    }, [accountRole])

    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setData((data) => {
            return Object.assign({}, data, { [name]: value })
        })
    }

    const onEdit = () => {
        console.log('DATA', data)
        const payload = {
            userId: user._id,
            roles: data,
        }

        patchRolesAccount(accountId, payload).then((res) => {
            console.log('RES', res)
            setData({})
            loadData()
            onClose()
        })
    }

    const removeRole = (i) => {
        return () => {
            setData((data) => {
                const newData = data.slice(0)
                newData.splice(i, 1)
                return newData
            })
        }
    }

    if (!user) return null

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em' }}
                tokens={{ childrenGap: '1em' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        // styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </div>
                <p>Editing roles for: {user.email}</p>
                <Stack
                    horizontalAlign='space-between'
                    style={{ width: '100%' }}>
                    {data.map((role, i) => {
                        return (
                            <div key={i}>
                                <span style={{ textTransform: 'capitalize' }}>
                                    {role}
                                </span>
                                <IconButton
                                    onClick={removeRole(i)}
                                    iconProps={{
                                        iconName: 'Delete',
                                    }}
                                />
                            </div>
                        )
                    })}
                </Stack>
                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='end'>
                    <PrimaryButton onClick={onEdit}>Edit</PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default EditRolesModal
