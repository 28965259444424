import { client2, getData, refreshToken, authPost, authGet } from '../common.js'

const login = (email, password) =>
    client2
        .post('/login', {
            email,
            password,
        })
        .then(getData)

const signup = (data) => client2.post('/user', data).then(getData)

const invite = (payload) => client2.post('/invite', payload).then(getData)

const refreshTokens = () =>
    authPost({
        url: '/refresh',
        headers: { 'X-Authorization': `Bearer ${refreshToken}` },
    }).then(getData)

const getAllPermissions = () => {
    return authGet({
        url: '/permissions',
        headers: { 'X-Authorization': `Bearer ${refreshToken}` },
    }).then(getData)
}

// Register refreshTokens on window so it can be accessed
// in authError
window.refreshTokens = refreshTokens

export { login, signup, invite, refreshTokens, getAllPermissions }
