import React from 'react'
import { useAuth } from './useAuth'
import { getUserPermissions } from '../api'
const PermissionsContext = React.createContext(null)

function PermissionsProvider(props) {
    const { currentAccount, userId, loading } = useAuth()
    const [permissions, setPermissions] = React.useState(null)

    const loadPermissions = React.useCallback(() => {
        if (loading) return
        if (!userId) return
        getUserPermissions(userId).then((permissions) => {
            setPermissions(permissions)
            console.log('permissions', permissions)
        })
    }, [setPermissions, userId, loading])

    React.useEffect(() => {
        if (loading) {
            return
        }
        loadPermissions()
    }, [currentAccount, loading])

    const hasPermission = React.useCallback(
        (permission) => {
            if (!permissions) return false
            return permissions.includes(permission)
        },
        [permissions],
    )

    const hasPermissions = React.useCallback(
        (permissions) => {
            return permissions.every(hasPermission)
        },
        [hasPermission],
    )

    return (
        <PermissionsContext.Provider
            {...props}
            value={{ hasPermission, hasPermissions }}
        />
    )
}

const usePermissions = () => {
    return React.useContext(PermissionsContext)
}

export { PermissionsProvider, usePermissions }
