class Stage {
    static pallette = {
        complete: 'rgb(136,194,46)',
        in_progress: 'rgb(230,221,19)',
        skipped: 'red',
        archive: 'red',
        default: 'white',
    }
    constructor(id, stageId, stage, status, type = 'circle') {
        this.id = id
        this.stageId = stageId
        this.stage = stage
        this.status = status
        this.type = type
        this.expandable = false
    }
    toPipeline() {
        return {
            id: this.id,
            type: this.type,
            color: this.statusToColor(this.status),
            data: {
                type: 'stage',
                title: this?.stage?.title,
                i: this.id,
                stageId: this.stageId,
                status: this.status,
                expandable: this.expandable,
            },
        }
    }

    statusToColor(status = 'default') {
        return Stage.pallette[status] || Stage.pallette['default']
    }
}

class Save {
    constructor(history) {
        this.history = history
    }
}

const savePallette = {
    complete: 'rgb(136,194,46)',
    in_progress: 'rgb(19,157,234)',
    archive: 'lightgray',
    default: 'transparent',
}

const saveStatusToColor = (status = 'default') => {
    return savePallette[status] || savePallette['default']
}

class Pipeline {
    constructor(
        transaction = {},
        transactionHistory = [],
        hideArchive = false,
    ) {
        this.transaction = transaction
        this.transactionHistory = transactionHistory
        this.archivedOrderings = new Set()
        this.todoOrderings = new Set()

        this.filteredHistory = !hideArchive
            ? transactionHistory
            : transactionHistory.filter(({ status }) => status !== 'archive')

        const [stageOrdering, lastStage] = this.filteredHistory.reduce(
            ([ordering, last], history) => {
                if (history.stage !== last) {
                    history.stageIndex = ordering.length
                    if (history.status === 'archive') {
                        this.archivedOrderings.add(history.stageIndex)
                    }
                    return [ordering.concat([history.stage]), history.stage]
                }
                history.stageIndex = ordering.length - 1
                if (history.status === 'archive') {
                    this.archivedOrderings.add(history.stageIndex)
                }
                return [ordering, last]
            },
            [[], null],
        )

        this.stageOrdering = stageOrdering
        this.lastStage = lastStage

        // If we don't have any transaction history, for example viewing a pipeline
        // from "create transaction" screen. We need to manually add the stages.
        if (!transactionHistory.length) {
            for (let i = 0; i < transaction.stages.length; i += 1) {
                this.todoOrderings.add(i)
                this.stageOrdering.push(i)
            }
        } else {
            // **********************************
            // Compute TODO stages
            // **********************************
            // Todo stages appear at the end of the pipeline.
            // For example, if you have a 5 stage pipeline,
            // currently on stage 3, then "todo stages" would
            // be 4 and5

            // If we are on the current stage, but there have been no saves
            // we need to also include the current stage in the todo stages
            const { currentStage } = transaction
            const offset = lastStage === currentStage ? 1 : 0
            this.currentOrdering = stageOrdering.length - offset

            for (
                let i = currentStage + offset;
                i < transaction.stages.length;
                i += 1
            ) {
                this.todoOrderings.add(stageOrdering.length)
                this.stageOrdering.push(i)
            }
        }

        // Compute transaction stages
        this.transactionStages = this.stageOrdering.map(
            (stageId, i) =>
                new Stage(
                    i,
                    stageId,
                    transaction.stages[stageId],
                    this.getStageStatus(i),
                ),
        )

        console.log('this.transactionStages', this.transactionStages)
        // if (this.transactionStages.length) {
        //     // Set the first type to be a down arrow
        //     this.transactionStages[0].type = 'down'
        //     if (this.transactionStages.length >= 2) {
        //         // Set the last type to be a up arrows
        //         this.transactionStages[this.transactionStages.length - 1].type =
        //             'up'
        //     }
        // }

        // Compute transaction saves
        this.saves = {}
        for (let i = 0; i < this.stageOrdering.length; i += 1) {
            this.saves[i] = []
        }

        this.filteredHistory.forEach((history) => {
            console.log('HS', history.stage, history.status, history)
            const status = this.transactionStages[history.stageIndex].status
            this.transactionStages[history.stageIndex].expandable = true
            this.saves[history.stageIndex].push({
                id: history._id,
                type: 'dot',
                color: saveStatusToColor(status),
                data: {
                    type: 'save',
                    status: history.status,
                    user: history.createdBy,
                    date: history.created,
                    document: history.upload || null,
                    party: history.createdParty,
                },
            })
        })
    }

    getStageStatus(stageId) {
        if (this.archivedOrderings.has(stageId)) return 'archive'
        if (stageId === this.currentOrdering) return 'in_progress'
        if (this.todoOrderings.has(stageId)) return 'default'
        if (stageId < this.currentOrdering) return 'complete'
        return 'default'
    }

    toPipeline() {
        const pipelineData = []
        for (let i = 0; i < this.transactionStages.length; i += 1) {
            pipelineData.push(this.transactionStages[i].toPipeline())
            this.saves[i].forEach((save) => pipelineData.push(save))
        }

        return pipelineData
    }
}

export default Pipeline
