import React from 'react'
import { CommandBar, Dropdown, Panel, TextField } from '@fluentui/react'
import { updateTransactionRole } from '../../../api'
const accessOptions = [
    {
        key: 'standard',
        text: 'Standard',
    },
    {
        key: 'view',
        text: 'View Only',
    },
]

const roleOptions = (roles) => {
    return roles.map(({ role }) => {
        return { key: role, text: role }
    })
}

const getTeamForRole = (roles, roleName) => {
    const role = roles.find(({ role }) => role === roleName)
    return role ? role.team : ''
}

const RoleManagePanel = ({
    open = false,
    setOpenTrue = () => {},
    setOpenFalse = () => {},
    roles = [],
    transactionId = '',
    loadData,
}) => {
    // const defaultRole = roles[0].role
    const [values, setValues] = React.useState({
        email: roles[0].email || '',
        displayName: `${roles[0].user.firstName} ${roles[0].user.lastName}`,
        access: 'standard',
    })

    const [changed, setChanged] = React.useState(false)

    const fixedRole = roles.length == 1

    const setValue = (field) => {
        return (e, value = {}) => {
            setValues((values) => {
                const newValues = {
                    ...values,
                    [field]: e.target.value || value.key,
                }

                return newValues
            })

            setChanged(true)
        }
    }

    const onSubmit = React.useCallback(() => {
        const payload = fixedRole
            ? {
                  role: roles[0].role,
                  ...values,
              }
            : values
        updateTransactionRole(transactionId, payload)
            .then(() => {
                // Updated roles
                console.log('Updated roles')
                loadData()
            })
            .catch((err) => {
                // Error updating roles
            })
    }, [transactionId, roles, values])

    const handleSave = () => {
        // @TODO: update security for role
        setOpenFalse()
    }

    const commands = [
        {
            key: 'save',
            name: 'Save',
            iconProps: { iconName: 'Save' },
            disabled: !changed,
            onClick: handleSave,
        },
        {
            key: 'cancel',
            name: 'Cancel',
            iconProps: { iconName: 'Cancel' },
            onClick: setOpenFalse,
        },
        {
            key: 'reinvite',
            name: 'Reinvite',
            iconProps: { iconName: 'Send' },
            onClick: onSubmit,
            disabled: roles[0].status !== 'pending',
        },
    ]

    return (
        <Panel isOpen={open} headerText='Manage Role' onDismiss={setOpenFalse}>
            <CommandBar items={commands} />
            <Dropdown
                label='Role'
                options={roleOptions(roles)}
                disabled={fixedRole}
                // onChange={setValue('role')}
                // value={values.role}
                defaultSelectedKey={roles[0].role}
            />
            <TextField
                label='Email Address'
                onChange={setValue('email')}
                value={values.email}
                disabled={true}
            />
            <TextField
                label='Team'
                disabled={true}
                value={getTeamForRole(roles, roles[0].role)}
            />
            <Dropdown
                label='Access'
                options={accessOptions}
                onChange={setValue('access')}
                defaultSelectedKey={values.access}
            />
        </Panel>
    )
}

export default RoleManagePanel
