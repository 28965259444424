import React from 'react'
// import { Stack, PrimaryButton, TextField } from '@fluentui/react'
import { Stack } from '@fluentui/react'

const styles = {
    root: {
        background: 'white',
        padding: 16,
        width: 360,
        gap: 12,
        position: 'relative',
        paddingTop: 32,
    },
    logo: {
        width: 147,
        background: 'black',
        padding: '11px 50px',
        borderRadius: 50,
        position: 'absolute',
        top: -26,
        left: 55,
    },
}

const Background = ({ background }) => {
    return (
        <div
            style={{
                position: 'absolute',
                inset: 0,
                background: `${background}, #ccc`,
                backgroundSize: 'cover',
                zIndex: 0,
            }}></div>
    )
}

const Logo = () => {
    return <img src='/images/logo-inverse.svg' style={styles.logo} />
}

const FullPage = ({
    children,
    background = undefined,
    logo = true,
    style = {},
}) => {
    const content = logo ? (
        <Stack style={{ ...styles.root, ...style }}>
            <Logo />
            {children}
        </Stack>
    ) : (
        children
    )

    return (
        <Stack
            horizontalAlign='center'
            verticalAlign='center'
            style={{
                flexGrow: 1,
            }}>
            <Background background={background} />
            {content}
        </Stack>
    )
}

export default FullPage
