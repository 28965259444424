import React from 'react'

import {
    DetailsList,
    SelectionMode,
    Persona,
    PersonaSize,
    Stack,
    Text,
    CommandBar,
    Selection,
    PrimaryButton,
    DefaultButton,
    IconButton,
    Modal,
    TextField,
    Icon,
} from '@fluentui/react'
import { formatShortDateTime } from '../../lib/date'

import {
    downloadTransactionFile,
    uploadTransaction,
    downloadTransactionFileAll,
    postTransactionFileTags,
} from '../../api'
import FileUploadInput from '../../components/FileUploadInput.js'
import File from '../../components/File.js'
import { useNavigate } from 'react-router-dom'

const columns = [
    {
        key: 'date',
        name: 'Date',
        fieldName: 'created',
        minWidth: 100,
        maxWidth: 150,

        onRender(item, index, col) {
            return formatShortDateTime(item[col.fieldName])
        },
    },
    {
        key: 'file',
        name: 'File',
        onRender: (row) => {
            return <File {...row} />
        },
    },
    {
        key: 'fileTags',
        name: 'Tags',
        minWidth: 250,
        maxWidth: 250,
        onRender: ({ tags = [] }) => {
            return (
                <Stack horizontal wrap tokens={{ childrenGap: '10 5' }}>
                    {tags.map((tag, i) => {
                        return <Tag key={i} title={tag} showCross={false} />
                    })}
                </Stack>
            )
        },
    },
    {
        key: 'user',
        name: 'User',
        minWidth: 150,
        maxWidth: 250,
        onRender: (row) => {
            return row.user ? (
                <Persona
                    text={`${row.user.firstName} ${row.user.lastName}`}
                    size={PersonaSize.size24}
                />
            ) : (
                '-'
            )
        },
    },
]

const TransactionFiles = ({ transaction, transactionFiles, loadData }) => {
    const items = transactionFiles

    const navigate = useNavigate()
    const [selectedKey, setSelectedKey] = React.useState(null)
    const [uploadModalOpen, setUploadModalOpen] = React.useState(false)
    const [addTagModalOpen, setAddTagModalOpen] = React.useState(false)

    const [selection, setSelection] = React.useState(
        new Selection({
            onSelectionChanged: () => {
                setSelectedKey(selection.getSelection()[0])
            },
        }),
    )

    const onDownload = () => {
        downloadTransactionFile(selectedKey.transaction, selectedKey._id)
    }

    const onDownloadAll = () => {
        downloadTransactionFileAll(transaction._id)
    }

    const onUpload = () => {
        setUploadModalOpen(true)
    }

    const onAddTag = () => {
        setAddTagModalOpen(true)
    }

    const actions = [
        {
            key: 'back',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => navigate(-1),
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: loadData,
        },
        {
            key: 'upload',
            text: 'Upload',
            iconProps: { iconName: 'Upload' },
            onClick: onUpload,
        },
        {
            key: 'download',
            text: 'Download',
            iconProps: { iconName: 'Download' },
            onClick: onDownload,
            disabled: !selectedKey,
        },
        {
            key: 'addTag',
            text: 'Edit Tags',
            iconProps: { iconName: 'Tag' },
            onClick: onAddTag,
            disabled: !selectedKey,
        },
        {
            key: 'downloadZip',
            text: 'Download All',
            iconProps: { iconName: 'ZipFolder' },
            onClick: onDownloadAll,
        },
    ]

    return (
        <Stack>
            <CommandBar items={actions} />
            <DetailsList
                columns={columns}
                items={items}
                selectionMode={SelectionMode.single}
                selection={selection}
            />
            <UploadModal
                transaction={transaction}
                isOpen={uploadModalOpen}
                onClose={() => setUploadModalOpen(false)}
            />
            {selectedKey ? (
                <EditTagModal
                    transaction={transaction}
                    file={selectedKey}
                    isOpen={addTagModalOpen}
                    onClose={() => setAddTagModalOpen(false)}
                    loadData={loadData}
                />
            ) : null}
        </Stack>
    )
}

const UploadModal = ({ isOpen = false, onClose = () => {}, transaction }) => {
    const title = 'Upload'

    const [files, setFiles] = React.useState([])

    const onUpload = () => {
        const formData = new FormData()

        files.forEach((file) => {
            formData.append('file', file)
        })

        uploadTransaction(transaction._id, formData).then((res) => {
            window.location.reload()
        })
    }

    const onChange = (event) => {
        setFiles(Array.from(event.target.files))
    }

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em', position: 'relative' }}
                tokens={{ childrenGap: '1em' }}>
                <Stack horizontal>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        style={{ position: 'absolute', top: 10, right: 10 }}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </Stack>

                <Text>Select files to upload:</Text>
                <FileUploadInput files={files} onChange={onChange} />

                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='center'>
                    <PrimaryButton onClick={onUpload}>Upload</PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}

const EditTagModal = ({
    isOpen = false,
    onClose = () => {},
    transaction,
    file,
    loadData,
}) => {
    const title = 'Edit File Tags'

    console.log('selectedKey', file)
    const [tags, setTags] = React.useState(file.tags || [])
    const [tagValue, setTagValue] = React.useState('')
    // const [files, setFiles] = React.useState([])

    React.useEffect(() => {
        setTags(file.tags || [])
    }, [file ? file._id : null])

    const onSubmit = () => {
        // const formData = new FormData()
        // files.forEach((file) => {
        //     formData.append('file', file)
        // })
        // uploadTransaction(transaction._id, formData).then((res) => {
        //     window.location.reload()
        // })
        postTransactionFileTags(transaction._id, file._id, tags).then(() => {
            onClose()
            loadData()
        })
    }

    const onChange = (event) => {
        // setFiles(Array.from(event.target.files))
    }

    const onSubmitTag = (e) => {
        e.preventDefault()
        setTags((tags) => {
            return tagValue ? tags.concat([tagValue]) : tags
        })
        setTagValue('')
    }

    const onRemoveTag = (i) => {
        const newTags = [...tags]
        newTags.splice(i, 1)
        setTags(newTags)
    }

    return (
        <Modal isOpen={isOpen} onDismiss={onClose} isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '3em', position: 'relative' }}
                tokens={{ childrenGap: '1em' }}>
                <Stack horizontal>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        style={{ position: 'absolute', top: 10, right: 10 }}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </Stack>

                {tags.length === 0 ? <Text>No tags</Text> : null}

                <Stack
                    horizontal
                    tokens={{ childrenGap: '10 15', maxWidth: 300 }}
                    wrap>
                    {tags.map((tag, i) => {
                        return (
                            <Tag
                                key={i}
                                title={tag}
                                onRemove={onRemoveTag.bind(null, i)}
                            />
                        )
                    })}
                </Stack>

                <form
                    onSubmit={onSubmitTag}
                    style={{ display: 'flex', flexDirection: 'col' }}>
                    <TextField
                        value={tagValue}
                        onChange={(e) => setTagValue(e.target.value)}
                    />
                    <DefaultButton onClick={onSubmitTag}>Add Tag</DefaultButton>
                </form>
                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='center'>
                    <PrimaryButton onClick={onSubmit}>Save</PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}

const Tag = ({ title, onRemove = () => {}, showCross = true }) => {
    return (
        <Stack
            style={{
                display: 'flex',
                background: '#ec008c',
                padding: '0.25em 0.5em',
                borderRadius: 10,
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
            <Text style={{ color: 'white' }}>{title}</Text>
            {showCross ? (
                <Icon
                    iconName='Cancel'
                    style={{ marginLeft: 5, color: 'white', cursor: 'pointer' }}
                    title='Remove tag'
                    onClick={onRemove}
                />
            ) : null}
        </Stack>
    )
}

export default TransactionFiles
