import React from 'react'
import { Text, TextField as FUITextField } from '@fluentui/react'

const styles = {
    wrap: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        margin: '0 16px 8px',
    },
    heading: {
        textAlign: 'center',
        fontWeight: 600,
        margin: '12px 0px 8px',
    },
    textField: {
        field: {
            borderBottom: '1px solid #aaa',
        },
        subComponentStyles: {
            label: { root: { fontSize: 'small', color: '#4d4d4d' } },
        },
    },
}
const Wrap = ({ children, style = {} }) => {
    return <div style={{ ...styles.wrap, ...style }}>{children}</div>
}

const Heading = ({ children, style = {} }) => {
    return (
        <Text
            variant='mediumPlus'
            block
            style={{ ...styles.heading, ...style }}>
            {children}
        </Text>
    )
}

const TextField = (props) => {
    return <FUITextField borderless styles={styles.textField} {...props} />
}

export { Heading, Wrap, TextField }
