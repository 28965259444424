import React from 'react'
import { loadTheme } from '@fluentui/react'
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import { ThemeProvider } from '@fluentui/react'
import { AuthProvider } from './hooks/useAuth'
import { MenuProvider } from './hooks/useMenu'
import { FormProvider } from './components/controls/useForm'
import { BrowserRouter } from 'react-router-dom'
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons'

import { StateMachineProvider } from './hooks/useStateMachine.js'
import { SettingsProvider } from './hooks/useSettingsNew.js'
import StateMachine from 'leasle-state-machine'
import theme from './theme.js'
import { PermissionsProvider } from './hooks/usePermissions.js'

const Providers = ({ children }) => {
    loadTheme(theme)

    // Init icons
    initializeIcons()
    initializeFileTypeIcons()

    const sm = new StateMachine({ word: false })
    return (
        <SettingsProvider>
            <ThemeProvider id='App'>
                <AuthProvider>
                    <PermissionsProvider>
                        <MenuProvider>
                            <StateMachineProvider value={sm}>
                                <FormProvider>
                                    <BrowserRouter>{children}</BrowserRouter>
                                </FormProvider>
                            </StateMachineProvider>
                        </MenuProvider>
                    </PermissionsProvider>
                </AuthProvider>
            </ThemeProvider>
        </SettingsProvider>
    )
}

export default Providers
