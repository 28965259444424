import React from 'react'
import {
    Text,
    DetailsList,
    SelectionMode,
    Persona,
    PersonaSize,
    PrimaryButton,
    DefaultButton,
} from '@fluentui/react'
import { getGroups } from '../../api'
import { useAuth } from '../../hooks/useAuth'

export const AccountsSection = () => {
    const { accountGroups, setCurrentAccount, currentAccount } = useAuth()
    const accounts = accountGroups.map(({ account }) => account)

    console.log('accountGroups', accountGroups)

    const columns = [
        {
            key: 'icon',
            text: '',
            onRender(col) {
                return (
                    <Persona
                        size={PersonaSize.size32}
                        text={col.name}
                        hidePersonaDetails={true}
                    />
                )
            },
            minWidth: 40,
            maxWidth: 40,
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 300,
        },
        {
            key: 'button',
            name: '',
            onRender(col) {
                const isActiveAccount = col._id === currentAccount.account._id
                return (
                    <>
                        <DefaultButton
                            disabled={isActiveAccount}
                            onClick={() => {
                                setCurrentAccount(col._id)
                            }}>
                            Activate
                        </DefaultButton>
                        {isActiveAccount ? (
                            <Text style={{ color: 'green', marginLeft: 20 }}>
                                Account currently active
                            </Text>
                        ) : null}
                    </>
                )
            },
            minWidth: 300,
            maxWidth: 300,
        },
    ]

    return (
        <>
            <Text variant='xxLarge'>Accounts</Text>
            <DetailsList
                items={accounts}
                columns={columns}
                selectionMode={SelectionMode.none}
            />
        </>
    )
}
