import { Stack } from '@fluentui/react'
import React from 'react'

import MiniHeader from '../Header/MiniHeader.js'

const mainStyle = {
    flex: '1 1 0',
    display: 'flex',
    flexDirection: 'row',
}

const PortalPage = ({ children }) => {
    return (
        <>
            <MiniHeader />
            <Stack style={mainStyle}>
                <div id='content'>{children}</div>
            </Stack>
        </>
    )
}

export default PortalPage
