import React from 'react'
import { Link } from 'react-router-dom'
import { Text, Stack, Icon } from '@fluentui/react'

import Page from '../../components/layout/Page'
import { getTemplates } from '../../api'

const Templates = (props) => {
    const [templates, setTemplates] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)
        getTemplates().then((templates) => {
            setTemplates(templates)
            setLoading(false)
        })
    }, [setTemplates, setLoading])

    return (
        <Page>
            <Text variant='xxLarge'>Templates</Text>
            <br />
            <Text variant='large'>Count: {templates.length}</Text>
            <Stack tokens={{ childrenGap: 20 }}>
                {templates.map((template, i) => {
                    return <TemplatePanel template={template} key={i} />
                })}
            </Stack>
            <pre>{JSON.stringify(templates, null, 4)}</pre>
        </Page>
    )
}

const TemplatePanel = ({ template }) => {
    const iconName = template.type === 'data' ? 'TextDocument' : 'WordDocument'
    return (
        <Stack
            style={{
                border: '2px solid #ccc',
                padding: 20,
                margin: '10px 0 10px 0px',
                borderRadius: 8,
            }}
            horizontal
            tokens={{ childrenGap: 20 }}>
            <Icon
                iconName={iconName}
                style={{ width: 80, height: 80, fontSize: 80 }}
            />
            <Stack>
                <ItemDetails
                    size='large'
                    label='Title'
                    value={template.title}
                />
                <ItemDetails size='large' label='Name' value={template.name} />
                <ItemDetails label='Description' value={template.description} />
                <ItemDetails label='Type' value={template.type} />
                {template.type === 'document' ? (
                    <ItemDetails
                        label='Word Document'
                        value={template.wordDocumentName}
                    />
                ) : null}
                <ItemDetails label='Created' value={template.created} />
                <ItemDetails label='Updated' value={template.updated} />
                <Link target='_blank' to={`/template/${template.name}`}>
                    Open Template
                </Link>
            </Stack>
        </Stack>
    )
}

const ItemDetails = ({
    label = '',
    size = 'medium',
    value = '',
    block = false,
}) => {
    return (
        <Text variant={size}>
            <b style={{ width: 125, display: 'inline-block' }}>{label}: </b>
            {block ? <br /> : null}
            {value}
        </Text>
    )
}

export default Templates
