import { useState } from 'react'

const useValues = (initState = {}) => {
    const [values, setValues] = useState(initState)

    const setValue = (field) => {
        return (event) => {
            if (!event.isTrusted) return
            setValues((state) => ({
                ...state,
                [field]: event.target.value,
            }))
        }
    }

    return { values, setValue }
}

export { useValues }
