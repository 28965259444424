import React from 'react'
import { CommandBar, Text, TextField, Stack } from '@fluentui/react'
import { useAuth } from '../../hooks/useAuth'

export const ProfileSection = ({ onClickSave }) => {
    const auth = useAuth()
    const [dirty, setDirty] = React.useState(false)

    const [state, setState] = React.useState({})
    const { firstName, lastName } = state

    const onChange = React.useCallback(
        (e) => {
            setState((state) => ({ ...state, [e.target.name]: e.target.value }))
            setDirty(true)
        },
        [setState, setDirty],
    )

    React.useEffect(() => {
        if (firstName !== auth.firstName) {
            setState((state) => ({ ...state, firstName: auth.firstName }))
        }
    }, [setState, auth.firstName])

    React.useEffect(() => {
        if (lastName !== auth.lastName) {
            setState((state) => ({ ...state, lastName: auth.lastName }))
        }
    }, [setState, auth.lastName])

    const commands = [
        {
            key: 'save',
            text: 'Save',
            iconProps: { iconName: 'Save' },
            disabled: !dirty,
            onClick: () => {
                onClickSave({ firstName, lastName })
            },
        },
    ]

    return (
        <Stack>
            <Text variant='xxLarge'>Profile</Text>
            <CommandBar items={commands} styles={{ root: { padding: 0 } }} />
            <TextField
                name='firstName'
                label='First Name'
                value={firstName}
                onChange={onChange}
                styles={{ root: { width: 300 } }}
            />
            <TextField
                name='lastName'
                label='Last Name'
                value={lastName}
                onChange={onChange}
                styles={{ root: { width: 300 } }}
            />
        </Stack>
    )
}
