import React from 'react'

const buttonStyle = {
    width: '100%',
    height: 42,
    appearance: 'none',
    border: '0.5px solid #aaa',
    borderRadius: 21,
    background: 'white',
    boxShadow: '1px 4px 3px rgba(0,0,0,0.1)',
    textTransform: 'uppercase',
    fontSize: '0.8em',
    color: '#666',
    cursor: 'pointer',
    fontWeight: 500,
}

const noop = () => {}

const Button = ({
    children,
    style = {},
    icon = '',
    onClick = noop,
    loading = true,
    ...props
}) => {
    const iconContent = icon ? (
        <img
            src={icon}
            style={{
                width: 20,
                verticalAlign: 'middle',
                margin: '-3px 6px 0 0',
                display: 'inline-block',
            }}
        />
    ) : null
    return (
        <button
            style={{ ...buttonStyle, ...style }}
            onClick={onClick}
            {...props}>
            {iconContent}
            {children}
        </button>
    )
}

const PrimaryButton = ({ children, ...props }) => {
    return (
        <Button
            style={{ background: '#ec008c', color: 'white', border: 'none' }}
            {...props}>
            {children}
        </Button>
    )
}

export { Button, PrimaryButton }
