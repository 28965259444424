import React from 'react'
import { Stack, PrimaryButton, TextField, Text } from '@fluentui/react'
import { Navigate } from 'react-router-dom'

import { useValues } from '@/hooks/useValues'
import { useAuth } from '@/hooks/useAuth'
import { useSearchParams } from '@/hooks/useSearchParams'

import { invite } from '@/api'

const defaultLoggedInPage = '/dashboard'

const Invite = (props) => {
    const { loggedIn } = useAuth()
    const { code, user } = useSearchParams()
    const { values, setValue } = useValues({
        firstName: '',
        lastName: '',
        password: '',
    })
    const { firstName, lastName, password } = values

    if (loggedIn) {
        return <Navigate to={defaultLoggedInPage} />
    }

    const submit = (e) => {
        e.preventDefault()
        const payload = {
            code,
            user,
            values,
        }
        invite(payload).then((data) => {
            window.location = '/login'
        })
    }

    return (
        <Stack horizontalAlign='center'>
            <div style={{ width: 350 }}>
                <Text variant='xxLarge'>Invite</Text>
                <form onSubmit={submit} autoComplete='nope'>
                    <Stack tokens={{ childrenGap: 15 }}>
                        <TextField
                            name='firstName'
                            placeholder='First Name'
                            value={firstName}
                            onChange={setValue('firstName')}
                            autoComplete='given-name'
                        />
                        <TextField
                            id='lastName'
                            name='lastName'
                            placeholder='Last Name'
                            value={lastName}
                            onChange={setValue('lastName')}
                            autoComplete='family-name'
                        />
                        <TextField
                            name='password'
                            placeholder='Password'
                            value={password}
                            type='password'
                            onChange={setValue('password')}
                            autoComplete='new-password'
                        />
                        <PrimaryButton type='submit'>Login</PrimaryButton>
                    </Stack>
                </form>
            </div>
        </Stack>
    )
}

export default Invite
