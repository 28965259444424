import React, { useEffect } from 'react'
import {
    Stack,
    PrimaryButton,
    Icon,
    Text,
    IconButton,
    MessageBar,
    MessageBarType,
} from '@fluentui/react'
import { useParams } from 'react-router-dom'
import useTransaction from '../../../hooks/useTransaction'
import Template from '../Template'
import { nudgeTransaction } from '../../../api'
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons'
import { useRef } from 'react'
import { useSearchParams } from '../../../hooks/useSearchParams'
const EmailPortal = (props) => {
    console.log('Email Portal render', props)
    const { transactionId } = useParams()
    const { envelopeId } = useSearchParams()
    const {
        loading,
        // serverError,
        // transactionError,
        transaction,
        // transactionData,
        // transactionActivity,
        transactionHistory,
        // transactionFiles,
        currentStage,
        template,
        portalTemplate,
        // fields,
        // domainTemplate,
        // domainFields,
        // hasRoles,
        // comments,
        // loadData,
        // loadActivity,
        sm,
    } = useTransaction(transactionId)

    const [submitting, setSubmitting] = React.useState(false)
    const [state, setState] = React.useState('view')
    const [files, setFiles] = React.useState({})
    const [errors, setErrors] = React.useState({})
    const [filteredUploadFiles, setFilteredUploadFiles] = React.useState([])

    const onChange = React.useCallback(
        (uploadFile, file) => {
            console.log('onChange', uploadFile, file)
            setFiles((files) => {
                files[uploadFile.name] = file
                return files
            })
        },
        [setErrors, setFiles],
    )

    const onChangeAdditionalFiles = React.useCallback(
        (additionalFiles) => {
            console.log('onChangeAdditionalFiles', additionalFiles)
            setFiles((files) => {
                files['additionalFiles'] = additionalFiles
                return files
            })
        },
        [setErrors, setFiles],
    )

    const onSubmit = React.useCallback(async () => {
        // Check if we have upload files that are required
        let missingFiles = false
        const errors = {}
        filteredUploadFiles.forEach((uploadFile) => {
            if (uploadFile.required && !files[uploadFile.name]) {
                missingFiles = true
                errors[uploadFile.name] =
                    'Missing required file: ' + uploadFile.title
            }
        })

        if (missingFiles) {
            setErrors(errors)
            return
        }

        const res = await sm.getSavePayload().catch((err) => {
            console.error('Errors', err)
        })

        if (!res) return

        setSubmitting(true)

        // Process upload files
        console.log(files)
        const uploadFiles = {}
        for (const name of Object.keys(files)) {
            const file = files[name]
            if (!file) continue
            if (name === 'additionalFiles') continue
            const result = await fileToUpload(file)
            console.log(file, result)
            uploadFiles[name] = {
                content: result,
                filename: file.name,
            }
        }

        // Handle array of additional files
        const additionalFiles = []
        if (files.additionalFiles) {
            for (const file of files.additionalFiles) {
                if (!file) continue
                const result = await fileToUpload(file)
                console.log(file, result)
                additionalFiles.push({
                    content: result,
                    filename: file.name,
                })
            }
        }

        const { fieldData } = res

        const lastSave = transactionHistory.slice(-1)[0]
        const emailData = lastSave.emailData

        const savePayload = {
            domain: transaction.domain,
            transaction: transactionId,
            fieldData,
            stage: transaction.currentStage,
        }

        const payload = {
            type: 'email-submit',
            data: {
                envelopeId: emailData.envelopeId,
                savePayload,
                uploadFiles,
                additionalFiles,
            },
        }

        console.log(payload)

        await nudgeTransaction(transactionId, payload).then(() => {
            // All done
            setSubmitting(false)
            setState('done')
        })
    }, [currentStage, sm, setSubmitting])

    const update = React.useCallback(() => {
        console.log('update filteredUploadFiles', currentStage)
        if (!currentStage || !currentStage.email) return
        const uploadFiles = currentStage.email.uploadFiles || []
        const filteredUploadFiles = uploadFiles.filter((uploadFile) => {
            if (!uploadFile.when) return true
            return sm.calcWhen(uploadFile.when)
        })

        setFilteredUploadFiles(filteredUploadFiles)
    }, [currentStage, loading])

    React.useEffect(() => {
        sm.ee.on('errors', update)
        update()
        return () => sm.ee.off('errors', update)
    }, [sm, update])

    console.log('LOADING', loading)

    if (loading) {
        return (
            <Stack horizontalAlign='center'>
                <Stack>
                    <Text variant='large'>Loading...</Text>
                </Stack>
            </Stack>
        )
    }

    const lastSave = transactionHistory.slice(-1)[0]
    const emailData = lastSave.emailData

    console.log(
        'transactionHistory',
        transactionHistory,
        lastSave,
        emailData,
        envelopeId,
    )

    if (
        currentStage.kind !== 'email' ||
        !emailData ||
        lastSave.type !== 'email' ||
        envelopeId !== emailData.envelopeId
    ) {
        return (
            <Stack horizontalAlign='center'>
                <Text variant='xLarge'>Invalid link</Text>
                <Text>This url is invalid, outof date or expired.</Text>
            </Stack>
        )
    }

    if (state === 'done') {
        return (
            <Stack horizontalAlign='center'>
                <Text variant='xLarge'>Thank you.</Text>
                <Text>Your data has been submitted.</Text>
                <Text>You may close this window.</Text>
            </Stack>
        )
    }

    const templateContents = template ? template.content.contents : null

    if (templateContents) {
        console.log('templateContents', templateContents)
        const senderName =
            transaction.createdBy.firstName +
            ' ' +
            transaction.createdBy.lastName
        const senderEmail = transaction.createdBy.email
        templateContents[0].content = templateContents[0].content
            .replace(/\$senderName/g, senderName)
            .replace(/\$senderEmail/g, senderEmail)
    }

    return (
        <Stack horizontalAlign='center'>
            <Stack style={{ width: '50%' }} tokens={{ childrenGap: 20 }}>
                {/* <div>
                    <h1>Email Template</h1>
                    {template ? (
                        <Template contents={templateContents} readonly={true} />
                    ) : null}
                </div> */}

                {portalTemplate ? (
                    <div>
                        <Template
                            contents={
                                portalTemplate
                                    ? portalTemplate.content.contents
                                    : null
                            }
                        />
                    </div>
                ) : null}

                {/* Upload Files */}
                {filteredUploadFiles.map((uploadFile, i) => {
                    return (
                        <UploadFile
                            key={i}
                            uploadFile={uploadFile}
                            onChange={onChange.bind(null, uploadFile)}
                        />
                    )
                })}

                {/* Additional Files */}
                <UploadFileAdditional
                    filename={'Additional Files'}
                    // extension={extension}
                    // required={uploadFile.required}
                    // accept={uploadFile.accept}
                    onChange={onChangeAdditionalFiles}
                />

                {/* {portalTemplate ? (
                    <div>
                        <h1>Portal Template</h1>
                        <Template
                            contents={
                                portalTemplate
                                    ? portalTemplate.content.contents
                                    : null
                            }
                        />
                    </div>
                ) : null} */}
                {errors ? (
                    <Stack>
                        {Object.keys(errors).map((name, key) => {
                            return (
                                <MessageBar
                                    key={key}
                                    messageBarType={MessageBarType.error}>
                                    {errors[name]}
                                </MessageBar>
                            )
                        })}
                    </Stack>
                ) : null}
                <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
            </Stack>
        </Stack>
    )
}

const documentStyle = {
    border: '2px solid lightgrey',
    padding: '10px 20px 10px 20px',
    marginBottom: 5,
    borderRadius: 5,
}

const mapToIcon = ({ accept, icon }) => {
    if (icon) return icon
    if (accept.match('image/')) return 'png'
    if (accept.match('text/')) return 'txt'
    if (accept.match('application/pdf')) return 'pdf'
    return ''
}

const UploadFile = ({ uploadFile, onChange }) => {
    console.log('uploadFile', uploadFile)

    const extension = mapToIcon(uploadFile)
    return (
        <Stack style={documentStyle}>
            <File
                filename={uploadFile.title}
                extension={extension}
                required={uploadFile.required}
                accept={uploadFile.accept}
                onChange={onChange}
            />
        </Stack>
    )
}

const UploadFileAdditional = ({ onChange }) => {
    console.log('uploadFile additional')

    // const extension = mapToIcon(uploadFile)
    return (
        <Stack style={documentStyle}>
            <File filename={'Additional Files'} onChange={onChange} multiple />
        </Stack>
    )
}

const File = ({
    filename,
    extension = 'txt',
    required = false,
    accept = undefined,
    onChange = () => {},
    multiple = false,
}) => {
    const ref = useRef(null)
    const [file, setFile] = React.useState(null)
    const [files, setFiles] = React.useState([])

    const onClickUpload = () => {
        ref.current.click()
    }

    const onFileChange = (e) => {
        // @TODO: multi file uploads
        if (!multiple) {
            setFile(e.target.files[0])
        } else {
            setFiles(Array.from(e.target.files))
        }
    }

    const onClickRemove = () => {
        setFile(null)
        ref.current.form.reset()
    }

    const onClickRemoveMultiple = (i) => {
        console.log('onClickRemoveMultiple', i)
        const newFiles = [...files]
        newFiles.splice(i, 1)
        setFiles(newFiles)
    }

    useEffect(() => {
        if (!multiple) {
            onChange(file)
        } else {
            onChange(files)
        }
    }, [onChange, file, files])

    console.log('yoyo', file, files)

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack
                horizontal
                verticalAlign='center'
                tokens={{ childrenGap: 10 }}>
                <Icon
                    {...getFileTypeIconProps({
                        extension,
                        size: 24,
                        imageFileType: 'png',
                    })}
                />
                <Stack style={{ flexGrow: 1 }}>
                    <Text>{filename}</Text>
                    {required ? (
                        <Text style={{ color: 'red' }}>Required</Text>
                    ) : null}
                </Stack>
                <form>
                    <input
                        ref={ref}
                        type='file'
                        style={{ display: 'none' }}
                        accept={accept}
                        onChange={onFileChange}
                        multiple={multiple}
                    />
                </form>
                <PrimaryButton onClick={onClickUpload}>Upload</PrimaryButton>
            </Stack>
            {file ? (
                <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    verticalAlign='center'>
                    <Icon
                        {...getFileTypeIconProps({
                            extension,
                            size: 24,
                            imageFileType: 'png',
                        })}
                    />
                    <Text>{file.name}</Text>
                    <IconButton
                        iconProps={{ iconName: 'Trash' }}
                        onClick={onClickRemove}
                    />
                </Stack>
            ) : null}
            {files.map((file, i) => {
                return (
                    <Stack
                        key={i}
                        horizontal
                        tokens={{ childrenGap: 10 }}
                        verticalAlign='center'>
                        <Icon
                            {...getFileTypeIconProps({
                                extension,
                                size: 24,
                                imageFileType: 'png',
                            })}
                        />
                        <Text>{file.name}</Text>
                        <IconButton
                            iconProps={{ iconName: 'Trash' }}
                            onClick={onClickRemoveMultiple.bind(null, i)}
                        />
                    </Stack>
                )
            })}
        </Stack>
    )
}

const fileToUpload = (file) => {
    // @TODO: Error states
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            resolve(reader.result)
        })
        reader.readAsDataURL(file)
    })
}

export default EmailPortal
