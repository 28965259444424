export default {
    palette: {
        themePrimary: '#ec008c',
        themeLighterAlt: '#fef4fa',
        themeLighter: '#fcd4ec',
        themeLight: '#faafdc',
        themeTertiary: '#f462ba',
        themeSecondary: '#ef1d9b',
        themeDarkAlt: '#d50080',
        themeDark: '#b4006c',
        themeDarker: '#850050',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#bab8b7',
        neutralSecondary: '#a3a2a0',
        neutralPrimaryAlt: '#8d8b8a',
        neutralPrimary: '#323130',
        neutralDark: '#605e5d',
        black: '#494847',
        white: '#ffffff',
    },
}
