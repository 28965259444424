import React, { useCallback, useEffect } from 'react'
import Page from '../components/layout/Page'
import { Stack, CommandBar } from '@fluentui/react'
import useCtrlS from '../hooks/useCtrlS'
import useUnload from '../hooks/useUnload'
// import { useBeforeUnload } from 'react-router-dom'

const Test = (props) => {
    useCtrlS(() => {
        alert('hello2')
    })

    useUnload(() => {
        alert('hello')
    })

    return (
        <Page>
            <Stack>
                <h1>Hello</h1>
                <input />
            </Stack>
        </Page>
    )
}

export default Test
