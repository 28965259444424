import React from 'react'
import {
    Text,
    DetailsList,
    SelectionMode,
    Persona,
    PersonaSize,
} from '@fluentui/react'
import { getUserGroups } from '../../api'
import { useAuth } from '../../hooks/useAuth'

export const UserGroupsSection = () => {
    const [permissions, setPermissions] = React.useState([])
    const { userId, currentAccount } = useAuth()

    React.useEffect(() => {
        getUserGroups(userId).then(setPermissions)
    }, [setPermissions])

    const columns = [
        {
            key: 'icon',
            name: '',
            onRender(col) {
                return (
                    <Persona
                        size={PersonaSize.size32}
                        text={col.name}
                        hidePersonaDetails={true}
                    />
                )
            },
            minWidth: 50,
            maxWidth: 50,
        },
        {
            key: 'name',
            name: 'Group Name',
            fieldName: 'name',
            minWidth: 300,
        },
    ]

    return (
        <>
            <Text variant='xxLarge'>
                User Groups for {currentAccount.account.name}
            </Text>
            <DetailsList
                items={permissions}
                columns={columns}
                selectionMode={SelectionMode.none}
            />
        </>
    )
}
