// Leasle SubTitle v1.0 implements headings and sub-headings on pages

import React from 'react'
import PropTypes from 'prop-types'
import { Separator, createTheme, Text } from '@fluentui/react'

const theme = createTheme({
    fonts: {
        medium: {
            fontSize: 'large',
        },
    },
    semanticColors: {
        bodyText: '#ec008c',
    },
})

const SubTitle = ({ title, subtitle }) => {
    if (title) {
        return (
            <Text variant={'large'} nowrap>
                {title}
            </Text>
        )
    }
    if (subtitle) {
        return (
            <Separator alignContent='start' theme={theme}>
                {subtitle}
            </Separator>
        )
    }
    return ''
}

SubTitle.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
}

export default SubTitle
