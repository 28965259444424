import dayjs from 'dayjs'

const formatDate = (date) => dayjs(date).format('LL')
const formatTime = (date) => dayjs(date).format('LT')

const formatDateTime = (date) => {
    return `${formatDate(date)} at ${formatTime(date)}`
}

const formatShortDateTime = (date) => dayjs(date).format('lll')

export { formatDateTime, formatDate, formatTime, formatShortDateTime }
