import React from 'react'

import {
    IconButton,
    Link,
    getTheme,
    FontWeights,
    mergeStyleSets,
    Callout,
} from '@fluentui/react'

const theme = getTheme()
const styles = mergeStyleSets({
    callout: {
        width: 400,
    },
    actionbuttons: {
        float: 'right',
        padding: '12px 18px 0 0',
    },
    header: {
        padding: '18px 24px 12px',
    },
    title: [
        theme.fonts.xLarge,
        {
            margin: 0,
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semilight,
        },
    ],
    reftext: [
        theme.fonts.xSmall,
        {
            margin: 0,
            float: 'right',
            color: theme.palette.themePrimary,
            fontWeight: FontWeights.semilight,
        },
    ],
    actions: {
        position: 'relative',
        marginTop: 15,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.themePrimary,
        },
    ],
})

const HelpPanel = ({
    labelId,
    descriptionId,
    onDismiss,
    targetId,
    schema,
    fieldId,
}) => {
    const { help, helpUrl, title } = schema

    return (
        <Callout
            className={styles.callout}
            ariaLabelledBy={labelId}
            ariaDescribedBy={descriptionId}
            role='alertdialog'
            gapSpace={0}
            target={`#${targetId}`}
            onDismiss={onDismiss}
            setInitialFocus={true}>
            <div className={styles.actionbuttons}>
                <IconButton
                    onClick={onDismiss}
                    title='Close'
                    iconProps={{ iconName: 'Cancel' }}
                />
            </div>
            <div className={styles.header}>
                <p className={styles.title} id={labelId}>
                    {title}
                </p>
            </div>
            <div className={styles.inner}>
                <p className={styles.subtext} id={descriptionId}>
                    {help}
                </p>
                <div className={styles.actions}>
                    {helpUrl && (
                        <Link
                            className={styles.link}
                            href={helpUrl}
                            target='_blank'>
                            More info
                        </Link>
                    )}
                </div>
                <p className={styles.reftext} id={fieldId}>
                    {schema.name}
                </p>
            </div>
        </Callout>
    )
}

export default HelpPanel
