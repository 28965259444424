import React from 'react'
import { Text, Toggle, Stack } from '@fluentui/react'
import { useSettings } from '../../hooks/useSettingsNew'
import { useSetting } from '../../hooks/useSetting'

export const SettingsSection = () => {
    const { getSetting, setSetting } = useSettings()
    const direction = getSetting('rtl', false)
    const showFieldDetails = getSetting('showFieldDetails', false)
    const showFieldCalc = getSetting('showFieldCalc', false)
    const showFieldHelp = getSetting('showFieldHelp', true)
    // const [direction, setDirection] = React.useState()

    const onToggleDirection = (event, checked) => {
        // setDirection((direction) => {
        //     const newDirection = !direction
        //     document.querySelector('html').dir = newDirection ? 'rtl' : 'ltr'
        //     return newDirection
        // })
    }

    console.log('RENDERING', direction)

    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <Text variant='xxLarge'>Settings</Text>
            <Stack>
                <Text variant='xLarge'>General</Text>
                <Toggle
                    onChange={(event, checked) => {
                        setSetting('showFieldDetails', checked)
                    }}
                    checked={showFieldDetails}
                    label='Show Field Details'
                />
                <Toggle
                    onChange={(event, checked) => {
                        setSetting('showFieldCalc', checked)
                    }}
                    checked={showFieldCalc}
                    label='Show Field Calc'
                />
                <Toggle
                    onChange={(event, checked) => {
                        setSetting('showFieldHelp', checked)
                    }}
                    checked={showFieldHelp}
                    label='Show Field Help'
                />
            </Stack>
            <Stack>
                <Text variant='xLarge'>Internationalisation</Text>
                <Toggle
                    onChange={(event, checked) => {
                        setSetting('rtl', checked)
                    }}
                    checked={direction}
                    label='LTR / RTL'
                />
            </Stack>
        </Stack>
    )
}
