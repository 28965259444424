import React from 'react'
const SettingsContext = React.createContext(null)

class Settings {
    foo() {
        console.log('Settings foo')
    }
}

const LEASLE_SETTINGS_KEY = 'leasle-settings'

const _loadSettings = () => {
    const value = localStorage.getItem(LEASLE_SETTINGS_KEY)
    if (!value) {
        return {}
    }
    return JSON.parse(value)
}

const _saveSettings = (settings) => {
    const existingSettings = _loadSettings()
    const updatedSettings = { ...existingSettings, ...settings }
    // console.log(updatedSettings, settings, existingSettings)
    localStorage.setItem(LEASLE_SETTINGS_KEY, JSON.stringify(updatedSettings))
}

function SettingsProvider(props) {
    const [settings, setSettings] = React.useState(_loadSettings())

    const saveSettings = React.useCallback(() => {
        setSettings((settings) => {
            _saveSettings(settings)
            return settings
        })
    }, [settings, setSettings])

    const getSetting = (setting, _default = null) => {
        // console.log('getSetting', setting, _default, settings[setting])
        return setting in settings ? settings[setting] : _default
    }

    const setSetting = React.useCallback(
        (setting, value) => {
            // console.log('setSetting', setting, value)
            setSettings((settings) => {
                const newSettings = { ...settings, [setting]: value }
                return newSettings
            })
            // @TODO: save setting to local storage
            saveSettings()
        },
        [setSettings],
    )

    return (
        <SettingsContext.Provider
            {...props}
            value={{ getSetting, setSetting }}
        />
    )
}

const useSettings = () => {
    return React.useContext(SettingsContext)
}

export { Settings, SettingsProvider, useSettings }
