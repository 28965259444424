import React from 'react'
import { getTransactionHistory } from '../api'
import {
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    Stack,
    CommandBarButton,
    Text,
} from '@fluentui/react'
import { useParams } from 'react-router-dom'

import LoadingSpinner from '../components/LoadingSpinner'
import Page from '../components/layout/Page'

const buttonStackStyles = { root: { height: 44 } }

const columns = [
    {
        key: 'column1',
        name: 'Version',
        fieldName: 'version',
        minWidth: 50,
        maxWidth: 80,
        isResizable: true,
    },
    {
        key: 'column2',
        name: 'Created',
        fieldName: 'created',
        minWidth: 80,
        maxWidth: 120,
        isResizable: true,
    },
]

const TransactionHistory = (props) => {
    const params = useParams()

    const [selectionDetails, setSelectionDetails] = React.useState('')
    const [selection] = React.useState(
        new Selection({
            onSelectionChanged: () =>
                setSelectionDetails(getSelectionDetails()),
        }),
    )

    const [historyItems, setHistoryItems] = React.useState([])
    const [historyLoading, setHistoryLoading] = React.useState(true)

    const transID = params.transactionId

    const updateHistoryItems = (historyRecords) => {
        const newHistoryItems = historyRecords.map((historyRecord, i) => ({
            key: i,
            id: historyRecord._id,
            created: historyRecord.created,
            version: i + 1,
        }))
        setHistoryLoading(false)
        setHistoryItems(newHistoryItems)
    }

    React.useEffect(() => {
        setHistoryLoading(true)
        // Get Transaction History records from cosmos
        getTransactionHistory(transID).then(updateHistoryItems)
    }, [])

    const getSelectionDetails = () => {
        const selectionCount = selection.getSelectedCount()
        switch (selectionCount) {
            case 0:
                return 'No items selected'
            case 1:
                return `1 item selected: (version ${
                    selection.getSelection()[0].version
                })`
            default: {
                let selectedVers = '(versions '
                for (let j = 0; j < selection.getSelection().length; j += 1) {
                    selectedVers += selection.getSelection()[j].version
                    if (j < selection.getSelection().length - 1) {
                        selectedVers += ', '
                    } else {
                        selectedVers += ')'
                    }
                }
                return `${selectionCount} items selected: ${selectedVers}`
            }
        }
    }

    const handleShowCompare = () => {
        if (selection.getSelection().length < 2) {
            return
        }

        const selectedVers = selection
            .getSelection()
            .map((a) => a.id)
            .join(',')

        const showCompare = `/compare/${transID}?versions=${selectedVers}`
        window.open(showCompare)
    }

    const closeWindow = () => {
        return window.close()
    }

    if (historyLoading) {
        return (
            <Page>
                <LoadingSpinner />
            </Page>
        )
    }

    return (
        <Page>
            <Text variant='xxLarge'>Transaction: {transID}</Text>
            <Stack horizontal styles={buttonStackStyles}>
                <CommandBarButton
                    iconProps={{ iconName: 'BranchCompare' }}
                    disabled={selection.getSelectedCount() < 2}
                    onClick={handleShowCompare}>
                    Compare Versions
                </CommandBarButton>
                <CommandBarButton
                    iconProps={{ iconName: 'NavigateBack' }}
                    onClick={closeWindow}>
                    Close
                </CommandBarButton>
            </Stack>
            <p>{selectionDetails}</p>
            <DetailsList
                items={historyItems}
                columns={columns}
                setKey='set'
                layoutMode={DetailsListLayoutMode.justified}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn='Toggle selection'
                ariaLabelForSelectAllCheckbox='Toggle selection for all items'
                checkButtonAriaLabel='Row checkbox'
            />
        </Page>
    )
}

export default TransactionHistory
