import React from 'react'

import {
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    MessageBar,
} from '@fluentui/react'

const dialogContentProps = {
    type: DialogType.normal,
    title: 'Confirm recall',
    closeButtonAriaLabel: 'Close',
    subText:
        'The transaction will be recalled to the transaction creator, are you sure you want to continue?',
}

const RoleRecallDialog = ({
    open = false,
    setOpenFalse = () => {},
    onConfirm = () => {},
}) => {
    return (
        <Dialog
            hidden={!open}
            onDismiss={setOpenFalse}
            dialogContentProps={dialogContentProps}>
            <DialogFooter>
                <PrimaryButton onClick={onConfirm} text='Confirm' />
                <DefaultButton onClick={setOpenFalse} text='Cancel' />
            </DialogFooter>
        </Dialog>
    )
}

export default RoleRecallDialog
