export default {
    render: () => {
        return `<!-- COMMENT -->`
    },
    background: (stage, render, { stepY }) => {
        const { x, y, halfHeight, final = false } = stage
        const yStart = y - halfHeight * stepY
        const yEnd = y + halfHeight * stepY
        const lineBefore = !stage.first
            ? `<line x1="${x}" x2="${x}" y1="${y}" y2="${yStart}" />`
            : ''
        const lineAfter = !stage.last
            ? `<line x1="${x}" x2="${x}" y1="${y}" y2="${yEnd}" />`
            : ''
        return [lineBefore, lineAfter].join('\n')
    },
    size: (stage) => {
        stage.height = 1
        stage.width = 1
        stage.halfHeight = stage.height / 2
    },
}
