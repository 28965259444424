import React from 'react'
// import { Stack, PrimaryButton, TextField } from '@fluentui/react'
import { Text } from '@fluentui/react'

import FullPage from '@/components/layout/FullPage'
import { Wrap } from './auth/Utils'

const NotFoundPanel = () => {
    return (
        <>
            <Wrap>
                <Text variant='superLarge'>404! - Not found :(</Text>
            </Wrap>
        </>
    )
}

const NotFound = (props) => {
    return (
        <FullPage background='url("https://source.unsplash.com/1600x900/?building")'>
            <NotFoundPanel />
        </FullPage>
    )
}

export default NotFound
