import React from 'react'

import {
    IconButton,
    Link,
    getTheme,
    FontWeights,
    mergeStyleSets,
    Callout,
    Stack,
    Text,
} from '@fluentui/react'
import { useStateMachine } from '../../../hooks/useStateMachine'

const theme = getTheme()
const styles = mergeStyleSets({
    callout: {
        width: 400,
    },
    actionbuttons: {
        float: 'right',
        padding: '12px 18px 0 0',
    },
    header: {
        padding: '18px 24px 12px',
    },
    title: [
        theme.fonts.xLarge,
        {
            margin: 0,
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semilight,
        },
    ],
    reftext: [
        theme.fonts.xSmall,
        {
            margin: 0,
            float: 'right',
            color: theme.palette.themePrimary,
            fontWeight: FontWeights.semilight,
        },
    ],
    actions: {
        position: 'relative',
        marginTop: 15,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.themePrimary,
        },
    ],
})

const CalcPanel = ({
    labelId,
    descriptionId,
    onDismiss,
    targetId,
    schema,
    fieldId,
}) => {
    const { help, helpUrl, title, calc } = schema

    const sm = useStateMachine()
    const calcHtml = formatCalcToHtml(calc, sm)

    const style = {
        background: 'papayawhip',
        padding: 10,
        borderRadius: 4,
        border: '1px solid orange',
    }

    return (
        <Callout
            className={styles.callout}
            ariaLabelledBy={labelId}
            ariaDescribedBy={descriptionId}
            role='alertdialog'
            gapSpace={0}
            target={`#${targetId}`}
            onDismiss={onDismiss}
            setInitialFocus={true}>
            <Stack tokens={{ childrenGap: 10 }} style={{ padding: 20 }}>
                <Text variant='large'>Calculation:</Text>
                <div
                    dangerouslySetInnerHTML={{ __html: calcHtml }}
                    style={style}></div>
            </Stack>
        </Callout>
    )
}

const formatCalcToHtml = (calc, sm) => {
    return calc.replace(/\$([a-z0-9]+)/gi, function (match, name) {
        return `<b title="${sm.getFieldDef(name).title}">${match}</b>`
    })
}

export default CalcPanel
