import React from 'react'
import {
    CommandBar,
    Text,
    TextField,
    Stack,
    MessageBar,
    MessageBarType,
    DefaultEffects,
    DefaultSpacing,
} from '@fluentui/react'
import { useAuth } from '../../hooks/useAuth'

export const PasswordSection = ({ onClickSave }) => {
    const { email } = useAuth()

    const [dirty, setDirty] = React.useState(false)

    const [touched, setTouched] = React.useState({})
    const [state, setState] = React.useState({})
    const [errors, setErrors] = React.useState({})

    const { currentPassword = '', password = '', password2 = '' } = state

    const onChange = React.useCallback(
        (e) => {
            const { name, value } = e.target
            setTouched((touched) => ({ ...touched, [name]: true }))
            setState((state) => ({ ...state, [name]: value }))
            setDirty(true)
        },
        [setState, setDirty, setTouched],
    )

    React.useMemo(() => {
        if (state.password !== state.password2) {
            setErrors({
                password2: 'Passwords do not match',
            })
        } else {
            setErrors({})
        }
    }, [state])

    const hasErrors = Object.keys(errors).length > 0
    const commands = [
        {
            key: 'save',
            text: 'Save',
            iconProps: { iconName: 'Save' },
            disabled: !touched || !dirty || hasErrors,
            onClick: () => {
                const payload = { ...state }
                delete payload.password2
                onClickSave(payload).catch((err) => {
                    console.log('ERROR', err)
                    setErrors({ server: err.response.data.error })
                })
            },
        },
    ]

    return (
        <Stack>
            <Text variant='xxLarge'>Change Password</Text>
            <CommandBar items={commands} styles={{ root: { padding: 0 } }} />
            <form>
                {errors.server ? (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {errors.server}
                    </MessageBar>
                ) : null}
                <input
                    name='email'
                    value={email}
                    readOnly
                    type='email'
                    autoComplete='username'
                    style={{ display: 'none' }}
                />
                <TextField
                    name='currentPassword'
                    type='password'
                    label='Current Password'
                    autoComplete='current-password'
                    required
                    value={currentPassword}
                    onChange={onChange}
                    styles={{ root: { width: 300 } }}
                />
                <TextField
                    name='password'
                    type='password'
                    label='New Password'
                    autoComplete='new-password'
                    required
                    value={password}
                    onChange={onChange}
                    styles={{ root: { width: 300 } }}
                />
                <TextField
                    name='password2'
                    type='password'
                    label='Confirm New Password'
                    autoComplete='new-password-2'
                    required
                    value={password2}
                    onChange={onChange}
                    styles={{ root: { width: 300 } }}
                    errorMessage={touched.password2 ? errors.password2 : null}
                />
            </form>
        </Stack>
    )
}
