import React from 'react'

import TextInput from './TextInput'
import LongTextInput from './LongTextInput'
import NumberInput from './NumberInput'
import DateInput from './DateInput'
import ListInput from './ListInput'
import SectionInput from './SectionInput'

function DefaultInput({ schema = { name: 'UNKNOWN' } }) {
    return <p>Field : {schema.name}</p>
}

const elementMapping = {
    TEXT: TextInput,
    LONGTEXT: LongTextInput,
    LONG_TEXT: LongTextInput,
    NUMBER: NumberInput,
    DATE: DateInput,
    LIST: ListInput,
    SECTION: SectionInput,
    DEFAULT: DefaultInput,
}

const FieldInput = (props) => {
    const { schema = {} } = props
    const Element = elementMapping[schema.type || 'DEFAULT']
    return <Element {...props} />
}

export default FieldInput
