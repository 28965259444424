import React, { useState, useCallback, useEffect } from 'react'
import { useStateMachine } from '../../hooks/useStateMachine.js'
import FieldHelper from './FieldHelper'

const toggleValueArray = (arr, value) => {
    const arr2 = Array.from(arr)
    if (arr2.includes(value)) {
        arr2.splice(arr2.indexOf(value), 1)
    } else {
        arr2.push(value)
    }
    return arr2
}

const errors = (name, simpleErrors = false) => {
    const sm = useStateMachine()
    const errors = sm.getErrors(name)
    if (!errors.length) return null

    if (simpleErrors) {
        return errors[0]
    }

    return (
        <div>
            {errors.map((error, i) => (
                <p style={{ margin: 0 }} key={i}>
                    {error}
                </p>
            ))}
        </div>
    )
}

const registerSingle = (name, formatter = null, simpleErrors = false) => {
    const sm = useStateMachine()
    const def = sm.getFieldDef(name)
    const [value, setValue] = useState(sm.getFieldValue(name))
    const [localValue, setLocalValue] = React.useState(
        formatter ? formatter(value) : value,
    )

    const update = useCallback(
        ([value]) => {
            setValue(value)
            setLocalValue(formatter ? formatter(value) : value)
        },
        [name, setValue, setLocalValue, formatter],
    )
    useEffect(() => {
        sm.register(name, update)
        return () => {
            sm.unregister(name, update)
        }
    }, [sm, update])

    const onChange = useCallback(
        (e, value) => {
            setValue(value)
            sm.setFieldValue(name, [value])
        },
        [sm],
    )
    const errorMessage = errors(name, simpleErrors)

    return { value, localValue, setLocalValue, onChange, errorMessage, def }
}

const registerMulti = (name, formatter = null, simpleErrors = false) => {
    const sm = useStateMachine()
    const [values, setValues] = useState(sm.getFieldValues(name))

    const update = useCallback((values) => setValues(values), [setValues])
    useEffect(() => {
        sm.register(name, update)
        return () => sm.unregister(name, update)
    }, [sm, update])

    const onChange = (e, value) => {
        const newValues = toggleValueArray(values, value)
        sm.setFieldValue(name, newValues)
    }
    const errorMessage = errors(name, simpleErrors)
    return { values, onChange, errorMessage }
}

const registerMulti2 = (name, formatter = null, simpleErrors = false) => {
    const sm = useStateMachine()
    const def = sm.getFieldDef(name)
    const [values, setValues] = useState(sm.getFieldValues(name))

    const update = useCallback((values) => setValues(values), [setValues])
    useEffect(() => {
        sm.register(name, update)
        return () => sm.unregister(name, update)
    }, [sm, update])

    const onChange = (e, index, value) => {
        const newValues = [...values]
        newValues[index] = value
        sm.setFieldValue(name, newValues)
    }

    const onDeleteItem = (index) => {
        const newValues = [...values]
        newValues.splice(index, 1)
        sm.setFieldValue(name, newValues)
    }
    const errorMessage = errors(name, simpleErrors)
    return { value: values, onChange, onDeleteItem, errorMessage, def }
}

const makeLabel = (schema) =>
    function Label(props, defaultRender) {
        return (
            <FieldHelper
                props={props}
                schema={schema}
                defaultRender={defaultRender}
            />
        )
    }

export { registerSingle, registerMulti, makeLabel, registerMulti2 }
