import React from 'react'
import { Stack, Text, Icon } from '@fluentui/react'

const containerStyle = {
    padding: '1em 3em',
}
const showMoreStyle = {
    textDecoration: 'underline',
    cursor: 'pointer',
}

const backtraceStyle = {
    background: '#eee',
    padding: '5px 10px',
    borderRadius: 8,
    border: '1px solid #ccc',
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.log('ERROR', error)
        return { hasError: true, error }
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log('ERROR', error, errorInfo)
    }

    render() {
        console.log('EB', this.state)
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Stack style={containerStyle}>
                    <h1>
                        <Icon
                            iconName='AlertSolid'
                            style={{
                                verticalAlign: 'bottom',
                                fontSize: 40,
                                color: 'red',
                            }}
                        />{' '}
                        Something went wrong.
                    </h1>
                    <ErrorComponent error={this.state.error} />
                </Stack>
            )
        }

        return this.props.children
    }
}

const iconStyle = {
    marginLeft: 10,
    cursor: 'pointer',
}

const ErrorComponent = ({ error }) => {
    const [showMore, setShowMore] = React.useState(false)
    const onClick = () => {
        setShowMore(!showMore)
    }
    const onCopy = async () => {
        const errorMessageText = [
            'Date=' + new Date(),
            'Location=' + window.location.href,
            'Error=' + error.message,
            'Backtrace=' + error.stack,
        ].join('\n')

        await navigator.clipboard.writeText(errorMessageText)
        alert('Error copied to clipboard')
    }
    return (
        <Stack>
            <Text variant='xxLarge'>
                Error: {error.message}
                <Icon
                    iconName='ClipboardList'
                    style={iconStyle}
                    onClick={onCopy}
                    title='Copy to Clipboard'
                />
            </Text>
            <Text style={showMoreStyle} onClick={onClick}>
                {showMore ? 'Show less' : 'Show more'}
            </Text>
            {showMore ? <pre style={backtraceStyle}>{error.stack}</pre> : null}
        </Stack>
    )
}

export default ErrorBoundary
