import React from 'react'
import { Stack } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'

import { useSearchParams } from '@/hooks/useSearchParams'
import { useAuth } from '@/hooks/useAuth'

const LoginAuth = (props) => {
    const { auth } = useSearchParams()
    const navigate = useNavigate()
    const { loginSSO } = useAuth()

    const data = JSON.parse(atob(decodeURIComponent(auth)))

    React.useEffect(() => {
        loginSSO(data)
        navigate('/dashboard')
    }, [])

    return (
        <Stack horizontalAlign='center'>
            <div style={{ width: 350 }}></div>
            <code>{JSON.stringify(data, null, 4)}</code>
        </Stack>
    )
}

export default LoginAuth
