import React from 'react'
import {
    CommandBar,
    DetailsList,
    Selection,
    SelectionMode,
    Text,
} from '@fluentui/react'

import Page from '../../components/layout/Page'
import { useParams } from 'react-router-dom'
import { getAccount, getUsersAccount } from '../../api'
import LoadingSpinner from '../../components/LoadingSpinner'
import AddUserModal from './Account/AddUserModal'
import AssignRoleModal from './Account/AssignRoleModal'
import EditRolesModal from './Account/EditRolesModal'

import md5 from './Account/md5.js'

const Account = (props) => {
    const params = useParams()
    const [account, setAccount] = React.useState({})
    const [users, setUsers] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [selected, setSelected] = React.useState()
    const selection = new Selection({
        onSelectionChanged: () => {
            setSelected(selection.getSelection()[0])
        },
    })

    const [addUserOpen, setAddUserOpen] = React.useState(false)
    const [assignRoleOpen, setAssignRoleOpen] = React.useState(false)
    const [editRolesOpen, setEditRolesOpen] = React.useState(false)

    const loadData = React.useCallback(() => {
        setLoading(true)
        Promise.all([
            getAccount(params.accountId),
            getUsersAccount(params.accountId),
        ]).then(([account, users]) => {
            setUsers(users)
            setAccount(account)
            setLoading(false)
        })
    }, [setAccount, setLoading])

    React.useEffect(() => {
        loadData()
    }, [loadData])

    if (loading) {
        return (
            <Page>
                <LoadingSpinner />
            </Page>
        )
    }

    const columns = [
        {
            key: 'id',
            name: 'ID',
            fieldName: '_id',
            maxWidth: 300,
        },
        {
            key: 'firstName',
            name: 'First Name',
            fieldName: 'firstName',
            maxWidth: 300,
        },
        {
            key: 'lastName',
            name: 'Last Name',
            fieldName: 'lastName',
            maxWidth: 300,
        },
        {
            key: 'email',
            name: 'Email',
            fieldName: 'email',
            maxWidth: 300,
        },
        {
            key: 'created',
            name: 'Created',
            fieldName: 'created',
            maxWidth: 300,
        },
        {
            key: 'lastLogin',
            name: 'Last Login',
            fieldName: 'lastLogin',
            maxWidth: 300,
        },
        {
            key: 'roles',
            name: 'Roles',
            onRender: (item) => {
                return item.accountGroups[0].roles.map((role, i) => {
                    return <Role key={i} role={role} />
                })
            },
        },
    ]

    const commands = [
        {
            key: 'addUser',
            text: 'Add User',
            iconProps: { iconName: 'Add' },
            onClick: () => setAddUserOpen(true),
        },
        {
            key: 'assignRole',
            text: 'Assign Role',
            iconProps: { iconName: 'Contact' },
            onClick: () => setAssignRoleOpen(true),
            disabled: !selected,
        },
        {
            key: 'editRoles',
            text: 'Edit Roles',
            iconProps: { iconName: 'Edit' },
            onClick: () => setEditRolesOpen(true),
            disabled: !selected,
        },
    ]

    return (
        <Page>
            <CommandBar items={commands} />
            <Text variant='xxLarge'>{account.name}</Text>
            <DetailsList
                items={users}
                columns={columns}
                selection={selection}
                selectionMode={SelectionMode.single}
            />
            <AddUserModal
                accountId={account?._id}
                isOpen={addUserOpen}
                onClose={() => setAddUserOpen(false)}
                loadData={loadData}
            />
            <AssignRoleModal
                accountId={account?._id}
                user={selected}
                isOpen={assignRoleOpen}
                onClose={() => setAssignRoleOpen(false)}
                loadData={loadData}
            />
            <EditRolesModal
                accountId={account?._id}
                user={selected}
                isOpen={editRolesOpen}
                onClose={() => setEditRolesOpen(false)}
                loadData={loadData}
            />
            <pre>{JSON.stringify(account, null, 4)}</pre>
            <pre>{JSON.stringify(users, null, 4)}</pre>
        </Page>
    )
}

const roleStyle = {
    background: '#ccc',
    color: 'black',
    display: 'inline-block',
    padding: '5px 10px',
    borderRadius: 8,
    verticalAlign: 'middle',
    marginRight: 5,
    textTransform: 'capitalize',
}

const getRoleColor = (role) => {
    const hash = parseInt(md5(role.repeat(10)).slice(0, 2), 16)
    const hue = (hash / 255) * 360
    return `hsl(${hue} 100% 50%)`
}

const Role = ({ role = null }) => {
    if (!role) return null
    const roleColor = getRoleColor(role)
    console.log(roleColor)
    return <span style={{ ...roleStyle, background: roleColor }}>{role}</span>
}

export default Account
