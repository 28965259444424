import React from 'react'

import { Text, CommandBar, Stack, Toggle, Selection } from '@fluentui/react'

import { useNavigate } from 'react-router-dom'

import Page from '../components/layout/Page'
import TransactionList from '../components/TransactionList'

import { useSearchParams } from '../hooks/useSearchParams'
import Filters from '../components/template/Filters'

const TransactionListView = () => {
    const navigate = useNavigate()
    const { entity = null, entityType = null } = useSearchParams()
    const [showCompleted, setShowCompleted] = React.useState(true)
    const [search, setSearch] = React.useState(null)
    const [selectedItems, setSelectedItems] = React.useState(null)
    const [selection] = React.useState(
        new Selection({
            onSelectionChanged: (...args) => {
                setSelectedItems(selection.getSelection())
            },
        }),
    )

    const handleShowCompleted = React.useCallback(() => {
        setShowCompleted((showCompleted) => !showCompleted)
    }, [setShowCompleted])

    const onSearch = React.useCallback(
        (value) => {
            setSearch(value)
        },
        [setSearch],
    )

    const hasSelection = !!(selectedItems && selectedItems.length)
    const selectedItem = hasSelection ? selectedItems[0] : null

    const actions = [
        {
            key: 'create',
            text: 'Create Transaction',
            onClick: () => {
                navigate('/createtransaction')
            },
            iconProps: { iconName: 'Add' },
        },
        {
            key: 'view',
            text: 'View Transaction',
            onClick: () => {
                navigate(`/transactions/${selectedItem._id}`)
            },
            iconProps: { iconName: 'PreviewLink' },
            disabled: !hasSelection,
        },
        // Disable delete for now
        // {
        //     key: 'delete',
        //     text: 'Delete',
        //     onClick: () => {
        //         alert('unimplemented')
        //     },
        //     iconProps: { iconName: 'Delete' },
        //     disabled: !hasSelection,
        // },
    ].filter((a) => !!a)

    return (
        <Page>
            <Text variant='xxLarge' block={true}>
                Transactions
            </Text>
            <Stack horizontal tokens={{ childrenGap: 30 }}>
                <Stack.Item grow='1'>
                    <CommandBar items={actions} />
                </Stack.Item>
                <Stack.Item>
                    <Toggle
                        inlineLabel
                        label='Show completed'
                        checked={showCompleted}
                        onChange={handleShowCompleted}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Filters onChange={onSearch} value={search} />
                </Stack.Item>
            </Stack>
            <TransactionList
                showCompleted={showCompleted}
                selection={selection}
                entity={entity}
                entityType={entityType}
                search={search}
            />
        </Page>
    )
}

export default TransactionListView
