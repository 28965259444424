import React from 'react'
import {
    Text,
    DetailsList,
    SelectionMode,
    Selection,
    CommandBar,
    TextField,
    Persona,
    PersonaSize,
} from '@fluentui/react'
import { createGroup, deleteGroup, getGroups } from '../../../api'
import { useNavigate } from 'react-router-dom'

export const GroupsSection = () => {
    const [groups, setGroups] = React.useState([])
    const [addModalOpen, setAddModalOpen] = React.useState(false)
    const navigate = useNavigate()

    const loadData = () => {
        getGroups().then(setGroups)
    }
    React.useEffect(loadData, [setGroups])

    const onAddModalClose = () => {
        setAddModalOpen(false)
        loadData()
    }

    const [selected, setSelected] = React.useState(null)
    const [selection] = React.useState(
        new Selection({
            onSelectionChanged: () =>
                setSelected(selection.getSelection()[0] || null),
        }),
    )

    const onItemInvoked = (item) => {
        console.log('click', item)
        navigate(`/profile/groups/${item._id}`)
    }
    const onClickEdit = () => {
        onItemInvoked(selected)
    }

    const onClickDelete = () => {
        deleteGroup(selected._id).then(() => loadData())
    }

    /**
     * @type Array<import('@fluentui/react').IDetailsListProps>
     */
    const columns = [
        {
            key: 'icon',
            text: '',
            onRender(col) {
                return (
                    <Persona
                        size={PersonaSize.size32}
                        text={col.name}
                        hidePersonaDetails={true}
                    />
                )
            },
            minWidth: 40,
            maxWidth: 40,
        },
        {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 300,
        },
        {
            key: 'description',
            name: 'Description',
            fieldName: 'description',
            minWidth: 300,
        },
    ]

    const actions = [
        {
            key: 'add',
            text: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => setAddModalOpen(true),
        },
        {
            key: 'edit',
            text: 'Edit',
            iconProps: { iconName: 'Edit' },
            disabled: !selected,
            onClick: onClickEdit,
        },
        {
            key: 'delete',
            text: 'Delete',
            iconProps: { iconName: 'Delete' },
            disabled: !selected,
            onClick: onClickDelete,
        },
    ]

    return (
        <>
            <Text variant='xxLarge'>Groups</Text>
            <CommandBar items={actions} />
            <DetailsList
                items={groups}
                columns={columns}
                selection={selection}
                selectionMode={SelectionMode.single}
                onItemInvoked={onItemInvoked}
            />
            <AddGroupModal isOpen={addModalOpen} onClose={onAddModalClose} />
        </>
    )
}

import {
    Modal,
    Stack,
    Dropdown,
    IconButton,
    PrimaryButton,
    DefaultButton,
} from '@fluentui/react'
import { useAuth } from '../../../hooks/useAuth'

// Turn a name like "Hello World" into "hello-world"
const createSafeName = (str) => {
    return str.replace(/[A-Z]/g, (a) => a.toLowerCase()).replace(/ +/g, '-')
}

const AddGroupModal = ({ isOpen, onClose = () => {} }) => {
    const title = 'Add Group'
    const [data, setData] = React.useState({})
    const { currentAccount } = useAuth()

    const onDataChange = (event) => {
        const name = event.target.name
        setData({ ...data, [name]: event.target.value })
    }

    const onAdd = () => {
        const group = {
            name: createSafeName(data.name),
            title: data.name,
            description: data.description,
        }
        createGroup(group).then((group) => {
            console.log('group', group)
            onClose()
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em' }}
                tokens={{ childrenGap: '1em' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        // styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </div>

                <Stack
                    horizontalAlign='start'
                    tokens={{ childrenGap: 10 }}
                    style={{ width: '100%' }}>
                    <TextField
                        label='Name'
                        name='name'
                        value={data.name}
                        onChange={onDataChange}
                        styles={{
                            root: { width: '100%' },
                        }}
                    />
                    <TextField
                        label='Description'
                        name='description'
                        multiline
                        value={data.description}
                        onChange={onDataChange}
                        styles={{
                            root: { width: '100%' },
                        }}
                    />
                </Stack>

                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='end'>
                    <PrimaryButton onClick={onAdd}>Add</PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}
