import React from 'react'
import {
    Stack,
    Icon,
    Text,
    Persona,
    PersonaSize,
    DetailsList,
    SelectionMode,
} from '@fluentui/react'
import { capitalize } from '../../../lib/string'
import { formatShortDateTime } from '../../../lib/date'

const DocusignOverview = ({ docusignData = {} }) => {
    const items = docusignData.signers
    const columns = [
        {
            key: 'signer',
            name: 'Signer',
            minWidth: 200,
            onRender: (signer) => {
                return <Persona text={signer.name} size={PersonaSize.size48} />
            },
        },
        {
            key: 'status',
            name: 'Status',
            minWidth: 140,
            onRender: (signer) => {
                const style = { width: 32, height: 32, fontSize: 32 }
                return (
                    <Stack
                        horizontal
                        tokens={{ childrenGap: 10 }}
                        verticalAlign='center'
                        style={{ height: '100%' }}>
                        <Icon {...statusToIconProps(signer.status, style)} />
                        <Text>{capitalize(signer.status)}</Text>
                    </Stack>
                )
            },
        },
        {
            key: 'details',
            name: 'Details',
            minWidth: 250,
            onRender: (signer) => {
                const content = [
                    <StatusDetail
                        key='send'
                        label='Sent'
                        date={signer.sentDate}
                    />,
                    <StatusDetail
                        key='completed'
                        label='Completed'
                        date={signer.completedDate}
                    />,
                    <StatusDetail
                        key='declined'
                        label='Declined'
                        date={signer.declinedDate}
                    />,
                ]

                return (
                    <Stack
                        verticalAlign='center'
                        style={{ height: '100%' }}
                        tokens={{ childrenGap: 10 }}>
                        {content}
                    </Stack>
                )
            },
        },
    ]
    return (
        <Stack
            tokens={{ childrenGap: 10 }}
            verticalAlign='center'
            style={{ height: '100%' }}>
            <DetailsList
                items={items}
                columns={columns}
                selectionMode={SelectionMode.none}
            />
        </Stack>
    )
}

const StatusDetail = ({ label, date }) => {
    if (!date) return null
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text style={{ fontWeight: 'bold' }}>{label}:</Text>
            <Text>{formatShortDateTime(date)}</Text>
        </Stack>
    )
}

const statusToIconProps = (status, style) => {
    if (status === 'new') return { iconName: 'ChromeMinimize', style }
    if (status === 'sent') return { iconName: 'Mail', style }
    if (status === 'declined')
        return {
            iconName: 'AlertSolid',
            style: { ...style, color: 'red' },
        }
    if (status === 'completed')
        return {
            iconName: 'CompletedSolid',
            style: { ...style, color: 'green' },
        }

    return { iconName: 'Unknown', style }
}

export default DocusignOverview
