const lerp = (a, b, p) => {
    return (b - a) * p + a
}

function clone(obj) {
    if (obj === null || typeof obj !== 'object' || 'isActiveClone' in obj)
        return obj

    let temp
    if (obj instanceof Date) {
        temp = new obj.constructor()
    } else {
        temp = obj.constructor()
    }

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            obj['isActiveClone'] = null
            temp[key] = clone(obj[key])
            delete obj['isActiveClone']
        }
    }
    return temp
}

const maxChildWidth = (stages) => {
    return stages.reduce((acc, { width }) => Math.max(acc, width), 0)
}

const sumChildHeight = (stages) => {
    return stages.reduce((acc, { height }) => acc + height, 0)
}

const sumChildWidth = (stages) => {
    return stages.reduce((acc, { width }) => acc + width, 0)
}

export { lerp, clone, maxChildWidth, sumChildHeight, sumChildWidth }
