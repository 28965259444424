import { sumChildWidth, sumChildHeight, lerp } from '../utils.js'

export default {
    render: ({ stages }, render) => {
        return stages.map(render).join('\n')
    },
    background: (stage, render, { stepY, stepX, debug }) => {
        const fanOut = stage.trackPos
            .map((trackPos) => {
                const x1 = stage.x
                const y1 = stage.y - stepY / 2
                const x2 = stage.x + trackPos
                const y2 = stage.y + stepY - stepY / 2

                const cx1 = x1
                const cx2 = x2
                const cy1 = lerp(y1, y2, 0.9)
                const cy2 = lerp(y2, y1, 0.9)

                return `<path style="fill:none;" d="M ${x1},${y1} C ${cx1},${cy1} ${cx2},${cy2} ${x2},${y2}" />`
            })
            .join('\n')

        const fanIn = stage.trackPos
            .map((trackPos) => {
                const x1 = stage.x + trackPos
                const y1 = stage.y + (stage.childHeight + 1) * stepY - stepY / 2
                const x2 = stage.x
                const y2 =
                    stage.y +
                    stepY -
                    stepY / 2 +
                    (stage.childHeight + 1) * stepY

                const cx1 = x1
                const cx2 = x2
                const cy1 = lerp(y1, y2, 0.9)
                const cy2 = lerp(y2, y1, 0.9)

                return `<path style="fill:none;" d="M ${x1},${y1} C ${cx1},${cy1} ${cx2},${cy2} ${x2},${y2}" />`
            })
            .join('\n')

        const childContent = stage.stages.map(render).join('\n')

        // Show background rectangle for parallel stages in debug
        let bg = ''
        if (debug) {
            const x1 = stage.x - ((stage.width - 1) / 2) * stepX - stepX * 0.5
            const y1 = stage.y - stepY * 0.5
            const w = stage.width * stepX
            const h = stage.height * stepY
            bg = `<rect x="${x1}" y="${y1}" width="${w}" height="${h}" fill="rgba(255,0,0,0.1)" stroke="none" />`
        }

        return [fanOut, childContent, fanIn, bg].join('\n')
    },
    size: (stage, { trackSpacing }) => {
        // combined with of children
        stage.numTracks = stage.stages.length
        stage.width = sumChildWidth(stage.stages)
        stage.width += (stage.numTracks - 1) * trackSpacing

        // combined height
        stage.childHeight = sumChildHeight(stage.stages)
        stage.height = stage.childHeight + 2

        stage.halfWidth = (stage.width - 1) / 2
        stage.halfHeight = stage.height / 2

        // Add spacers before and after child pipelines
        let spaceBefore = 0
        for (const childStage of stage.stages) {
            childStage.spaceBefore = spaceBefore
            childStage.spaceAfter =
                stage.childHeight - spaceBefore - childStage.height
            spaceBefore += childStage.height
        }

        // Calculate tracks for child stages
        let curr = 0
        stage.tracks = stage.stages.map((child) => {
            curr += child.halfWidth
            const tmp = curr
            curr += child.halfWidth + 1 + trackSpacing
            return tmp
        })
    },
    layout: (stage, layout, { stepX, stepY, trackSpacing }) => {
        let childX = stage.x - stage.halfWidth * stepX
        stage.stages.map((child) => {
            childX += child.halfWidth * stepX
            layout(childX, stage.y + stepY, child)
            childX += child.halfWidth * stepX
            childX += stepX + stepX * trackSpacing
        })
        stage.trackPos = stage.tracks.map(
            (track) => track * stepY - stage.halfWidth * stepY,
        )
    },
}
