import React from 'react'
import PropTypes from 'prop-types'
import { Label, Checkbox, Stack, DefaultPalette } from '@fluentui/react'

import { registerMulti, makeLabel } from './utils.js'

const stack = {
    styles: {
        root: {
            background: DefaultPalette.themeLighterAlt,
            width: 300,
        },
    },
    tokens: {
        childrenGap: 10,
        padding: 10,
    },
}

const BasicLabel = ({ label, required }) => {
    return <Label required={required}>{label}</Label>
}

const MultiChoiceGroup = ({ schema, readonly = false }) => {
    const { name, items, title, calc } = schema

    // Don't allow calculated fields to be edited
    if (calc) {
        readonly = true
    }

    const { onChange, values, errorMessage } = registerMulti(name, null, true)
    const onRenderLabel = makeLabel(schema)

    const checkItem = ({ title, value }, i) => (
        <Checkbox
            label={title}
            key={i}
            checked={values.includes(value)}
            indeterminate={false}
            onChange={(e, v) => {
                if (!readonly) onChange(null, value)
            }}
            disabled={readonly}
        />
    )

    return (
        <Stack styles={stack.styles} tokens={stack.tokens}>
            {onRenderLabel(
                { required: schema.required, label: title },
                BasicLabel,
            )}
            {items.map(checkItem)}
            {errorMessage}
        </Stack>
    )
}

MultiChoiceGroup.propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            text: PropTypes.string,
        }),
    ),
    selectedKeys: PropTypes.arrayOf(PropTypes.string),
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onRenderLabel: PropTypes.func,
}

export default MultiChoiceGroup
