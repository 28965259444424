import React from 'react'
import TabSet from '../TabSet'
import Template from './Template'
import { useStateMachine } from '../../hooks/useStateMachine'

const TabContent = (props) => {
    const sm = useStateMachine()
    const { content, fields, readonly = false } = props

    const [delta, setDelta] = React.useState(0)

    const update = () => {
        setDelta(Date.now())
    }

    React.useEffect(() => {
        sm.state.ee.on('errors', update)
        return () => {
            sm.state.ee.off('errors', update)
        }
    }, [sm])

    return (
        <TabSet>
            {content.contents.map((content, i) => {
                const badge = sm.state.getTabErrorCount(content.tabPath)
                return (
                    <div label={content.title} key={i} badge={badge}>
                        <Template
                            fields={fields}
                            contents={content.contents}
                            readonly={readonly}
                        />
                    </div>
                )
            })}
        </TabSet>
    )
}

TabContent.propTypes = {}

export default TabContent
