import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import Providers from './Providers'
import { getSetting } from './api/config'

// Setup dayjs
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

// Init socket io
const API_BASE = getSetting('API_BASE')
const socketScript = document.createElement('script')
socketScript.src = `${API_BASE}/socket.io/socket.io.js`
document.body.appendChild(socketScript)

const root = createRoot(document.getElementById('root'))
root.render(
    <Providers>
        <App />
    </Providers>,
)
