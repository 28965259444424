import React from 'react'

import { DetailsList } from '@fluentui/react'

import { getTransactions } from '../api'

import { useNavigate } from 'react-router-dom'
import { SelectionMode } from '@fluentui/utilities'

import StatusBadge from '../components/StatusBadge'

import LoadingSpinner from '../components/LoadingSpinner'
import { Icon } from '@fluentui/react'

import { useSearchParams } from '../hooks/useSearchParams'
import { capitalize } from '../lib/string'
import { formatShortDateTime } from '../lib/date'
import { getRecordType } from '../lib/workflow'
const onRenderCapitalize = (item, i, col) => capitalize(item[col.fieldName])

const getWorkflowIcon = (transaction) => {
    return transaction.workflow && transaction.workflow.icon
        ? transaction.workflow.icon
        : 'TextDocument'
}

const columns = [
    {
        key: 'column1',
        name: 'Workflow Type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'iconName',
        minWidth: 16,
        maxWidth: 16,
        onRender: (item) => <Icon iconName={getWorkflowIcon(item)} />,
    },
    {
        key: 'title',
        minWidth: 150,
        maxWidth: 200,
        name: 'Title',
        fieldName: 'title',
    },
    {
        key: 'workflow',
        minWidth: 150,
        maxWidth: 200,
        name: 'Workflow',
        onRender: (item) => {
            return item.workflow ? item.workflow.title : '-'
        },
    },
    {
        key: 'recordType',
        minWidth: 100,
        maxWidth: 100,
        name: 'Record Type',
        fieldName: 'recordType',
        onRender: onRenderCapitalize,
    },
    {
        key: 'created',
        minWidth: 120,
        maxWidth: 300,
        name: 'Created',
        fieldName: 'created',
        onRender(item, index, col) {
            return formatShortDateTime(item[col.fieldName])
        },
    },
    {
        key: 'updated',
        minWidth: 120,
        maxWidth: 300,
        name: 'Updated',
        fieldName: 'updated',
        onRender(item, index, col) {
            return formatShortDateTime(item[col.fieldName])
        },
    },
    {
        key: 'status',
        minWidth: 90,
        maxWidth: 300,
        name: 'Status',
        fieldName: 'status',
        onRender(item) {
            return <StatusBadge status={item.status} />
        },
    },
    {
        key: 'currentStage',
        minWidth: 100,
        maxWidth: 300,
        name: 'Current Stage',
        onRender: (item) => {
            if (item.currentStage >= item.stages.length) {
                return 'Completed'
            }
            return item.stages[item.currentStage].title
        },
    },
    {
        key: 'currentRole',
        minWidth: 150,
        maxWidth: 300,
        name: 'Current Role',
        onRender: (item) => {
            if (item.currentRole === 'creator') return ''
            const role = item.roles.find(
                (role) => role.name === item.currentRole,
            )
            if (!role) return 'Role: ' + item.currentRole
            return `${role.user.firstName} ${role.user.lastName}`
        },
    },
]

const sortData = (data, key, asc) => {
    return asc
        ? data.sort((a, b) => (a[key] > b[key] ? 1 : -1))
        : data.sort((a, b) => (a[key] < b[key] ? 1 : -1))
}

const filterData = (data, search) => {
    return data.filter((item) => JSON.stringify(item).includes(search))
}

const TransactionList = ({
    limit = 100,
    showCompleted = true,
    selection = null,
    search = null,
}) => {
    const navigate = useNavigate()
    const { entity = null, entityType = null } = useSearchParams()
    const [transactions, setTransactions] = React.useState([])
    const [transactionsLoading, setTransactionsLoading] = React.useState(true)
    const [sortColumn, setSortColumn] = React.useState('created')
    const [sortOrder, setSortOrder] = React.useState(false)

    const [finalColumns, data] = React.useMemo(() => {
        const finalColumns = columns.map((column) => {
            if (column.key !== sortColumn) return column
            return {
                ...column,
                isSorted: true,
                isSortedDescending: !sortOrder,
            }
        })

        const filteredData = search
            ? filterData(transactions, search)
            : transactions

        const data = sortData(filteredData, sortColumn, sortOrder)
        return [finalColumns, data]
    }, [columns, sortColumn, sortOrder, search, transactions])

    const getTransactionData = () => {
        setTransactionsLoading(true)
        // Get transactions from db
        const query = !entity
            ? {
                  status: showCompleted ? undefined : { $ne: 'complete' },
              }
            : {
                  [`params.${entityType}`]: entity,
                  status: showCompleted ? undefined : { $ne: 'complete' },
              }

        getTransactions(query, limit).then((transactions) => {
            setTransactionsLoading(false)
            const expandedTransactions = transactions.map((transaction) => {
                return {
                    ...transaction,
                    iconName: 'Document',
                    recordType: getRecordType(transaction.workflow),
                }
            })
            setTransactions(expandedTransactions)
        })
    }

    React.useEffect(() => {
        getTransactionData()
    }, [showCompleted])

    if (transactionsLoading) {
        return <LoadingSpinner />
    }

    const onClick = (item) => {
        const extra = item.account ? `account=${item.account}` : ''
        navigate(`/transactions/${item._id}?` + extra)
    }

    const onColumnHeaderClick = (item, col) => {
        if (col.key === sortColumn) {
            setSortOrder((sortOrder) => !sortOrder)
        } else {
            setSortColumn(col.key)
            setSortOrder(true)
        }
    }
    const onColumnHeaderContextMenu = (...args) => {
        console.log('right click', args)
    }

    return (
        <DetailsList
            compact
            items={data}
            columns={finalColumns}
            selectionMode={
                selection ? SelectionMode.single : SelectionMode.none
            }
            selection={selection}
            onItemInvoked={onClick}
            onRenderRow={(props, defaultRender) => (
                <div className='red'>
                    {defaultRender({ ...props, className: 'red' })}
                </div>
            )}
            styles={{ root: { cursor: 'pointer' } }}
            onColumnHeaderClick={onColumnHeaderClick}
            onColumnHeaderContextMenu={onColumnHeaderContextMenu}
        />
    )
}

export default TransactionList
