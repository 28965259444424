import React from 'react'
import { Text, Stack, Icon } from '@fluentui/react'

import Page from '../../components/layout/Page'
import { getWorkflows } from '../../api'

const Workflows = (props) => {
    const [workflows, setWorkflows] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)
        getWorkflows().then((workflows) => {
            setWorkflows(workflows)
            setLoading(false)
        })
    }, [setWorkflows, setLoading])

    return (
        <Page>
            <Text variant='xxLarge'>Workflows</Text>
            <br />
            <Text variant='large'>Count: {workflows.length}</Text>
            <Stack tokens={{ childrenGap: 20 }}>
                {workflows.map((workflow, i) => {
                    return <WorkflowPanel workflow={workflow} key={i} />
                })}
            </Stack>
            <pre>{JSON.stringify(workflows, null, 4)}</pre>
        </Page>
    )
}

const WorkflowPanel = ({ workflow }) => {
    const iconName = 'Processing'
    return (
        <Stack
            style={{
                border: '2px solid #ccc',
                padding: 20,
                margin: '10px 0 10px 0px',
                borderRadius: 8,
            }}
            horizontal
            tokens={{ childrenGap: 20 }}>
            <Icon
                iconName={iconName}
                style={{ width: 80, height: 80, fontSize: 80 }}
            />
            <Stack>
                <ItemDetails
                    size='large'
                    label='Title'
                    value={workflow.title}
                />
                <ItemDetails size='large' label='Name' value={workflow.name} />
                <ItemDetails label='Description' value={workflow.description} />
                <ItemDetails label='Created' value={workflow.created} />
                <ItemDetails label='Updated' value={workflow.updated} />
                <ItemDetails
                    label='Stages'
                    value={workflow.stages.map((stage, i) => {
                        return <div key={i}>{stage.title}</div>
                    })}
                />
            </Stack>
        </Stack>
    )
}

const ItemDetails = ({ label = '', size = 'medium', value = '' }) => {
    return (
        <Text variant={size}>
            <b style={{ width: 125, display: 'inline-block' }}>{label}: </b>
            {value}
        </Text>
    )
}

export default Workflows
