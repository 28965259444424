import React from 'react'
import {
    Text,
    DetailsList,
    SelectionMode,
    Selection,
    CommandBar,
    TextField,
} from '@fluentui/react'

export const Permissions = ({ group, loadData }) => {
    const [selected, setSelected] = React.useState([])
    const [selection] = React.useState(
        new Selection({
            onSelectionChanged: () =>
                setSelected(selection.getSelection() || []),
        }),
    )
    const [addModalOpen, setAddModalOpen] = React.useState(false)

    const onAddModalClose = () => {
        setAddModalOpen(false)
        loadData()
    }

    const onDelete = () => {
        console.log('selected', selected)
        const permissions = selected
        patchGroupPermissions(group._id, { permissions, mode: 'delete' }).then(
            () => {
                loadData()
            },
        )
    }

    const items = group.permissions
    const columns = [
        {
            key: 'name',
            name: 'Name',
            onRender: (col) => col,
        },
    ]

    const actions = [
        {
            key: 'add',
            name: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => setAddModalOpen(true),
        },
        {
            key: 'delete',
            name: 'Remove',
            iconProps: { iconName: 'Delete' },
            disabled: !selected.length,
            onClick: onDelete,
        },
    ]

    return (
        <>
            <Text variant='xLarge'>Permissions</Text>
            <CommandBar items={actions} />
            <DetailsList
                items={items}
                columns={columns}
                selection={selection}
                selectionMode={SelectionMode.multiple}
            />
            <AddPermissionModal
                group={group}
                isOpen={addModalOpen}
                onClose={onAddModalClose}
            />
        </>
    )
}

import {
    Modal,
    Stack,
    Dropdown,
    IconButton,
    PrimaryButton,
    DefaultButton,
} from '@fluentui/react'
import { patchGroupPermissions } from '../../../../api/module/group'

const AddPermissionModal = ({ group, isOpen, onClose = () => {} }) => {
    const title = 'Add Permissions'
    const [data, setData] = React.useState('')

    const onDataChange = (event) => {
        setData(event.target.value)
    }

    const onAdd = () => {
        const permissions = data
            .split('\n')
            .map((a) => a.trim())
            .filter((a) => !!a)
        patchGroupPermissions(group._id, { permissions }).then(() => {
            setData('')
            onClose()
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em' }}
                tokens={{ childrenGap: '1em' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        // styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </div>

                <Stack
                    horizontalAlign='start'
                    tokens={{ childrenGap: 10 }}
                    style={{ width: '100%' }}>
                    <TextField
                        multiline={true}
                        value={data}
                        onChange={onDataChange}
                        styles={{
                            root: { width: '100%' },
                        }}
                    />
                </Stack>

                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='end'>
                    <PrimaryButton onClick={onAdd}>Add</PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}
