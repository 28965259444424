import React from 'react'
// import { Stack, PrimaryButton, TextField } from '@fluentui/react'
// import { Text } from '@fluentui/react'
// import { Link } from 'react-router-dom'

// import { useNavigate } from 'react-router-dom'

import { useValues } from '@/hooks/useValues'
// import { useAuth } from '@/hooks/useAuth'
// import { useSearchParams } from '@/hooks/useSearchParams'

import { resetPassword } from '../../api'
// const defaultLoggedInPage = '/dashboard'

import FullPage from '@/components/layout/FullPage'

import { PrimaryButton } from './Buttons'
import { Wrap, Heading, TextField } from './Utils'

const ResetPassword = () => {
    const { values, setValue } = useValues({
        user: '',
        password: '',
    })
    const submit = (e) => {
        e.preventDefault()
        resetPassword(values).then(() => {
            window.location.reload()
        })
    }
    return (
        <>
            <Wrap>
                <Heading>Admin Reset Password</Heading>
                <TextField
                    name='user'
                    label='User Id'
                    value={values.user}
                    onChange={setValue('user')}
                    autoComplete='none'
                    required
                />
                <TextField
                    name='password'
                    label='Password'
                    value={values.password}
                    onChange={setValue('password')}
                    autoComplete='none'
                    required
                    type='password'
                />
                <PrimaryButton onClick={submit}>Reset password</PrimaryButton>
            </Wrap>
        </>
    )
}

const Login = (props) => {
    return (
        <FullPage background='url("https://source.unsplash.com/1600x900/?building")'>
            <ResetPassword />
        </FullPage>
    )
}

export default Login
