import React from 'react'
import PropTypes from 'prop-types'

import {
    ShimmeredDetailsList,
    ContextualMenuItemType,
    CommandBar,
} from '@fluentui/react'
import ListField from './ListField'
import { Selection, SelectionMode } from '@fluentui/utilities'
import { useNavigate } from 'react-router-dom'
import { formatShortDateTime } from '../../lib/date'
import { formatUser } from '../../lib/utils'
import { useStateMachine } from '../../hooks/useStateMachine.js'

const extractFieldRecord = (fieldValues, columns) => {
    const record = {}
    for (let j = 0; j < fieldValues.length; j += 1) {
        const fieldName = fieldValues[j].field
        if (columns.includes(fieldName)) {
            const propData = fieldValues[j]
            record[fieldName] = propData.values ? propData.values[0] : ''
        }
    }
    return record
}

const noop = () => {}

const objectMatch = (obj, cols, value) => {
    let passes = false
    if (!obj) return false
    if (!value) return true

    const lowerCaseValue = value.toLowerCase()

    for (const col of cols) {
        if (typeof obj[col] === 'undefined') continue

        const target = obj[col]
        const type = typeof target
        if (type === 'string') {
            const stringValue = obj[col].toLowerCase()
            if (stringValue.includes(lowerCaseValue)) {
                passes = true
                break
            }
        }
    }

    return passes
}

const RecordTable = (props) => {
    const navigate = useNavigate()
    const {
        linkTarget,
        listColumns,
        records,
        fields,
        actions = [],
        onClickAction = noop,
        onItemInvoked = noop,
        onSelectionChanged,
        search,
        loading = false,
    } = props

    const hasLinkTarget = !!linkTarget

    const [sortColumn, setSortColumn] = React.useState(0)
    const [sortDescending, setSortDescending] = React.useState(false)
    const sm = useStateMachine()

    const actionsCol = actions.length
        ? {
              key: 'actions',
              name: 'Action',
              minWidth: 150,
              maxWidth: 400,
              onRender: function Actions(item) {
                  const items = (
                      hasLinkTarget
                          ? [
                                {
                                    key: 'divider_1',
                                    itemType: ContextualMenuItemType.Divider,
                                },
                            ]
                          : []
                  ).concat(
                      actions.map((action, i) => {
                          return {
                              key: i,
                              type: 'button',
                              text: action.text,
                              onClick: () => {
                                  onClickAction(item.id, action)
                              },
                              iconProps: { iconName: 'Play' },
                          }
                      }),
                  )
                  //   const menuProps = { items }

                  const actionsCommandBarItems = [
                      {
                          key: 'view',
                          type: 'button',
                          //   text: `View`,
                          iconProps: { iconName: 'View' },
                          onClick: () => {
                              navigate(`${linkTarget}/${item.id}?readonly=true`)
                          },
                      },
                      {
                          key: 'edit',
                          type: 'button',
                          //   text: `Edit`,
                          iconProps: { iconName: 'Edit' },
                          onClick: () => {
                              navigate(`${linkTarget}/${item.id}`)
                          },
                      },
                      {
                          key: 'action',
                          type: 'button',
                          iconProps: { iconName: 'MoreVertical' },
                          subMenuProps: {
                              items: items,
                          },
                      },
                  ]

                  return (
                      <CommandBar
                          styles={{ root: { padding: 0, background: 'none' } }}
                          items={actionsCommandBarItems}
                      />
                  )

                  //   return (
                  //       <CommandBarButton
                  //           style={{ height: 32 }}
                  //           iconProps={{ iconName: 'MoreVertical' }}
                  //           menuProps={menuProps}
                  //       />
                  //   )
              },
          }
        : null

    const columns = [...listColumns]
        .map((col, i) => {
            if (col === '$created') {
                return {
                    key: col,
                    name: 'Created',
                    minWidth: 100,
                    maxWidth: 150,
                    fieldName: 'created',
                    onRender: (row) => formatShortDateTime(row.created),
                }
            }
            if (col === '$updated') {
                return {
                    key: col,
                    name: 'Updated',
                    minWidth: 100,
                    maxWidth: 150,
                    fieldName: 'updated',
                    onRender: (row) => formatShortDateTime(row.updated),
                }
            }
            if (col === '$createdBy') {
                return {
                    key: col,
                    name: 'Created By',
                    minWidth: 100,
                    maxWidth: 150,
                    fieldName: 'createdBy',
                    onRender: (row) =>
                        row.createdBy
                            ? formatUser(
                                  row.createdBy.firstName,
                                  row.createdBy.lastName,
                              )
                            : '-',
                }
            }
            const def = fields[col]
            if (!def) {
                console.warn(`Missing field def for: ${col}`)
            }

            // @TODO: not this
            const fieldDef = sm.getFieldDef(col)

            return {
                key: i,
                name: def ? def.title : col,
                minWidth: 100,
                maxWidth: 150,
                fieldName: col,
                flexGrow: 1,
                isResizable: 1,
                isSorted: i === sortColumn,
                isSortedDescending: sortDescending,
                onRender: fieldDef
                    ? (row) => {
                          return fieldDef.render([row[col]]) || ''
                      }
                    : undefined,
            }
        })
        .concat([actionsCol])
        .filter((a) => !!a)

    const onColumnHeaderClick = (e, item) => {
        const idx = listColumns.indexOf(item.fieldName)
        if (sortColumn === idx) {
            setSortDescending(!sortDescending)
            return
        } else {
            setSortDescending(false)
            setSortColumn(idx)
        }
    }

    const sortAscCmp = (a, b) => {
        if (typeof a[listColumns[sortColumn]] === 'undefined') return 1
        if (typeof b[listColumns[sortColumn]] === 'undefined') return -1
        return a[listColumns[sortColumn]] < b[listColumns[sortColumn]] ? -1 : 1
    }

    const sortDescCmp = (a, b) => {
        if (typeof a[listColumns[sortColumn]] === 'undefined') return 1
        if (typeof b[listColumns[sortColumn]] === 'undefined') return -1
        return a[listColumns[sortColumn]] < b[listColumns[sortColumn]] ? 1 : -1
    }

    const processedRecords = records
        .map((objRow) => ({
            id: objRow._id,
            created: objRow.created,
            updated: objRow.updated,
            createdBy: objRow.createdBy,
            ...extractFieldRecord(objRow.data, listColumns),
        }))
        .filter((objRow) => {
            if (!search) return true

            return objectMatch(objRow, listColumns, search)
        })
        .sort(sortDescending ? sortDescCmp : sortAscCmp)

    const onRender = (item, index, col) => {
        return (
            <ListField
                dataVal={item[col.fieldName]}
                fieldSchema={fields[col.fieldName]}
            />
        )
    }

    const _onSelectionChanged = () => {
        if (!onSelectionChanged) return
        const selected = selection.getSelection()
        onSelectionChanged(selected.length ? selected[0].id : undefined)
    }

    const selection = props.onSelectionChanged
        ? new Selection({
              onSelectionChanged: _onSelectionChanged,
          })
        : null

    const selectionMode = props.onSelectionChanged
        ? SelectionMode.single
        : SelectionMode.none

    const getKey = (item) => (item ? item.id : null)

    return (
        <ShimmeredDetailsList
            items={processedRecords}
            enableShimmer={loading}
            columns={columns}
            onRenderItemColumn={onRender}
            selectionMode={selectionMode}
            selection={selection}
            getKey={getKey}
            onColumnHeaderClick={onColumnHeaderClick}
            onItemInvoked={onItemInvoked}
            setKey='single'
            styles={{ root: { cursor: 'pointer' } }}
        />
    )
}

RecordTable.propTypes = {
    records: PropTypes.array.isRequired,
    fields: PropTypes.object.isRequired,
    listColumns: PropTypes.array.isRequired,
    linkKey: PropTypes.string,
    linkTarget: PropTypes.string,
}

export default RecordTable
