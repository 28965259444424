import React from 'react'
import {
    // Stack,
    DetailsList,
    Icon,
    Selection,
    SelectionMode,
    CommandBar,
    Text,
} from '@fluentui/react'

import { useBoolean } from '@fluentui/react-hooks'

import {
    getWorkflows,
    triggerWorkflow,
    getActionsByEntityName,
    triggerWorkflowAndParams,
} from '@/api'
import { useNavigate } from 'react-router-dom'

import Page from '@/components/layout/Page'
import LoadingSpinner from '../../components/LoadingSpinner'
import { formatShortDateTime } from '../../lib/date'
import { capitalize } from '../../lib/string'
import TransactionDetailsPanel from './TransactionDetailsPanel'

// const stackTokens = {
//     horizontalAlign: 'center',
//     childrenGap: 15,
//     padding: 10,
// }
// const stackStyles = {
//     root: {
//         minheight: 500,
//     },
// }

import { getRecordType } from '../../lib/workflow'

import { useSearchParams } from '../../hooks/useSearchParams'

const onRenderDateTime = (item, i, col) =>
    formatShortDateTime(item[col.fieldName])
const onRenderCapitalize = (item, i, col) => capitalize(item[col.fieldName])

const deduplicateWorkflows = (workflows) => {
    const workflowNames = {}
    workflows.forEach((workflow) => {
        if (!(workflow.name in workflowNames)) {
            workflowNames[workflow.name] = workflow
        }
        if (
            workflow.name in workflowNames &&
            workflowNames[workflow.name].created < workflow.created
        ) {
            workflowNames[workflow.name] = workflow
        }
    })

    return Object.keys(workflowNames).map((key) => workflowNames[key])
}

const CreateTransaction = (props) => {
    const navigate = useNavigate()
    const { entity, entityType } = useSearchParams()

    const [leasleSchemaLoading, setLeasleSchemaLoading] = React.useState(false)
    const [transactionSchemas, setTransactionSchemas] = React.useState([])
    const [selected, setSelected] = React.useState(null)

    const [
        isDetailsOpen,
        { setTrue: openDetailsPanel, setFalse: dismissDetailsPanel },
    ] = useBoolean(false)

    React.useEffect(() => {
        ;(async () => {
            const workflows = entityType
                ? await getActionsByEntityName(entityType)
                : await getWorkflows()

            // workflows = deduplicateWorkflows(workflows)

            setTransactionSchemas(workflows)
            setLeasleSchemaLoading(false)
        })()
    }, [entityType])

    // Generate data
    const genRowData = (schema) => ({
        _id: schema._id,
        transactionType: schema.title,
        description: schema.description,
        recordType: getRecordType(schema),
        version: schema.version || '0.1', // @TODO: workflow versions
        created: new Date(schema.created),
        schema,
    })

    const data = React.useMemo(
        () =>
            transactionSchemas
                .map(genRowData)
                .sort((a, b) => (+a.created > +b.created ? -1 : 1)),
        [transactionSchemas],
    )

    const selection = new Selection({
        onSelectionChanged: () => {
            setSelected(selection.getSelection()[0])
        },
    })

    const instantiateTransaction = async () => {
        console.debug(`Creating ${selected.title}`)
        if (entity) {
            // Trigger workflow and action the first ingress stage
            const newTransaction = await triggerWorkflowAndParams(
                selected._id,
                entityType,
                entity,
            )

            navigate(`/transactions/${newTransaction._id}`)
        } else {
            // Basic workflow trigger
            const newTransaction = await triggerWorkflow(selected._id)
            navigate(`/transactions/${newTransaction._id}`)
        }
    }

    // Columns
    const columns = [
        {
            key: 'icon',
            name: 'Type Icon',
            isIconOnly: true,
            onRender: () => <Icon iconName='Page' />,
            minWidth: 16,
            maxWidth: 16,
        },
        {
            key: 'transactionType',
            name: 'Transaction Type',
            fieldName: 'transactionType',
            minWidth: 150,
            maxWidth: 250,
        },
        {
            key: 'recordType',
            name: 'Record Type',
            fieldName: 'recordType',
            minWidth: 100,
            maxWidth: 125,
            onRender: onRenderCapitalize,
        },
        {
            key: 'description',
            name: 'Description',
            fieldName: 'description',
        },
        {
            key: 'version',
            name: 'Version',
            fieldName: 'version',
        },
        {
            key: 'created',
            name: 'Created',
            fieldName: 'created',
            onRender: onRenderDateTime,
            minWidth: 150,
            maxWidth: 250,
            isSorted: true,
            isSortedDescending: true,
        },
        // {
        //     key: 'action',
        //     name: 'Action',
        //     onRender: React.useCallback(() => null),
        // },
    ]

    // Action Bar Commands
    const commands = [
        {
            key: 'back',
            name: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => navigate(-1),
        },
        {
            key: 'start',
            name: 'Start Transaction',
            iconProps: { iconName: 'Play' },
            disabled: !selected,
            onClick: instantiateTransaction,
        },
        {
            key: 'view',
            name: 'View Details',
            iconProps: { iconName: 'View' },
            disabled: !selected,
            onClick: openDetailsPanel,
        },
    ]

    if (leasleSchemaLoading) {
        return (
            <Page>
                <LoadingSpinner />
            </Page>
        )
    }

    const transactionDetailsPanel = isDetailsOpen ? (
        <TransactionDetailsPanel
            open={isDetailsOpen}
            setOpenTrue={openDetailsPanel}
            setOpenFalse={dismissDetailsPanel}
            workflow={selected.schema}
        />
    ) : null

    return (
        <Page>
            <Text variant='xxLarge'>New Transaction</Text>
            <CommandBar items={commands} />
            <DetailsList
                items={data}
                columns={columns}
                selection={selection}
                selectionMode={SelectionMode.single}
                onItemInvoked={instantiateTransaction}
            />
            {transactionDetailsPanel}
        </Page>
    )
}

export default CreateTransaction
