import React from 'react'

import { getMenu } from '../api'

const MenuContext = React.createContext({})

function MenuProvider(props) {
    const [expanded, setExpanded] = React.useState(true)
    const [loading, setLoading] = React.useState(true)

    const [menu, setMenu] = React.useState(null)

    const loadMenu = async () => {
        setLoading(true)
        getMenu()
            .then((menu) => {
                setLoading(false)
                setMenu(menu)
            })
            .catch((err) => {
                console.error('Error loading menu', err)
                setLoading(false)
            })
    }

    React.useEffect(() => {
        loadMenu()
    }, [])

    const toggleMenu = () => {
        setExpanded(!expanded)
    }

    return (
        <MenuContext.Provider
            value={{
                loading,
                menu,
                expanded,
                toggleMenu,
                loadMenu,
            }}
            {...props}
        />
    )
}

const useMenu = () => React.useContext(MenuContext)

export { MenuProvider, useMenu }
