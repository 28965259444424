/* global refreshTokens */
import axios from 'axios'

import { getSetting } from './config'

const minRequiredStateVersion = 1
const currentStateVersion = 1

const API_BASE = getSetting('API_BASE')

const client2 = axios.create({
    baseURL: API_BASE,
})

const client2Auth = axios.create({
    baseURL: API_BASE,
})

let refreshToken = ''
let accessToken = ''
let accountId = null

const loadAuthState = (defaultAuthState) => {
    console.log(window.location)

    // If we have a window.location.hash, check to see if it is
    // an token, if so use it.
    if (window.location.search) {
        try {
            const params = new URLSearchParams(window.location.search.slice(1))
            const paramsToken = params.get('token')
            if (paramsToken) {
                console.log('Got token', paramsToken)
                return {
                    loggedIn: true,
                    refreshToken: paramsToken,
                    accessToken: paramsToken,
                    userId: null,
                }
            }
        } catch (err) {
            console.error('Error reading hash token', err)
        }
    }

    const json = localStorage.getItem('leasle-auth')
    if (!json) {
        return defaultAuthState
    }
    const state = JSON.parse(json)
    if (
        typeof state.version === 'undefined' ||
        state.version < minRequiredStateVersion
    ) {
        clearAuthState()
        window.location.reload()
        return defaultAuthState
    }
    return state
}

const clearAuthState = () => {
    localStorage.removeItem('leasle-auth')
}

const saveAuthState = (data) => {
    const json = JSON.stringify({ ...data, version: currentStateVersion })
    localStorage.setItem('leasle-auth', json)
}

window.saveProfile = (name) => {
    const json = JSON.stringify(loadAuthState())
    localStorage.setItem(`leasle-auth-profile-${name}`, json)
}

window.clearAllProfiles = () => {
    for (let i = 0; i < localStorage.length; i += 1) {
        const key = localStorage.key(i)
        if (!key.startsWith('leasle-auth-profile-')) continue
        localStorage.removeItem(key)
    }
    window.location.reload()
}

window.clearProfile = (name) => {
    localStorage.removeItem(`leasle-auth-profile-${name}`)
    window.location.reload()
}

window.loadProfile = (name) => {
    const key = `leasle-auth-profile-${name}`
    if (!localStorage.getItem(key)) {
        throw new Error(`Unknown profile name: ${key}`)
    }
    const json = JSON.parse(localStorage.getItem(key))
    saveAuthState(json)
    window.location.reload()
}

window.getProfiles = () => {
    return Object.keys(localStorage)
        .filter((key) => key.match(/^leasle-auth-profile-/))
        .map((key) => key.replace(/^leasle-auth-profile-/, ''))
}

const mergeAuthState = (data) => {
    saveAuthState({ ...loadAuthState({}), ...data })
}

const setAuthTokens = (_refreshToken, _accessToken, _accountId = null) => {
    refreshToken = _refreshToken
    accessToken = _accessToken
    accountId = _accountId

    const headers = {
        'X-Authorization': `Bearer ${accessToken}`,
    }
    console.log('client2Auth.defaults', client2Auth.defaults)
    Object.assign(client2Auth.defaults.headers.common, headers)

    if (_accountId) {
        client2Auth.defaults.params = {
            account: accountId,
        }
    }

    mergeAuthState({
        refreshToken,
        accessToken,
        accountId,
    })
}

// Helper for returning the body from axios calls
const getData = ({ data }) => data

let refreshPromise = null
let refreshLoading = false
const authError = (opts) => async (error) => {
    if (!error.response || opts.url.match(/\/refresh$/i)) {
        throw error
    }

    if (error.response.status === 401) {
        if (!refreshLoading) {
            refreshLoading = true
            // Refresh tokens

            refreshPromise = refreshTokens().catch((err) => {
                clearAuthState()
                window.location = '/login'
            })
            const tokens = await refreshPromise
            console.log('client2Auth TOKENS', tokens)
            setAuthTokens(tokens.refreshToken, tokens.accessToken)
            refreshLoading = false
            refreshPromise = null
        } else {
            await refreshPromise
        }

        return await client2Auth(opts)
    }

    throw error
}

const authGet = (opts) => {
    const _opts = { method: 'get', ...opts }
    return client2Auth(_opts).catch(authError(_opts))
}
const authPost = (opts) => {
    const _opts = { method: 'post', ...opts }
    return client2Auth(_opts).catch(authError(_opts))
}
const authPatch = (opts) => {
    const _opts = { method: 'patch', ...opts }
    return client2Auth(_opts).catch(authError(_opts))
}

const authDelete = (opts) => {
    const _opts = { method: 'delete', ...opts }
    return client2Auth(_opts).catch(authError(_opts))
}

// Add a request interceptor
client2Auth.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        // console.log('XPIP request', config)
        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    },
)

// Add a response interceptor
client2Auth.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log('XPIP response', response)
        return response
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    },
)

export {
    authGet,
    authPost,
    authPatch,
    authDelete,
    getData,
    client2,
    refreshToken,
    accessToken,
    accountId,
    API_BASE,
    setAuthTokens,
    loadAuthState,
    saveAuthState,
    clearAuthState,
    mergeAuthState,
}
