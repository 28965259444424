import React from 'react'
import {
    Text,
    Stack,
    Dropdown,
    Label,
    DefaultButton,
    Icon,
    IconButton,
} from '@fluentui/react'
import Page from '../components/layout/Page'

import FileUploadInput from '../components/FileUploadInput'

import { getEntityTypes } from '../api'

const extractHeadersCsv = async (file) => {
    const text = await file.text()
    const lines = text.split('\n')
    return lines[0].split(',')
}

const Import = (props) => {
    const [file, setFile] = React.useState(null)
    const [entityTypes, setEntityTypes] = React.useState([])
    const [fromOptions, setFromOptions] = React.useState([])
    const [toOptions, setToOptions] = React.useState([])

    const onChange = (e) => {
        console.log('File', e)
        setFile(e.target.files.length ? e.target.files[0] : null)
    }

    const onEntityTypeChange = (e, item) => {
        console.log(item)
        const entityType = entityTypes.find((e) => e._id === item.key)
        if (!entityType) {
            setToOptions([])
            return
        }
        console.log('EntityType', entityType)
        const toOptions = entityType.fields.map(({ name, title }, i) => ({
            key: name,
            text: [name, title].join(' - '),
        }))
        setToOptions(toOptions)
    }

    React.useEffect(() => {
        getEntityTypes().then((entityTypes) => {
            setEntityTypes(entityTypes)
        })
    }, [])

    React.useEffect(() => {
        if (!file) return

        if (file.type === 'text/csv') {
            extractHeadersCsv(file).then((headers) => {
                console.log(headers)
                const fromOptions = headers.map((header, i) => {
                    return {
                        key: header,
                        text: header,
                    }
                })
                setFromOptions(fromOptions)
            })
        }
    }, [file])

    const entityOptions = entityTypes.map((entityType) => ({
        key: entityType._id,
        text: entityType.name,
    }))

    return (
        <Page>
            <Stack horizontalAlign='center' tokens={{ childrenGap: 10 }}>
                <Text variant='xxLarge'>Import</Text>
                <Stack
                    tokens={{ childrenGap: 10 }}
                    styles={{ root: { width: 500 } }}>
                    <FileUploadInput
                        onChange={onChange}
                        multiple={false}
                        buttonText='Upload file'
                    />

                    {file ? (
                        <Stack>
                            <Text variant='xLarge'>File Details</Text>
                            <Stack horizontal>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <p>Type: {file.type}</p>
                                    <p>Size: {file.size}</p>
                                </Stack>
                            </Stack>
                            <div>
                                <Label>Select Entity Type:</Label>
                                <Dropdown
                                    options={entityOptions}
                                    onChange={onEntityTypeChange}
                                />
                            </div>
                        </Stack>
                    ) : null}
                </Stack>
                {file ? (
                    <FieldMapping
                        fromOptions={fromOptions}
                        toOptions={toOptions}
                    />
                ) : null}
            </Stack>
        </Page>
    )
}

const FieldMapping = ({ fromOptions = [], toOptions = [] }) => {
    const [mapping, setMapping] = React.useState([])
    console.log('Mapping', mapping)

    const addMapping = () => {
        setMapping((mapping) => mapping.concat([{}]))
    }
    const onDelete = (i) => {
        console.log('ONDELETE', i, mapping)
        const newMapping = mapping.slice(0)
        newMapping.splice(i, 1)
        setMapping(newMapping)
        console.log('ONDELETE', i, newMapping)
    }
    const onChange = (i, key, e, item) => {
        console.log(i, key, e, item)
        const newMapping = mapping.slice(0)
        newMapping[i][key] = item.key
        setMapping(newMapping)
    }

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Text variant='xLarge'>Field Mapping</Text>
            {mapping.map((map, i) => {
                return (
                    <FieldMap
                        key={i}
                        map={map}
                        fromOptions={fromOptions}
                        toOptions={toOptions}
                        onDelete={onDelete.bind(null, i)}
                        onChange={onChange.bind(null, i)}
                    />
                )
            })}
            <DefaultButton onClick={addMapping}>Add Field</DefaultButton>
        </Stack>
    )
}

const FieldMap = ({
    map,
    fromOptions = [],
    toOptions = [],
    onDelete = () => {},
    onChange = () => {},
}) => {
    return (
        <Stack
            horizontal
            horizontalAlign='space-between'
            verticalAlign='center'>
            <Dropdown
                onChange={onChange.bind(null, 'from')}
                dropdownWidth='auto'
                options={fromOptions}
                styles={{ root: { width: 200 } }}
                selectedKey={map.from || null}
            />
            <Icon
                iconName='SkypeArrow'
                style={{
                    margin: '0 10px',
                    fontSize: 24,
                    lineHiehgt: 24,
                    transform: 'scaleX(-1)',
                }}
            />
            <Dropdown
                onChange={onChange.bind(null, 'to')}
                dropdownWidth='auto'
                options={toOptions}
                styles={{ root: { width: 200 } }}
                selectedKey={map.to || null}
            />
            <Stack horizontal>
                <IconButton
                    onClick={onDelete}
                    iconProps={{ iconName: 'Delete' }}
                    styles={{ root: { minWidth: 30 } }}
                />
                <IconButton
                    iconProps={{ iconName: 'Settings' }}
                    styles={{ root: { minWidth: 30 } }}
                />
            </Stack>
        </Stack>
    )
}

export default Import
