import React from 'react'
import { Panel, PanelType, Label, Stack } from '@fluentui/react'
import { formatShortDateTime } from '../../lib/date'

import { getRecordType } from '../../lib/workflow'
import { capitalize } from '../../lib/string'

import Pipeline from '../../components/Pipeline'
import PipelineC from '../../lib/pipeline/Pipeline.js'

const TransactionDetailsPanel = ({
    workflow = null,
    open = false,
    setOpenTrue = () => {},
    setOpenFalse = () => {},
}) => {
    // const pipelineData = getPipelineDataForWorkflow(workflow)

    // Pipeline data
    const pipeline = new PipelineC(workflow)
    console.log('PIPELINE', pipeline)

    return (
        <Panel
            isOpen={open}
            headerText={'Process Details : ' + workflow.title}
            type={PanelType.medium}
            onDismiss={setOpenFalse}>
            <div>
                <Label>Description</Label>
                <p style={{ margin: 0 }}>{workflow.description}</p>
            </div>
            <div>
                <Label>Record Type</Label>
                <p style={{ margin: 0 }}>
                    {capitalize(getRecordType(workflow))}
                </p>
            </div>
            {/* <div>
                <Label>Record Conditions</Label>
                <p>{schema.description}</p>
            </div> */}
            <div>
                <Label>Version</Label>
                <p style={{ margin: 0 }}>0.1</p>
            </div>
            <div>
                <Label>Published</Label>
                <p style={{ margin: 0 }}>
                    {formatShortDateTime(workflow.created)}
                </p>
            </div>

            <Stack horizontal={true}>
                <Stack>
                    <Pipeline pipeline={pipeline} canSelect={false} />
                </Stack>
            </Stack>
        </Panel>
    )
}

export default TransactionDetailsPanel
