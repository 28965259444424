import types from './types/index.js'
import { clone } from './utils.js'

const defaultOpts = {
    margin: 16,
    stepY: 32,
    stepX: 32,
    debug: false,
    backgroundColor: 'transparent',
    strokeColor: '#888',
    trackSpacing: 0,
    hidden: [],
    hiddenParents: [],
}

const renderPipelineSVG = (_stages, opts = {}) => {
    let stages = clone(_stages)
    opts = { ...defaultOpts, ...opts }
    const {
        stepY,
        stepX,
        debug,
        backgroundColor,
        margin,
        strokeColor,
        hidden = [],
        hiddenParents = [],
    } = opts

    const convertToPipelineSymbol = (stages) => {
        const dfs = (stages) => {
            const newStages = stages.map((stage) => {
                if (stage.type === 'parallel') {
                    stage.stages = stage.stages.map((stages) => dfs(stages))
                }
                return stage
            })

            return {
                type: 'pipeline',
                stages: newStages,
            }
        }

        return dfs(stages)
    }

    const stageMap = {}

    const giveIds = (stages) => {
        let i = 0
        const dfs = (stage) => {
            if (typeof stage.id === 'undefined') {
                stage.id = i
                i += 1
            }

            stageMap[stage.id] = stage

            if (stage.type === 'parallel' || stage.type === 'pipeline') {
                stage.stages = stage.stages.map((stages) => dfs(stages))

                stage.stages[0].first = true
                stage.stages[stage.stages.length - 1].last = true
            }
            return stage
        }

        return dfs(stages)
    }

    const giveParents = (stages) => {
        let prev = null

        const dfs = (stage) => {
            if (stage.type !== 'dot') {
                prev = stage
            } else {
                stage.parent = prev ? prev.id : null
            }

            if (stage.type === 'parallel' || stage.type === 'pipeline') {
                stage.stages = stage.stages.map((stages) => dfs(stages))
            }

            return stage
        }

        return dfs(stages)
    }

    const removeHidden = (stages) => {
        const dfs = (stage) => {
            if (
                stage.hidden ||
                hidden.includes(stage.id) ||
                hiddenParents.includes(stage.parent)
            )
                return null
            if (stage.type === 'parallel' || stage.type === 'pipeline') {
                stage.stages = stage.stages
                    .map((stages) => dfs(stages))
                    .filter((a) => !!a)
            }
            return stage
        }

        return dfs(stages)
    }

    const calcOrder = (stages) => {
        const order = []

        const dfs = (stage) => {
            if (stage.type === 'parallel') {
                order.push(null) // before space for fan out
                stage.stages.map((stages) => dfs(stages))
                order.push(null) // after space for fan in
                return
            } else if (stage.type === 'pipeline') {
                stage.stages.map((stages) => dfs(stages))
            } else {
                order.push(stage.id)
            }
        }

        dfs(stages)

        return order
    }

    const dfs = (func) => {
        return (stage) => {
            if (Array.isArray(stage)) {
                stage.map(dfs(func))
                return
            } else if (stage.type === 'parallel' || stage.type === 'pipeline') {
                stage.stages.map(dfs(func))
            }

            func(stage)
        }
    }

    /* -------------------------------------------------------
            1. Preprocessing
    ------------------------------------------------------- */
    stages = removeHidden(giveParents(giveIds(convertToPipelineSymbol(stages))))
    console.log('stages', stages)
    const order = calcOrder(stages)

    /* -------------------------------------------------------
            1. Size Pass
    ------------------------------------------------------- */

    {
        // calculate heights and widths recursively
        dfs((stage) => {
            const type = types[stage.type]
            if (type.size) {
                type.size(stage, opts)
            } else {
                types.default.size(stage, opts)
            }
        })(stages)
    }

    let minX = Number.POSITIVE_INFINITY
    let minY = Number.POSITIVE_INFINITY
    let maxX = Number.NEGATIVE_INFINITY
    let maxY = Number.NEGATIVE_INFINITY

    /* -------------------------------------------------------
            LAYOUT PASS
    ------------------------------------------------------- */
    {
        // layout pass
        const dfsLayout = (x, y, stage) => {
            stage.x = x
            stage.y = y

            // Calculate the bounds of the layout
            minX = Math.min(minX, stage.x)
            minY = Math.min(minY, stage.y)
            maxX = Math.max(maxX, stage.x)
            maxY = Math.max(maxY, stage.y)

            const type = types[stage.type]
            if (type.layout) {
                type.layout(stage, dfsLayout, opts)
            }

            y += stepY * stage.height
        }

        dfsLayout(0, 0, stages)
    }

    const renderBackground = (stage) => {
        const type = types[stage.type]
        if (type.background) {
            return type.background(stage, renderBackground, opts)
        } else {
            return types.default.background(stage, renderBackground, opts)
        }
    }

    const render = (stage) => {
        const type = types[stage.type]
        if (type.render) {
            return type.render(stage, render, opts)
        } else {
            return types.default.render(stage, render, opts)
        }
    }

    const svgShapes = render(stages)
    const svgBackground = renderBackground(stages)

    const width = maxX - minX + 2 * margin
    const height = maxY - minY + 2 * margin

    // Transpose position
    const tx = -minX + margin
    const ty = -minY + margin

    const textOrder = debug
        ? order
              .map((a, i) => {
                  if (a === null) return ''
                  const x = (stages.width / 2) * stepX
                  const y = stageMap[a].y
                  return `<text style="stroke-width: 0; fill: #000;" x="${x}" y="${y}">${stageMap[a].type}</text>`
              })
              .join('\n')
        : ''

    const svg = `<svg height="${height}" width="${width}" xmlns="http://www.w3.org/2000/svg" style="background: ${backgroundColor}">
        <g transform="translate(${tx}, ${ty})" style="stroke:${strokeColor};stroke-width:4;">
        ${svgBackground}
        ${svgShapes}
        ${textOrder}
        ${
            debug
                ? '<rect x="-100%" y="-100%" width="200%" height="200%" fill="url(#grid)" stroke-width="0" />'
                : ''
        }
        </g>

        ${
            debug
                ? `<defs>
                <pattern id="tenthGrid" width="32" height="32" patternUnits="userSpaceOnUse">
                        <path d="M 32 0 L 0 0 0 32" fill="none" stroke="silver" stroke-width="0.5" />
                </pattern>
                <pattern id="grid" width="320" height="320" patternUnits="userSpaceOnUse">
                        <rect width="320" height="320" fill="url(#tenthGrid)" />
                        <path d="M 320 0 L 0 0 0 320" fill="none" stroke="gray" stroke-width="1" />
                </pattern>
        </defs>`
                : ''
        }
    </svg>`

    return {
        svg,
        height,
        width,
        order,
        stageMap,
    }
}

export { renderPipelineSVG }
