export default {
    render: ({ x = 0, y = 0, color = 'lime' }) => {
        const points = [
            [-8, -8],
            [8, -8],
            [0, 8],
        ]
        const relativePoints = points
            .map((point) => `${x + point[0]},${y + point[1]}`)
            .join(' ')
        return `<polygon points="${relativePoints}" style="fill:${color}" />`
    },
}
