import { Stack } from '@fluentui/react'
import React from 'react'

import Header from '../Header/Header.js'
import SidebarMenu from '../SidebarMenu.js'

const mainStyle = {
    flex: '1 1 0',
    display: 'flex',
    flexDirection: 'row',
}

const Page = ({ children, showSidebar = true }) => {
    const sidebar = showSidebar ? <SidebarMenu /> : null
    return (
        <>
            <Header />
            <Stack style={mainStyle}>
                {sidebar}
                <div id='content'>{children}</div>
            </Stack>
        </>
    )
}

export default Page
