import React from 'react'
import {
    DetailsList,
    Text,
    CommandBar,
    Stack,
    Persona,
    PersonaSize,
    Toggle,
    SelectionMode,
} from '@fluentui/react'
import { useParams, useNavigate } from 'react-router-dom'

import {
    getFieldsByQuery,
    getClausesByQuery,
    getTransactionDataCompleteById,
} from '../api'
import { useSearchParams } from '../hooks/useSearchParams'
import { formatShortDateTime } from '../lib/date'

import {
    pick,
    sortArray,
    sortBy,
    collectFromTo,
    collectIntoObject,
    collectIntoSet,
} from '../lib/utils'

import Page from '../components/layout/Page'
import ListFieldDiff from '../components/pageparts/ListFieldDiff'
import ListClauseDiff from '../components/pageparts/ListClauseDiff'
import LoadingSpinner from '../components/LoadingSpinner'

const sortByCreated = sortBy('created')
const pickField = pick('field')
const pickClause = pick('clause')
const pickClauseId = pick('clauseId')

const allSame = (arr) => {
    const s = new Set(arr.map((a) => JSON.stringify(a)))
    return s.size === 1
}

const renderHeader = (props, i) => {
    const { createdBy, created } = props
    const date = formatShortDateTime(created)
    return (
        <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign='center'>
            {createdBy ? (
                <Persona
                    text={`${createdBy.firstName} ${createdBy.lastName}`}
                    imageUrl={createdBy.avatar}
                    size={PersonaSize.size24}
                />
            ) : null}
            <Text variant='small'>{date}</Text>
        </Stack>
    )
}

const onRenderCell = (fieldSchema, clauseSchema, row, i) => {
    if (row.clauseRow) {
        return (
            <ListClauseDiff
                first={i === 0}
                dataVal={row.versions[i]}
                prevVal={row.versions[i - 1]}
                clauseSchema={clauseSchema[row.fieldId]}
            />
        )
    }
    return (
        <ListFieldDiff
            first={i === 0}
            dataVal={row.versions[i] ? row.versions[i][0] : ''}
            prevVal={row.versions[i - 1] ? row.versions[i - 1][0] : ''}
            fieldSchema={fieldSchema[row.fieldId]}
        />
    )
}

const CompareHistory = (props) => {
    const { transactionId } = useParams()
    const { versions = '' } = useSearchParams()
    const navigate = useNavigate()

    const [historyData, setHistoryData] = React.useState([])
    const [fieldSchema, setFieldSchema] = React.useState(null)
    const [clauseSchema, setClauseSchema] = React.useState(null)
    const [ignoreSame, setIgnoreSame] = React.useState(true)

    const versionsArray = React.useMemo(() => versions.split(','), [versions])

    // History Data
    React.useEffect(() => {
        if (!versionsArray.length) return

        Promise.all(
            versionsArray.map((id) => {
                const data = getTransactionDataCompleteById(id)
                return data
            }),
        )
            .then(sortArray(sortByCreated))
            .then(setHistoryData)
    }, [transactionId, versionsArray])

    // Calculate compressed data, allFields and allClauses
    const [allFields, allClauses, compressedData, clauseData, clauseIds] =
        React.useMemo(() => {
            const allFields = historyData
                .map((historyItem) => historyItem.fieldData.map(pickField))
                .reduce(collectIntoSet, new Set())

            const allClauses = historyData
                .map((historyItem) => historyItem.clauseData.map(pickClause))
                .reduce(collectIntoSet, new Set())

            const clauseIds = historyData
                .map((historyItem) => historyItem.clauseData.map(pickClauseId))
                .reduce(collectIntoSet, new Set())
            const data = {}
            const clauseData = {}
            const compressedData = historyData.map((historyData, i) => {
                historyData.fieldData.reduce(
                    collectFromTo('values', 'field'),
                    data,
                )
                historyData.clauseData.reduce(
                    collectFromTo('edited', 'clause'),
                    data,
                )

                historyData.clauseData.forEach((clauseItem) => {
                    if (!clauseData[clauseItem.clause]) {
                        clauseData[clauseItem.clause] = []
                    }

                    if (
                        JSON.stringify(data[clauseItem.field]) ===
                        JSON.stringify(clauseItem.values)
                    ) {
                        clauseData[clauseItem.clause][i] = true
                    } else {
                        clauseData[clauseItem.clause][i] = false
                    }
                })

                return Object.assign({}, data)
            })

            return [
                Array.from(allFields),
                Array.from(allClauses),
                compressedData,
                clauseData,
                Array.from(clauseIds),
            ]
        }, [historyData])

    const versionColumns = React.useMemo(() => {
        if (!fieldSchema || !clauseSchema) return []

        // Build columns dynamically for number of versions we are comparing
        return historyData.map((historyItem, i) => ({
            key: `version-${i}`,
            name: renderHeader(historyItem, i),
            minWidth: 150,
            maxWidth: 350,
            onRender: (row) => onRenderCell(fieldSchema, clauseSchema, row, i),
            isResizable: true,
        }))
    }, [historyData, fieldSchema, clauseSchema])

    // Load fields and clauses
    React.useEffect(() => {
        ;(async () => {
            const fields = await getFieldsByQuery({
                name: { $in: allFields },
            })
            const clauses = await getClausesByQuery({
                _id: { $in: clauseIds },
            })

            const fieldSchema = fields.reduce(collectIntoObject('name'), {})
            const clauseSchema = clauses.reduce(collectIntoObject('name'), {})

            setFieldSchema(fieldSchema)
            setClauseSchema(clauseSchema)
        })()
    }, [allFields, clauseIds])

    // Build columns
    const columns = [
        {
            key: 'fieldId',
            name: 'Field Id',
            fieldName: 'fieldId',
            minWidth: 25,
            maxWidth: 80,
            isResizable: true,
        },
        {
            key: 'fieldName',
            name: 'Field Name',
            fieldName: 'fieldName',
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
        },
        ...versionColumns,
    ]

    // Build up data
    const genFieldData = (field) => ({
        fieldId: field,
        fieldName: fieldSchema[field] ? fieldSchema[field].title : '-',
        versions: compressedData.map(pick(field)),
    })
    const genClauseData = (clause) => ({
        fieldId: clause,
        clauseRow: true,
        fieldName: clauseSchema[clause] ? clauseSchema[clause].title : '-',
        versions: clauseData[clause],
    })
    const data = React.useMemo(() => {
        if (!clauseSchema || !fieldSchema || !allFields || !allClauses) {
            return []
        }
        return allFields
            .map((field) => {
                // Calculate which rows in comare to show based on ignoreSame flag
                // If field values are unchanged, dont show them, but show their clauses
                // if they are edited.

                // Calc field data
                const fieldData = genFieldData(field)

                // Calc clause data
                const clauseData = []
                allClauses.forEach((clause) => {
                    if (
                        clauseSchema[clause] &&
                        clauseSchema[clause].fieldName === field
                    ) {
                        clauseData.push(genClauseData(clause))
                    }
                })

                const fieldValuesAllSame = allSame(fieldData.versions)
                const clauseDataFiltered = !ignoreSame
                    ? clauseData
                    : clauseData.filter(({ versions }) => !allSame(versions))

                let results = []
                if (ignoreSame) {
                    if (fieldValuesAllSame) {
                        results = [...clauseDataFiltered]
                    } else {
                        results = [fieldData, ...clauseDataFiltered]
                    }
                } else {
                    results = [fieldData, ...clauseDataFiltered]
                }

                console.log('Field data', field, fieldData, clauseData)

                return results
            })
            .filter((a) => !!a)
            .flat()
    }, [
        clauseSchema,
        fieldSchema,
        allFields,
        allClauses,
        compressedData,
        ignoreSame,
    ])

    const commands = [
        {
            key: 'back',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => navigate(-1),
        },
    ].filter((a) => !!a)

    const actionBar = <CommandBar items={commands} style={{ flexGrow: 1 }} />

    // Loading spinner
    if (!fieldSchema || !clauseSchema) {
        return (
            <Page>
                <LoadingSpinner />
            </Page>
        )
    }

    return (
        <Page>
            <Text variant='xxLarge'>
                Comparing {versionsArray.length} versions
            </Text>
            <Stack horizontal>
                {actionBar}
                <div>
                    <Toggle
                        inlineLabel
                        label='Ignore unchanged'
                        checked={ignoreSame}
                        onChange={() => setIgnoreSame(!ignoreSame)}
                    />
                </div>
            </Stack>
            <DetailsList
                items={data}
                columns={columns}
                selectionMode={SelectionMode.none}
            />
        </Page>
    )
}
export default CompareHistory
