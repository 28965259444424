import React from 'react'
import PropTypes from 'prop-types'

import MultiChoiceGroup from './MultiChoiceInput'
import SimpleListInput from './SimpleListInput'

const ListControl = (props) => {
    const { schema } = props

    return schema.multiple ? (
        <MultiChoiceGroup {...props} />
    ) : (
        <SimpleListInput {...props} />
    )
}

ListControl.propTypes = {
    schema: PropTypes.shape({
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['LIST']).isRequired,
        help: PropTypes.string,
        format: PropTypes.oneOf(['combo', 'choice']),
        default: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        mandatory: PropTypes.bool,
        required: PropTypes.bool,
        multiple: PropTypes.bool,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            }),
        ),
    }),
}

export default ListControl
