import React from 'react'
import { IconButton } from '@fluentui/react'

const FieldHelpButton = ({ id, onClick }) => (
    <IconButton
        id={id}
        iconProps={{ iconName: 'Info' }}
        tabIndex='-1'
        title='Help'
        ariaLabel='Help'
        onClick={onClick}
        styles={{ root: { marginBottom: -3 } }}
    />
)

export default FieldHelpButton
