import React from 'react'
const StateMachineContext = React.createContext({ blank: true })

function StateMachineProvider(props) {
    if (props.value) {
        console.log('SETTING SM', props.value)
        window.sm = props.value
    }

    return <StateMachineContext.Provider {...props} />
}

const useStateMachine = () => {
    return React.useContext(StateMachineContext)
}

export { StateMachineProvider, useStateMachine }
