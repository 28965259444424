const reduceTemplate = (template, values) => {
    if (Array.isArray(template)) {
        return template.map((t) => reduceTemplate(t, values)).filter((a) => !!a)
    }
    if (
        template.type === 'FIELD' &&
        typeof values[template.definitionId] === 'undefined'
    ) {
        return null
    }

    if (template.contents) {
        const contents = template.contents
            .map((t) => reduceTemplate(t, values))
            .filter((a) => !!a)
        if (!contents.length) {
            return null
        }
        return {
            ...template,
            contents,
        }
    }

    return template
}

export { reduceTemplate }
