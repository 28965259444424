import React from 'react'
// import { Stack, PrimaryButton, TextField } from '@fluentui/react'
import { Text } from '@fluentui/react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'

import { useValues } from '@/hooks/useValues'
import { useAuth } from '@/hooks/useAuth'
import { useSearchParams } from '@/hooks/useSearchParams'

const defaultLoggedInPage = '/dashboard'

import FullPage from '@/components/layout/FullPage'

import { PrimaryButton } from './Buttons'
import { Wrap, Heading, TextField } from './Utils'

const LoginPanel = () => {
    const { signup } = useAuth()
    const { after } = useSearchParams()
    const navigate = useNavigate()
    const { values, setValue } = useValues({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
    })
    const submit = (e) => {
        e.preventDefault()
        signup(values).then(() => {
            navigate(after || defaultLoggedInPage)
        })
    }
    return (
        <>
            <Wrap>
                <Heading>Sign up</Heading>
                <TextField
                    name='firstName'
                    label='First Name'
                    value={values.firstName}
                    onChange={setValue('firstName')}
                    autoComplete='given-name'
                    required
                />
                <TextField
                    name='lastName'
                    label='Last Name'
                    value={values.lastName}
                    onChange={setValue('lastName')}
                    autoComplete='family-name'
                    required
                />
                <TextField
                    name='email'
                    label='Email Address'
                    value={values.email}
                    onChange={setValue('email')}
                    autoComplete='username'
                    required
                />
                <TextField
                    name='password'
                    label='New Password'
                    type='password'
                    value={values.password}
                    onChange={setValue('password')}
                    authComplete='current-password'
                    required
                />
                <TextField
                    name='password2'
                    label='Confirm New Password'
                    type='password'
                    value={values.password2}
                    onChange={setValue('password2')}
                    authComplete='current-password'
                    required
                />
                <PrimaryButton onClick={submit}>Sign up</PrimaryButton>
            </Wrap>
            <hr />
            <Wrap
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    flexDirection: 'row',
                }}>
                <Text>
                    Already have an account? <Link to='/login'>Log in now</Link>
                </Text>
            </Wrap>
        </>
    )
}

const Login = (props) => {
    return (
        <FullPage background='url("https://source.unsplash.com/1600x900/?building")'>
            <LoginPanel />
        </FullPage>
    )
}

export default Login
