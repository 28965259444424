import { authGet, getData } from '../common'

export const getLatestSchema = async (entityTypeName) => {
    const q = { name: entityTypeName }
    const [entityType] = await authGet({
        url: `/entityType?q=${JSON.stringify(q)}`,
    }).then(getData)

    const entityTypeFull = await authGet({
        url: `/entityType/${entityType._id}`,
    }).then(getData)

    const schema = {}

    entityTypeFull.fields.forEach((field) => {
        schema[field.name] = field
    })

    return schema
}

export const getEntityTypes = async (entityTypeId) => {
    return authGet({
        url: `/entityType`,
    }).then(getData)
}
export const getEntityType = async (entityTypeId) => {
    const entityType = await authGet({
        url: `/entityType/${entityTypeId}`,
    }).then(getData)

    return entityType
}

export const getEntityTypeSchema = async (entityTypeId) => {
    const entityType = await authGet({
        url: `/entityType/${entityTypeId}`,
    }).then(getData)

    const schema = {}

    entityType.fields.forEach((field) => {
        schema[field.name] = field
    })

    return { schema, entityType }
}

export const getActionsByEntityId = async (entityId) => {
    const entity = await authGet({
        url: `/entityType/${entityId}`,
    }).then(getData)

    const q2 = {
        'stages.0.kind': 'ingress',
        'stages.0.source': entity.name,
    }
    return authGet({
        url: `/workflow?q=${JSON.stringify(q2)}`,
    }).then(getData)
}
