import React from 'react'

import { Modal, Stack, Text } from '@fluentui/react'
import { fetchSiteInfo, fetchSiteVersion } from '../api'

const style = {
    fontSize: 12,
    borderTop: '1px solid #eee',
    padding: '4px 8px',
    color: '#747474',
    cursor: 'pointer',
}

const siteInfoStyle = {
    background: '#dedede',
    padding: '0.5em 1em',
    borderRadius: 8,
}

const year = new Date().getFullYear()

const Footer = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [siteInfo, setSiteInfo] = React.useState(null)
    const [version, setVersion] = React.useState('-')

    const onClick = () => {
        setIsOpen(true)
        fetchSiteInfo().then((siteInfo) => {
            console.log('Site Info', siteInfo)
            setSiteInfo(siteInfo)
        })
    }

    React.useEffect(() => {
        fetchSiteVersion().then((version) => setVersion(version))
    }, [setVersion])

    return (
        <>
            <div style={style}>
                leasle <span onClick={onClick}>v{version}</span> &copy;
                Copyright leasle Limited {year}
            </div>

            <Modal
                isOpen={isOpen}
                style={{ padding: 50 }}
                onDismiss={() => {
                    setIsOpen(false)
                }}
                isBlocking={false}>
                <Stack
                    horizontalAlign='center'
                    style={{ padding: '1em' }}
                    tokens={{ childrenGap: '1em' }}>
                    <Text variant='xxLarge'>Site Info</Text>
                    <pre style={siteInfoStyle}>{siteInfo}</pre>
                </Stack>
            </Modal>
        </>
    )
}

export default Footer
