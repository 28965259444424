import React from 'react'

import { commentTransaction } from '../../api'
import {
    Text,
    Stack,
    PrimaryButton,
    Persona,
    PersonaSize,
    Panel,
    PanelType,
    TextField,
    Icon,
} from '@fluentui/react'

import { useBoolean } from '@fluentui/react-hooks'
import { formatShortDateTime } from '../../lib/date'

const CommentsForm = ({ transaction, reload = false }) => {
    const [comment, setComment] = React.useState('')

    const onSubmit = async (e) => {
        e.preventDefault()
        console.log('onSubmit')
        if (!comment) return false
        await commentTransaction(transaction._id, {
            comment,
        })
        if (reload) {
            await reload()
        }
        setComment('')
        return false
    }

    return (
        <form onSubmit={onSubmit}>
            <Stack
                horizontal
                style={{ width: '100%' }}
                tokens={{ childrenGap: 10 }}>
                <TextField
                    styles={{ root: { flexGrow: 1 } }}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <PrimaryButton
                    iconProps={{ iconName: 'Send' }}
                    onClick={onSubmit}>
                    Post
                </PrimaryButton>
            </Stack>
        </form>
    )
}

const Comments = ({ transaction = {}, comments = [], reload = false }) => {
    const [isOpen, { toggle: toggleOpen }] = useBoolean(false)

    return (
        <>
            <Icon
                iconName='Comment'
                style={{
                    width: 32,
                    height: 32,
                    fontSize: 32,
                    cursor: 'pointer',
                }}
                onClick={toggleOpen}
            />
            <Panel
                isLightDismiss
                headerText='Comments'
                isOpen={isOpen}
                onDismiss={toggleOpen}
                type={PanelType.medium}
                isFooterAtBottom={true}>
                <Stack tokens={{ childrenGap: 10 }} style={{ paddingTop: 10 }}>
                    <CommentsForm transaction={transaction} reload={reload} />
                    {comments.map((comment, i) => (
                        <Comment key={i} comment={comment} />
                    ))}
                </Stack>
            </Panel>
        </>
    )
}

const Comment = ({ comment }) => {
    return (
        <Stack
            vertical
            tokens={{ childrenGap: 10 }}
            style={{
                borderBottom: '2px solid #eee',
                paddingBottom: 10,
                marginBottom: 5,
            }}>
            <Stack horizontal verticalAlign='center'>
                <Persona
                    text={`${comment.user.firstName} ${comment.user.lastName}`}
                    size={PersonaSize.size40}
                    secondaryText={formatShortDateTime(comment.created)}
                />
            </Stack>
            <Text block>{comment.data.comment}</Text>
        </Stack>
    )
}

export default Comments
