const getTeamForRole = (workflow, role) => {
    if (!workflow.teams) return '-'
    const team = workflow.teams.find((team) => team.roles.includes(role))
    return team ? team.title : '-'
}

const getRolesData = (transaction) => {
    const assignedRoles = []
    const transactionRoles = transaction.roles.map((role) => {
        assignedRoles.push(role.name)
        return {
            role: role.name,
            user: {
                ...role.user,
            },
            email: role.user.email,
            team: getTeamForRole(transaction.workflow, role.name),
            access: role.access || 'standard',
            status: 'active',
            since: role.created,
            restrict: role.restrict || null,
            active: transaction.currentRole === role.name,
        }
    })

    const workflowRoles = transaction.workflow.roles
        .filter((role) => !assignedRoles.includes(role.name))
        .map((role) => {
            return {
                role: role.name,
                team: getTeamForRole(transaction.workflow, role.name),
                status: 'unassigned',
                restrict: role.restrict || null,
            }
        })

    const combinedRoles = [...transactionRoles, ...workflowRoles].sort(
        (a, b) => a.role < b.role,
    )

    return combinedRoles
}

export { getRolesData }
