import { TextField } from '@fluentui/react'
import React from 'react'

const noop = () => {}

const Filters = ({ onChange = noop, value = '' }) => {
    const [_value, setValue] = React.useState(value || '')
    const _onChange = React.useCallback((e) => {
        setValue(e.target.value)
        onChange(e.target.value)
    })
    return (
        <TextField
            placeholder='Search'
            iconProps={{ iconName: 'Search' }}
            onChange={_onChange}
            value={_value}
        />
    )
}

export default Filters
