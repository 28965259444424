import React from 'react'
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons'
import { Stack, Icon, Text } from '@fluentui/react'

const File = ({ filename, extension = 'txt' }) => {
    return (
        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
            <Icon
                {...getFileTypeIconProps({
                    extension,
                    size: 24,
                    imageFileType: 'png',
                })}
            />
            <Text>{filename}</Text>
        </Stack>
    )
}

export default File
