import React from 'react'
import PropTypes from 'prop-types'
import { DefaultButton, TextField } from '@fluentui/react'

import { registerSingle, makeLabel, registerMulti2 } from './utils.js'

const TextInput = ({ schema, readonly = false }) => {
    if (schema.type !== 'TEXT') {
        throw new Error('TextInput given incorrect schema type')
    }
    const { name, required, title, calc, multiple = false } = schema

    const { onChange, value, errorMessage, def, onDeleteItem } = !multiple
        ? registerSingle(name)
        : registerMulti2(name)

    // console.log('Schema', schema, def, value, multiple)
    // Don't allow calculated fields to be edited
    if (calc) {
        // console.log('CALC', name)
        readonly = true
    }

    const onRenderLabel = makeLabel(schema)

    const opts = {
        name,
        required,
        label: title,
        value,
        errorMessage,
        onRenderLabel,
        onChange,
        readOnly: readonly,
        style: !calc ? { background: 'papayawhip !important' } : {},
    }

    if (schema.maxLength && schema.maxLength <= 185) {
        Object.assign(opts, {
            styles: {
                fieldGroup: { maxWidth: 25 + schema.maxLength * 9 },
            },
        })
    }

    if (multiple) {
        const fields = []
        for (let i = 0; i < value.length; i += 1) {
            const multiOpts = {
                ...opts,
                onRenderLabel: i === 0 ? opts.onRenderLabel : () => {},
                value: opts.value[i],
                onChange: (e, v) => {
                    opts.onChange(e, i, v)
                },
            }
            fields.push(
                <div key={i}>
                    <TextField {...multiOpts} />
                    <DefaultButton
                        onClick={() => {
                            onDeleteItem(i)
                        }}>
                        x
                    </DefaultButton>
                </div>,
            )
        }

        return (
            <>
                {fields}
                {readonly ? null : (
                    <DefaultButton
                        onClick={() => {
                            opts.onChange(null, value.length, '')
                        }}>
                        +
                    </DefaultButton>
                )}
            </>
        )
    }

    return <TextField {...opts} />
}

TextInput.propTypes = {
    schema: PropTypes.any,
}

export default TextInput
