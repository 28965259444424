import React, { useEffect, useState } from 'react'

import {
    getEntity,
    getTemplateByName,
    updateEntity,
    triggerWorkflowAndParams,
    getActionsByEntityName,
    getEntityType,
    getOverrides,
    createEntity,
} from '../api'

// Hooks
import { useStateMachine } from './useStateMachine.js'
import { useForm } from '../components/controls/useForm'
import { useSearchParams } from '../hooks/useSearchParams'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import useCtrlS from './useCtrlS.js'
import { collectIntoObject } from '../lib/utils.js'

const useTemplate = (templateName, entityId) => {
    const sm = useStateMachine()
    const { onChangeParams, resetParams } = useForm()
    const navigate = useNavigate()
    const { readonly: readonlyParam = 'false' } = useSearchParams()
    useCtrlS(() => {
        handleSubmit()
    })

    const [template, setTemplate] = useState(null)
    const [entity, setEntity] = useState(null)
    const [workflows, setWorkflows] = useState([])
    const [entityType, setEntityType] = useState(null)
    const [ready, setReady] = useState(false)
    const [error, setError] = useState(null)
    const [saveError, setSaveError] = useState(null)
    const [isDirty, setIsDirty] = useState(false)

    const readonly = Boolean(readonlyParam === 'true')
    const isCreate = !entity

    const update = useCallback(() => {
        console.log('sm.isDirty()', sm.isDirty())
        setIsDirty(sm.isDirty())
        setSaveError(null)
    }, [sm])

    useEffect(() => {
        sm.state.ee.on('errors', update)
        return () => {
            sm.state.ee.off('errors', update)
        }
    }, [sm, update])

    const handleCreateEntity = useCallback(async () => {
        const now = Date.now()
        sm.getSavePayload()
            .then(async (payload) => {
                const entityPayload = {
                    name: now,
                    title: `Entity ${now}`,
                    domain: template.domain,
                    entityType: template.entityType,
                    data: payload.fieldData,
                }

                const entity = await createEntity(entityPayload)

                navigate(`/template/${templateName}/${entity._id}`)
            })
            .catch((err) => {
                console.log('ERR', err)
                if (err.message === 'Outstanding errors') {
                    setSaveError(err.message)
                } else {
                    throw err
                }
            })
    }, [sm, template, templateName, createEntity])

    const handleUpdateEntity = useCallback(async () => {
        sm.getSavePayload(false)
            .then(async (payload) => {
                await updateEntity(entity._id, { data: payload.fieldData })
                sm.clearDirty()
            })
            .catch((err) => {
                console.log('ERR', err)
                if (err.message === 'Outstanding errors') {
                    setSaveError(err.message)
                } else {
                    throw err
                }
            })
    }, [sm, entity, updateEntity])

    const handleSubmit = useCallback(async () => {
        if (isCreate) {
            handleCreateEntity()
        } else {
            handleUpdateEntity()
        }
    }, [isCreate, entity, handleCreateEntity, handleUpdateEntity])

    // Load template
    useEffect(() => {
        ;(async () => {
            setReady(false)
            setError(null)
            const template = await getTemplateByName(templateName).catch(
                (err) => {
                    console.log('TEMPLATE ERROR', err)
                },
            )
            const overrides = await getOverrides({
                template: template._id,
            })

            resetParams()
            setTemplate(template)
            if (template && template.templateError) {
                return
            }

            let transactionData = []

            if (entityId) {
                try {
                    const entity = await getEntity(entityId)
                    setEntity(entity)

                    transactionData = { fieldData: entity.data }
                    // entity.data.forEach((item) => {
                    //     // @TODO: mark not dirty
                    //     sm.setFieldValue(item.field, item.values)
                    // })
                    console.log('CLEARING DIRTY', entity)

                    if (entity.entityType) {
                        // sm.state.clearDirty()
                        const entityType = await getEntityType(
                            entity.entityType._id,
                        )
                        // Load entity actions
                        const workflows = await getActionsByEntityName(
                            entityType.name,
                        )

                        setEntityType(entityType)
                        setWorkflows(workflows)

                        onChangeParams(entityType.name, entity._id)
                    }
                } catch (err) {
                    console.log('Error', err)
                    setError({
                        title: 'Error loading entity',
                        msg: err.response.data.error,
                    })
                    return
                }
            } else {
                setReady(true)
                setEntity(null)
            }

            sm.initData({
                template,
                transactionData,
                overrides: overrides.items,
            })

            // If we don't have an entity id, clear data
            if (!entityId) {
                // setValues({})
                return
            }

            setReady(true)
        })()
    }, [
        templateName,
        setTemplate,
        entityId,
        setEntity,
        setWorkflows,
        setEntityType,
        onChangeParams,
    ])

    const fields = template
        ? template.fields.reduce(collectIntoObject('name'), {})
        : null

    const actions = workflows ? workflows.map(workflowToAction) : null

    return {
        template,
        error,
        ready,
        entity,
        fields,
        actions,
        entityType,
        isDirty,
        saveError,
        isCreate,
        handleSubmit,
        readonly,
    }
}

const workflowToAction = (workflow) => ({
    workflow: workflow._id,
    key: workflow._id,
    text: workflow.title,
})

export default useTemplate
