import React from 'react'
import { Stack } from '@fluentui/react'
import RecordTable from '../pageparts/RecordTable'
import {
    getEntitiesByTypeName,
    getActionsByEntityName,
    triggerWorkflowAndParams,
} from '../../api'

import { useForm } from '../controls/useForm'
import { useNavigate } from 'react-router-dom'
import Filters from './Filters'
import ActionBar from './ActionBar'
import LoadingSpinner from '../LoadingSpinner'
import { usePermissions } from '../../hooks/usePermissions'
import { useStateMachine } from '../../hooks/useStateMachine'

const processFilter = (filter, params, data) => {
    if (!filter) return { filter: null, pass: true }
    const processedFilter = { ...filter }

    for (const field of Object.keys(processedFilter)) {
        // if (!filter[field]) return
        if (typeof processedFilter[field] !== 'string') continue
        const match = processedFilter[field].match(/^{([a-z0-9_]+)}$/i)
        if (match) {
            const key = match[1]
            // If we are missing some param for the filter
            // stop processing filter
            if (typeof params[key] !== 'undefined') {
                processedFilter[field] = params[key]
            } else if (typeof data[key] !== 'undefined') {
                processedFilter[field] = data[key]
            } else {
                return { filter: null, pass: false }
            }
        }
    }

    return { filter: processedFilter, pass: true }
}

const ListTemplate = ({
    checkPermissions = false,
    // Template is only set sometimes, dont rely on it.
    template = null,
    content,
    fields,
    root = false,
    onItemInvoke = null,
}) => {
    const navigate = useNavigate()
    const sm = useStateMachine()
    const [loading, setLoading] = React.useState(false)
    const [records, setRecords] = React.useState([])
    const [workflows, setWorkflows] = React.useState([])
    const [search, setSearch] = React.useState('')
    const [selection, setSelection] = React.useState(null)
    const { onChangeParams, params } = useForm()
    const { hasPermissions } = usePermissions()

    const hasView = hasPermissions([
        'getOne_entity',
        `getOne_entity:${content.source}`,
    ])
    const hasCreate = hasPermissions([
        'create_entity',
        `create_entity:${content.source}`,
    ])
    const hasEdit = hasPermissions([
        'update_entity',
        `update_entity:${content.source}`,
    ])

    const update = React.useCallback(async () => {
        const data = sm.state.castData()
        console.log('DATA', data)
        const { filter, pass } = processFilter(content.filter, params, data)
        if (!pass) {
            console.warn('Missing param for list template filter')
            setRecords([])
            setWorkflows([])
            return
        }

        setLoading(true)

        const records = await getEntitiesByTypeName(content.source, filter)
        setRecords(records)

        const workflows = await getActionsByEntityName(content.source)
        setWorkflows(workflows)

        setLoading(false)
    }, [content])

    React.useEffect(() => {
        sm.ee.on('errors', update)
        return () => sm.ee.off('errors', update)
    }, [sm, update])

    React.useEffect(() => {
        ;(async () => {
            update()
        })()
    }, [content])

    // if (loading) {
    //     return <LoadingSpinner />
    // }

    const linkTarget = content.createLink
        ? `/template/${content.createLink}`
        : null

    const onSelectionChanged = root
        ? (id) => {
              setSelection(id)
          }
        : (id) => {
              onChangeParams(content.source, id)
          }

    const actions = root
        ? workflows.map((workflow) => {
              return {
                  workflow: workflow._id,
                  key: workflow._id,
                  text: workflow.title,
              }
          })
        : []

    const onClickAction = async (id, action) => {
        const transaction = await triggerWorkflowAndParams(
            action.workflow,
            content.source,
            id,
        )

        navigate(`/transactions/${transaction._id}`)
    }

    const onSearch = (value) => {
        setSearch(value)
    }

    const showProps = {
        showView: hasView,
        showAdd: hasCreate && !!linkTarget,
        showEdit: hasEdit && !!linkTarget,
    }

    const actionProps = {
        actions,
        onClickBack: () => {
            navigate(-1)
        },
        onClickView: () => {
            if (onItemInvoke) {
                onItemInvoke()
            } else if (root) {
                navigate(linkTarget + '/' + selection + '?readonly=true')
            } else {
                navigate(
                    linkTarget +
                        '/' +
                        params[content.source] +
                        '?readonly=true',
                )
            }
        },
        onClickAdd: () => {
            navigate(linkTarget)
        },
        onClickEdit: () => {
            navigate(linkTarget + '/' + selection)
        },
        onClickTransactions: () => {
            navigate(
                `/transactions?entity=${selection}&entityType=${content.source}`,
            )
        },
        onClickCreate: () => {
            navigate(
                '/createtransaction?entity=' +
                    selection +
                    '&entityType=' +
                    content.source,
            )
        },
        onClickCreateAction: (action) => {
            // navigate('/createtransaction?entity=' + selection)
            onClickAction(selection, action)
        },
        onClickArchive: () => {
            // @TODO: delete entity
        },
    }

    const actionBar = root ? (
        <ActionBar
            entity={selection}
            {...showProps}
            {...actionProps}
            style={{ flexGrow: 1 }}
        />
    ) : null

    return (
        <div>
            <Stack horizontal>
                {actionBar}
                <Filters onChange={onSearch} value={search} />
            </Stack>
            <RecordTable
                loading={loading}
                records={records}
                fields={fields}
                listColumns={content.fields}
                linkTarget={linkTarget}
                onSelectionChanged={onSelectionChanged}
                onClickAction={onClickAction}
                onItemInvoked={actionProps.onClickView}
                search={search}
            />
        </div>
    )
}

export default ListTemplate
