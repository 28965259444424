import React from 'react'
import { Text, Stack } from '@fluentui/react'
import Page from '../../components/layout/Page'
import { useNavigate } from 'react-router-dom'
import { getAccounts } from '../../api'

const Accounts = (props) => {
    const [accounts, setAccounts] = React.useState([])
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        setLoading(true)
        getAccounts().then((accounts) => {
            setAccounts(accounts)
            setLoading(false)
        })
    }, [setAccounts, setLoading])

    return (
        <Page>
            <Text variant='xxLarge'>Accounts</Text>
            <Stack tokens={{ childrenGap: 20 }} horizontal wrap>
                {accounts.map((account, i) => {
                    return <AccountPanel account={account} key={i} />
                })}
            </Stack>
            <pre>{JSON.stringify(accounts, null, 4)}</pre>
        </Page>
    )
}

const accountPanelStyle = {
    // background: 'red',
    width: '20%',
    border: '2px solid #ccc',
    borderRadius: 8,
    padding: '1em 1em',
}

const AccountPanel = ({ account = null }) => {
    const navigate = useNavigate()
    const onClick = () => {
        navigate(`/admin/account/${account._id}`)
    }
    if (!account) return null

    return (
        <div style={accountPanelStyle} onClick={onClick}>
            <Text variant='large'>{account.name}</Text>
            <p>
                Created: <span>{account.created}</span>
            </p>
            <p>
                Updated: <span>{account.updated}</span>
            </p>
        </div>
    )
}

export default Accounts
