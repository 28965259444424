import React from 'react'
import { useStateMachine } from '../../hooks/useStateMachine.js'
import ejs from 'ejs'

console.log('ejs', ejs)

const HTML = ({ innerHTML = '' }) => {
    return <div dangerouslySetInnerHTML={{ __html: innerHTML }}></div>
}

const HTMLTemplate = ({ content }) => {
    const sm = useStateMachine()
    const [newContent, setNewContent] = React.useState('')
    const [fields, setFields] = React.useState([])

    // const fields = []
    const updateHTML = () => {
        // console.log('Update HTML')
        const [tmpNewContent, newFields] = replaceFieldPlaceholdersHTMLTemplate(
            content,
            (field) => {
                const values = sm.getFieldValues(field)
                const fieldDef = sm.getFieldDef(field)
                if (!fieldDef) {
                    console.warn(
                        `Cannot find fieldDef for HTML render`,
                        field,
                        values,
                    )
                    return ''
                } else {
                    return fieldDef.render(values) || ''
                }
            },
        )

        // EJS render
        const data = sm.state.castData()
        const fullyRenderedContent = ejs.render(tmpNewContent, data, {})

        setNewContent(fullyRenderedContent)
        setFields(newFields)
    }

    React.useMemo(() => {
        updateHTML()
    }, [setNewContent, content, setFields])

    const onChange = (event, value, errors) => {
        if (event.match(/change:.*/)) {
            const field = event.replace('change:', '')
            if (fields.includes(field)) {
                updateHTML()
            }
        }
    }

    React.useEffect(() => {
        sm.state.ee.on('*', onChange)
        return () => sm.state.ee.off('*', onChange)
    })

    return <HTML innerHTML={newContent} />
}

const replaceFieldPlaceholdersHTMLTemplate = (content, getDisplayValue) => {
    let tmpNewContent = content
    const matches = tmpNewContent.match(/(\$[A-Z0-9]+)/g)
    // console.log('matches', matches)
    const newFields = []
    if (matches) {
        for (const match of matches) {
            const field = match.replace('$', '')
            newFields.push(field)
            const displayValue = getDisplayValue(field)
            tmpNewContent = tmpNewContent.replace(match, displayValue || '')
        }
    }

    return [tmpNewContent, newFields]
}

export default HTMLTemplate
