import React from 'react'

import {
    Modal,
    Stack,
    Dropdown,
    IconButton,
    PrimaryButton,
    DefaultButton,
    TextField,
} from '@fluentui/react'

import { addUserAccount } from '../../../api'
import { useAuth } from '@/hooks/useAuth'

const AddUserModal = ({
    isOpen,
    onClose = () => {},
    accountId = null,
    loadData = () => {},
}) => {
    const title = 'Add User'
    const [data, setData] = React.useState({ email: '' })

    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setData((data) => {
            return Object.assign({}, data, { [name]: value })
        })
    }

    const onAdd = () => {
        console.log('DATA', data)
        addUserAccount(accountId, data).then((res) => {
            console.log('RES', res)
            setData({})
            loadData()
            onClose()
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em' }}
                tokens={{ childrenGap: '1em' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        // styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </div>
                <p>
                    Please enter the email of the user you would like to add to
                    your account.
                </p>
                <TextField
                    name='email'
                    label='Email'
                    required
                    styles={{ root: { width: '100%' } }}
                    value={data.email}
                    onChange={onChange}
                />
                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='end'>
                    <PrimaryButton onClick={onAdd}>Add</PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AddUserModal
