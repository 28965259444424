import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@fluentui/react'

import '../_styles/tabset.css'

const styles = {
    tabSet: { margin: 0 },
    tabList: {
        borderBottom: '1px solid #ccc',
        paddingLeft: 0,
    },
    tabListItem: {
        display: 'inline-block',
        listStyle: 'none',
        marginBottom: -1,
        padding: '0.5rem 0.75rem',
        cursor: 'pointer',
    },
    tabListItemActive: {
        backgroundColor: 'white',
        border: 'solid #ccc',
        borderWidth: '1px 1px 0 1px',
        borderRadius: '5px 5px 0 0',
        color: '#ec008c',
        fontWeight: 'bold',
    },
}

const TabSet = ({ children, onTabClick = () => true, active = false }) => {
    if (!Array.isArray(children)) {
        children = [children]
    }
    children = children.filter((a) => !!a)
    const [firstChild] = children
    const [activeTab, setActiveTab] = React.useState(
        active ? active : firstChild.props.label,
    )

    const availableLabels = children.map((child) => child.props.label)

    // Set active tab to the first tab if active tab label not
    // in available labels
    if (!availableLabels.includes(activeTab)) {
        if (onTabClick(firstChild.props.label)) {
            setActiveTab(firstChild.props.label)
        }
    }

    if (active && active !== activeTab) {
        setActiveTab(active)
    }

    const onClickTabItem = (tab) => {
        if (onTabClick(tab)) {
            setActiveTab(tab)
        }
    }

    const tabItem = (child) => {
        const { label, badge: badge } = child.props
        return (
            <TabItem
                badge={badge}
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
            />
        )
    }

    const activeTabContent = () => {
        return (
            children
                .filter((child) => child.props.label === activeTab)
                .map((child) =>
                    child.props.children
                        ? React.cloneElement(child.props.children, {
                              key: activeTab,
                          })
                        : null,
                ) || <p>Missing</p>
        )
    }

    return (
        <Stack style={styles.tabSet}>
            <ol style={styles.tabList}>{children.map(tabItem)}</ol>
            <div className='tab-content'>{activeTabContent()}</div>
        </Stack>
    )
}

const TabItem = ({ label, onClick, activeTab, badge = null }) => {
    const isActive = activeTab === label

    const style = Object.assign(
        {},
        styles.tabListItem,
        isActive ? styles.tabListItemActive : {},
    )

    return (
        <li
            className='hover-color-pink'
            style={style}
            onClick={() => onClick(label)}>
            {label}
            {badge ? <ErrorBadge text={badge} /> : null}
        </li>
    )
}

const errorBadgeStyle = {
    marginLeft: 5,
    background: 'red',
    color: 'white',
    display: 'inline-block',
    width: 22,
    height: 22,
    textAlign: 'center',
    borderRadius: 11,
}

const ErrorBadge = ({ text }) => {
    return <span style={errorBadgeStyle}>{text}</span>
}

TabItem.propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default TabSet
