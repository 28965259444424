import React, { useState } from 'react'
// import { Stack, PrimaryButton, TextField } from '@fluentui/react'
import { Text } from '@fluentui/react'
import { Link } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'

import { getSetting } from '@/api/config'
import { useValues } from '@/hooks/useValues'
import { useAuth } from '@/hooks/useAuth'
import { useSearchParams } from '@/hooks/useSearchParams'

const defaultLoggedInPage = '/dashboard'

const API_BASE = getSetting('API_BASE')

import FullPage from '@/components/layout/FullPage'

import { Button, PrimaryButton } from './Buttons'
import { Wrap, Heading, TextField } from './Utils'

const LoginPanel = () => {
    const { login } = useAuth()
    const { after, email: prefilledEmail = '' } = useSearchParams()
    const navigate = useNavigate()
    const [error, setError] = React.useState(null)
    const { values, setValue } = useValues({
        email: prefilledEmail,
        password: '',
    })
    const { email, password } = values
    const loginSSO = (e) => {
        e.preventDefault()
        console.log('LOGIN SSO')
        window.location = `${API_BASE}/login-sso`
    }

    const [loading, setLoading] = useState(false)
    const submit = (e) => {
        e.preventDefault()
        setLoading(true)
        login(email, password)
            .then(() => {
                navigate(after || defaultLoggedInPage)
            })
            .catch((err) => {
                setError(err.toString())
            })
            .finally(setLoading(false))
    }
    return (
        <>
            <Wrap>
                <Heading>Sign in with your social account</Heading>
                <Button icon='/images/microsoft_icon.svg' onClick={loginSSO}>
                    Microsoft
                </Button>
                <Button icon='/images/google_icon.svg' onClick={loginSSO}>
                    Google
                </Button>
                <Button icon='/images/linkedin_icon.svg' onClick={loginSSO}>
                    Linked In
                </Button>
            </Wrap>
            <hr />
            <form onSubmit={submit}>
                <Wrap>
                    <Heading>Sign in with your existing account</Heading>

                    <TextField
                        name='email'
                        label='Email Address'
                        required
                        value={email}
                        onChange={setValue('email')}
                        autoComplete='username'
                    />
                    <TextField
                        name='password'
                        type='password'
                        label='Password'
                        required
                        value={password}
                        onChange={setValue('password')}
                        authComplete='current-password'
                    />
                    <PrimaryButton onClick={submit} disabled={loading}>
                        Sign in
                    </PrimaryButton>
                    {error ? (
                        <div>
                            <p style={{ color: 'red' }}>
                                Error logging in, please check password and try
                                again.
                            </p>
                            <p>
                                <small>{error}</small>
                            </p>
                        </div>
                    ) : null}
                </Wrap>
            </form>
            <hr />
            <Wrap
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    flexDirection: 'row',
                }}>
                <Text>
                    Don&apos;t have an account?{' '}
                    <Link to='/signup'>Sign up now</Link>
                </Text>
            </Wrap>
        </>
    )
}

const Login = (props) => {
    return (
        <FullPage background='url("https://source.unsplash.com/1600x900/?building")'>
            <LoginPanel />
        </FullPage>
    )
}

export default Login
