import { authGet, authPost, getData } from '../common'
import { getTransaction, updateTransactionParams } from './transaction'

export const getActionsByEntityName = (entityName) => {
    const q = {
        'stages.0.kind': 'ingress',
        'stages.0.source': entityName,
    }
    return authGet({
        url: `/workflow?q=${JSON.stringify(q)}`,
    }).then(getData)
}

export const triggerWorkflow = (workflowId) =>
    authPost({ url: `/workflow/${workflowId}/trigger` }).then(getData)

export const triggerWorkflowAndParams = async (
    workflowId,
    entityName,
    entityId,
) => {
    const transaction = await authPost({
        url: `/workflow/${workflowId}/trigger`,
    }).then(getData)

    await updateTransactionParams(transaction._id, { [entityName]: entityId })

    return getTransaction(transaction._id)
}

export const getWorkflows = () => authGet({ url: `/workflow` }).then(getData)
