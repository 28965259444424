import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'

import { Stack, Nav } from '@fluentui/react'

import Page from '../../components/layout/Page'
import { useAuth } from '../../hooks/useAuth'
import { patchProfile, mergeAuthState, clearAuthState } from '../../api'

import { UserGroupsSection } from './UserGroupsSection'
import { ProfileSection } from './ProfileSection'
import { PasswordSection } from './PasswordSection'
import { SettingsSection } from './SettingsSection'
import { GroupsSection } from './admin/GroupsSection'
import { GroupSection } from './admin/GroupSection'
import { AccountsSection } from './AccountsSection'
import { PermissionsSection } from './PermissionsSection'
import { ApiSection } from './ApiSection'

const Profile = () => {
    const { section = 'profile', subSection = null } = useParams()
    const auth = useAuth()

    const isAdmin =
        auth.roles.includes('admin') || auth.roles.includes('superadmin')

    const onClickSave = (payload) => {
        return patchProfile(auth.userId, payload).then(() => {
            // debugger
            if (payload.password) {
                // If we've changed password, kill this session
                // and force re-login
                clearAuthState()
                window.location = '/login'
            } else {
                mergeAuthState(payload)
                window.location.reload()
            }
        })
    }

    const navigate = useNavigate()
    const onClickNav = (e, item) => {
        e.preventDefault()
        navigate(item.url)
    }

    const content = useMemo(() => {
        if (section === 'profile') {
            return <ProfileSection onClickSave={onClickSave} />
        } else if (section === 'password') {
            return <PasswordSection onClickSave={onClickSave} />
        } else if (section === 'settings') {
            return <SettingsSection />
        } else if (section === 'user-groups') {
            return <UserGroupsSection />
        } else if (section === 'permissions') {
            return <PermissionsSection />
        } else if (section === 'accounts') {
            return <AccountsSection />
        } else if (section === 'groups' && subSection) {
            return <GroupSection />
        } else if (section === 'groups') {
            return <GroupsSection />
        } else if (section === 'api') {
            return <ApiSection />
        }
        return null
    }, [section, onClickSave])

    return (
        <Page>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Nav
                    onRenderGroupHeader={_onRenderGroupHeader}
                    groups={navGroups(isAdmin)}
                    styles={navStyles}
                    onLinkClick={onClickNav}
                />
                <Stack tokens={{ childrenGap: 10 }}>{content}</Stack>
            </Stack>
        </Page>
    )
}

function _onRenderGroupHeader(group) {
    return <h3>{group.name}AAA</h3>
}

const navGroups = (isAdmin) => [
    {
        links: [
            {
                name: 'User',
                isExpanded: true,
                links: [
                    {
                        name: 'Profile',
                        url: '/profile',
                        key: 'key1',
                        icon: 'EditContact',
                    },
                    {
                        name: 'Password',
                        url: '/profile/password',
                        key: 'key2',
                        icon: 'PasswordField',
                    },
                    {
                        name: 'Settings',
                        url: '/profile/settings',
                        key: 'key3',
                        icon: 'Settings',
                    },
                    {
                        name: 'Accounts',
                        url: '/profile/accounts',
                        key: 'key4',
                        icon: 'AccountManagement',
                    },
                    {
                        name: 'User Groups',
                        url: '/profile/user-groups',
                        key: 'key5',
                        icon: 'SecurityGroup',
                    },
                    {
                        name: 'Permissions',
                        url: '/profile/permissions',
                        key: 'key6',
                        icon: 'AuthenticatorApp',
                    },
                    {
                        name: 'Api',
                        url: '/profile/api',
                        key: 'key7',
                        icon: 'Link',
                    },
                ],
            },
            isAdmin
                ? {
                      name: 'Admin',
                      isExpanded: true,
                      links: [
                          {
                              name: 'Groups',
                              url: '/profile/groups',
                              key: 'key11',
                              icon: 'SecurityGroup',
                          },
                      ],
                  }
                : null,
        ].filter((a) => !!a),
    },
]

const navStyles = {
    root: {
        width: 280,
        minHeight: 350,
        boxSizing: 'border-box',
        border: '1px solid #eee',
        overflowY: 'auto',
    },
}

export default Profile
