import React from 'react'

const FormContext = React.createContext({})

function FormProvider(props) {
    const [params, setParams] = React.useState({})

    const onChangeParams = React.useCallback(
        (name, value) => setParams((values) => ({ ...values, [name]: value })),
        [setParams],
    )

    const resetParams = React.useCallback(() => {
        setParams({})
    }, [setParams])

    return (
        <FormContext.Provider
            value={{
                params,
                onChangeParams,
                resetParams,
                setParams,
            }}
            {...props}
        />
    )
}

const useForm = () => React.useContext(FormContext)

export { FormProvider, useForm }
