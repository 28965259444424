import { authGet, authPatch, authPost, getData, refreshToken } from '../common'

export const getNotifications = (userId) =>
    authGet({ url: `/user/${userId}/notifications` }).then(getData)

export const getNotificationsCount = (userId) =>
    authGet({ url: `/user/${userId}/notificationsCount` }).then(getData)

export const readNotifications = (userId, ids = []) =>
    authPost({ url: `/user/${userId}/notificationsRead`, data: ids }).then(
        getData,
    )

export const readAllNotifications = (userId) =>
    authPost({ url: `/user/${userId}/notificationsReadAll` }).then(getData)

export const deleteNotifications = (userId, ids = []) =>
    authPost({ url: `/user/${userId}/notificationsDelete`, data: ids }).then(
        getData,
    )

export const getUser = (userId) => {
    return authGet({
        url: `/user/${userId}`,
    }).then(getData)
}

export const getUserPermissions = (userId) => {
    return authGet({
        url: `/user/${userId}/permissions`,
    }).then(getData)
}

export const getUserGroups = (userId) => {
    return authGet({
        url: `/user/${userId}/groups`,
    }).then(getData)
}

export const addUserAccountRole = (userId, accountId, role = undefined) => {
    return authPost({
        url: `/user/${userId}/roles`,
        data: {
            accountId,
            role,
        },
    }).then(getData)
}

export const patchProfile = (userId, values) => {
    return authPatch({
        url: `/user/${userId}`,
        headers: { 'X-Authorization': `Bearer ${refreshToken}` },
        json: true,
        data: values,
    }).then(getData)
}

export const addUserGroups = (userId, payload) => {
    return authPost({
        url: `/user/${userId}/groups`,
        json: true,
        data: payload,
    }).then(getData)
}

export const addUserGroupsEmail = (payload) => {
    return authPost({
        url: `/user/groups-email`,
        json: true,
        data: payload,
    }).then(getData)
}
