import React from 'react'
import { CommandBar, Panel, PanelType } from '@fluentui/react'

import {
    populateActivity,
    buildActivityFileExport,
} from '../../../lib/activity'
import { downloadFile } from '../../../lib/file'

import ActivityDetailsList from '../../../components/ActivityDetailsList'

const RoleActivityPanel = ({
    open = false,
    setOpenTrue = () => {},
    setOpenFalse = () => {},
    transactionActivity = [],
    role = null,
}) => {
    const activity = React.useMemo(() => {
        return transactionActivity
            .filter(({ user }) => (role ? user._id === role.user._id : true))
            .map(populateActivity)
    }, [transactionActivity, role])

    const handleExport = async () => {
        const user = `${role.user._id}-${role.name}`
        const timestamp = new Date().toISOString()
        const fileName = `Transaction-Activity-${user}-${timestamp}.csv`
        const downloadContent = buildActivityFileExport(activity)

        await downloadFile(fileName, 'text/csv', downloadContent)
    }

    const commands = [
        {
            key: 'close',
            name: 'Close',
            iconProps: { iconName: 'Cancel' },
            onClick: setOpenFalse,
        },
        {
            key: 'export',
            name: 'Export',
            iconProps: { iconName: 'Export' },
            onClick: handleExport,
        },
    ]

    console.log(activity, transactionActivity, role)
    return (
        <Panel
            isOpen={open}
            headerText='Activity Summary'
            type={PanelType.large}
            onDismiss={setOpenFalse}>
            <CommandBar items={commands} />
            <ActivityDetailsList activity={activity} />
        </Panel>
    )
}

export default RoleActivityPanel
