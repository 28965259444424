import React from 'react'
import { Stack, Icon, Text, PrimaryButton, IconButton } from '@fluentui/react'
import { capitalize } from '../../../lib/string'
import { formatShortDateTime } from '../../../lib/date'
import { downloadUpload, getTemplate, nudgeTransaction } from '../../../api'
import Template from '../Template'
import { useStateMachine } from '../../../hooks/useStateMachine'
import { getExtension } from '../../../lib/file'
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons'

const EmailPreview = ({
    transaction,
    transactionData,
    transactionHistory,
    emailData = {},
}) => {
    const currentStage = transaction.stages[transaction.currentStage]
    const portalTemplate = currentStage.email.portalTemplate
    const transactionId = transaction._id

    const [loading, setLoading] = React.useState(true)
    const [template, setTemplate] = React.useState(null)
    const sm = useStateMachine()

    React.useEffect(() => {
        setLoading(true)
        getTemplate(portalTemplate).then((template) => {
            // Load template
            setTemplate(template)
            setLoading(false)

            // Load additional data
            const toSigner = emailData.signers.find(
                ({ signerKey }) => signerKey === 'to',
            )

            if (!toSigner) {
                console.error(`Cannot find to signer`)
            }

            toSigner.fieldData.forEach(({ field, values }) => {
                sm.setFieldValue(field, values)
            })
        })
    }, [portalTemplate, setTemplate])

    console.log('emailData11', emailData)
    if (loading) {
        return <div>Loading...</div>
    }

    const onClickAccept = () => {
        const payload = {
            type: 'email-accept',
            data: {
                envelopeId: emailData.envelopeId,
            },
        }
        nudgeTransaction(transactionId, payload).then((data) => {
            console.log('data', data)
        })
    }

    const toSigner = emailData.signers.find(
        ({ signerKey }) => signerKey === 'to',
    )
    const { files = [], additionalFiles = [] } = toSigner
    console.log('emailData', emailData, files)

    const allFiles = files.concat(additionalFiles)

    return (
        <Stack tokens={{ childrenGap: 10 }} style={{ minWidth: '50%' }}>
            <Template contents={template.content.contents} readonly={true} />
            {allFiles ? <UploadFiles files={allFiles} /> : null}
            <PrimaryButton onClick={onClickAccept}>Accept</PrimaryButton>
        </Stack>
    )
}

const UploadFiles = ({ files }) => {
    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Text variant='xLarge'>Uploaded Files</Text>
            {files.map((file, i) => {
                return <File key={i} file={file} />
            })}
        </Stack>
    )
}

const documentStyle = {
    border: '2px solid lightgrey',
    padding: 10,
    marginBottom: 5,
    borderRadius: 5,
}

const File = ({ file }) => {
    console.log('File', file)
    const extension = getExtension(file.filename)
    const actualFileName = file.filename.replace(/.*\//, '')
    const onClickDownload = () => {
        downloadUpload(actualFileName, file.filename)
    }

    return (
        <div style={documentStyle}>
            <Stack
                horizontal
                verticalAlign='center'
                tokens={{ childrenGap: 10 }}>
                <Icon
                    {...getFileTypeIconProps({
                        extension,
                        size: 24,
                        imageFileType: 'png',
                    })}
                />
                <Text style={{ flexGrow: 1 }}>{actualFileName}</Text>
                <IconButton
                    onClick={onClickDownload}
                    iconProps={{ iconName: 'Download' }}
                />
            </Stack>
        </div>
    )
}
export default EmailPreview
