import React from 'react'
import PropTypes from 'prop-types'
import { Stack, TextField, Checkbox } from '@fluentui/react'

const stackTokens = {
    horizontalAlign: 'center',
    childrenGap: 15,
    maxWidth: 800,
    padding: 10,
}
const stackStyles = {
    root: {
        minheight: 500,
    },
}

const fields = [
    {
        type: 'text',
        name: 'name',
        label: 'Field ID',
        readOnly: true,
    },
    {
        type: 'text',
        name: 'title',
        label: 'Name',
        readOnly: true,
    },
    {
        type: 'text',
        name: 'type',
        label: 'Type',
        readOnly: true,
    },
    {
        type: 'text',
        name: 'help',
        label: 'Help',
        readOnly: true,
        multiline: true,
        rows: 3,
    },
    {
        type: 'text',
        name: 'helplink',
        label: 'Help Link',
        readOnly: true,
    },
    {
        type: 'text',
        name: 'format',
        label: 'Format',
        readOnly: true,
    },
    {
        type: 'text',
        name: 'default',
        label: 'Default',
        readOnly: true,
        expand: 'expandDefaults',
    },
    {
        type: 'checkbox',
        name: 'mandatory',
        label: 'Mandatory',
    },
    {
        type: 'checkbox',
        name: 'required',
        label: 'Required',
    },
    {
        type: 'checkbox',
        name: 'multiple',
        label: 'Multiple',
    },
    {
        type: 'text',
        name: 'minLength',
        label: 'Min Length',
        readOnly: true,
    },
    {
        type: 'text',
        name: 'maxLength',
        label: 'Max Length',
        readOnly: true,
    },
    {
        type: 'text',
        name: 'calc',
        label: 'Calc',
        readOnly: true,
    },
    {
        type: 'text',
        name: 'items',
        label: 'Items',
        readOnly: true,
        expand: 'expandItems',
    },
]

const expandDefaults = (itemObj) => {
    if (typeof itemObj === 'object' && itemObj !== null) {
        let itemStr = ''
        for (let key = 0; key < itemObj.length; key += 1) {
            itemStr = `${itemStr + itemObj[key]}; \n`
        }
        return itemStr
    }
    return ''
}

const expandItems = (itemObj) => {
    if (typeof itemObj === 'object' && itemObj !== null) {
        let itemStr = ''
        for (let key = 0; key < itemObj.length; key += 1) {
            itemStr = `${itemStr + itemObj[key].value}: ${itemObj[key].title}`
            if (itemObj[key].exclude) {
                itemStr = `${itemStr} (exclude: ${itemObj[key].exclude})`
            }
            itemStr += '; \n'
        }
        return itemStr
    }
    return ''
}

const displayRows = (itemObj) => {
    if (typeof itemObj === 'object' && itemObj !== null) return itemObj.length
    return 1
}

const FieldItemMapping = {
    text: TextField,
    checkbox: Checkbox,
}

const ExpandFunctionMapping = {
    expandItems,
    expandDefaults,
}

const FieldItem = ({
    type,
    name,
    label,
    readOnly,
    expand,
    multiline,
    rows,
    schema,
}) => {
    const Element = FieldItemMapping[type]

    const props = {
        readOnly,
        label,
        disabled: typeof schema[name] === 'undefined',
    }

    if (type == 'checkbox') {
        Object.assign(props, { checked: schema[name] || false })
    } else if (type == 'text') {
        if (expand) {
            const dRows = displayRows(schema[name])
            const expandFunc = ExpandFunctionMapping[expand]
            Object.assign(props, {
                defaultValue: expandFunc(schema[name]),
                multiline: dRows > 1,
                rows: dRows,
            })
        } else {
            Object.assign(props, {
                multiline,
                rows,
                defaultValue: schema[name],
            })
        }
    }

    return <Element {...props} />
}

const FieldPanel = (props) => {
    const { schema } = props
    return (
        <Stack tokens={stackTokens} styles={stackStyles}>
            {fields.map((field, i) => (
                <FieldItem key={i} schema={schema} {...field} />
            ))}
        </Stack>
    )
}

FieldPanel.propTypes = {
    schema: PropTypes.any,
}

export default FieldPanel
