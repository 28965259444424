import React from 'react'

import {
    Modal,
    Stack,
    Dropdown,
    IconButton,
    PrimaryButton,
    DefaultButton,
    TextField,
} from '@fluentui/react'

import { assignRoleAccount } from '../../../api'
import { useAuth } from '@/hooks/useAuth'

const AssignRoleModal = ({
    isOpen,
    onClose = () => {},
    accountId = null,
    loadData = () => {},
    user = null,
}) => {
    const title = 'Assign Role'
    const [data, setData] = React.useState({ role: '' })

    const onChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setData((data) => {
            return Object.assign({}, data, { [name]: value })
        })
    }

    const onAssign = () => {
        console.log('DATA', data)
        // Ensure roles are lowercase
        data.role = data.role.toLowerCase()
        const payload = {
            userId: user._id,
            ...data,
        }

        assignRoleAccount(accountId, payload).then((res) => {
            console.log('RES', res)
            setData({})
            loadData()
            onClose()
        })
    }

    if (!user) return null

    return (
        <Modal
            isOpen={isOpen}
            style={{ padding: 50 }}
            onDismiss={onClose}
            isBlocking={false}>
            <Stack
                horizontalAlign='center'
                style={{ padding: '1em' }}
                tokens={{ childrenGap: '1em' }}>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}>
                    <h1 style={{ flexGrow: 1, margin: 0 }}>{title}</h1>
                    <IconButton
                        // styles={iconButtonStyles}
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel='Close'
                        onClick={onClose}
                    />
                </div>
                <p>
                    Please enter the role you would like to assign to:{' '}
                    {user.email}
                </p>
                <TextField
                    name='role'
                    label='Role'
                    required
                    styles={{ root: { width: '100%' } }}
                    value={data.role}
                    onChange={onChange}
                />
                <Stack
                    horizontal
                    style={{ width: '100%' }}
                    tokens={{ childrenGap: '1em' }}
                    horizontalAlign='end'>
                    <PrimaryButton onClick={onAssign}>Assign</PrimaryButton>
                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                </Stack>
            </Stack>
        </Modal>
    )
}

export default AssignRoleModal
