import React from 'react'
import {
    Stack,
    TextField,
    PrimaryButton,
    Icon,
    Text,
    Persona,
    PersonaSize,
    Spinner,
    SpinnerSize,
    CommandBar,
    DefaultButton,
} from '@fluentui/react'
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons'
import { capitalize } from '../../../lib/string'
import { nudgeTransaction } from '../../../api'
import { useNavigate } from 'react-router-dom'
import DocusignOverview from './DocusignOverview.js'

import RollbackModal from '../RollbackModal.js'
import { getRolesData } from '../../../lib/roles'
import RoleAssignPanel from '../../../views/Transaction/Roles/RoleAssignPanel'
import { useAuth } from '../../../hooks/useAuth.js'
import { useStateMachine } from '../../../hooks/useStateMachine.js'

const resolveDocuments = (transactionFiles, documents, sm) => {
    const files = []
    for (const document of documents) {
        console.log('Document', document)
        if (document.when) {
            if (!sm.calcWhen(document.when)) {
                continue
            }
        }
        if (document.document) {
            const foundFile = transactionFiles.find(
                (transactionFile) =>
                    transactionFile.filename === document.document,
            )
            files.push(foundFile)
        } else if (document.documentTags) {
            const foundFiles = transactionFiles.filter((transactionFile) =>
                document.documentTags.every((tag) =>
                    transactionFile.tags.includes(tag),
                ),
            )

            for (const foundFile of foundFiles) {
                files.push(foundFile)
            }
        }
    }
    return files
}

const Docusign = ({
    transaction,
    transactionFiles = [],
    loadData,
    transactionHistory = [],
}) => {
    const sm = useStateMachine()
    const { userId } = useAuth()
    const navigate = useNavigate()
    const [data, setDataRaw] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [serverError, setServerError] = React.useState(null)
    const [rollbackModelOpen, setRollbackModelOpen] = React.useState(false)

    const setValue = (signer, field, value) => {
        setDataRaw((dataIn) => {
            const data = { ...dataIn }
            if (!data[signer]) {
                data[signer] = {}
            }

            data[signer][field] = value
            return data
        })
    }

    console.log('transactionFiles', transactionFiles)

    const getValue = (signer, field) =>
        data[signer] ? data[signer][field] || '' : ''

    const hasRole = (role) =>
        transaction.roles.find(({ name }) => name === role)

    const findFile = ({ document }) =>
        transactionFiles.find(
            (transactionFile) => transactionFile.filename === document,
        )

    const currentStage = transaction.stages[transaction.currentStage] || {}

    const lastSave = transactionHistory.slice(-1)[0]
    const docusignLastSave =
        lastSave?.type === 'docusign' &&
        lastSave?.stage === transaction.currentStage &&
        lastSave?.status === 'valid'
    const docusignData = lastSave.docusignData

    console.log('docusignData', docusignData)

    let content
    const status = docusignLastSave ? docusignData.status : ''
    const documents = currentStage.documents

    // unique files
    const files = Array.from(
        new Set(resolveDocuments(transactionFiles, documents, sm)),
    )
    console.log('documents', documents, files)

    const signers = docusignLastSave
        ? docusignData.signers
        : currentStage.signers

    const signersData = Object.keys(signers || {}).map((signer) => {
        const who = signers[signer]
        if (who.role) {
            return {
                role: true,
                hasRole: hasRole(who.role),
                who: who.role,
                roleType: who.roleType,
            }
        }
    })

    // console.log('ROLESSIGN', signers, signersData)

    const onSubmit = () => {
        if (loading) return
        const filesTrimmed = files
            .filter((a) => !!a)
            .map(({ filename, extension, upload }) => {
                return { filename, extension, upload }
            })

        const payload = {
            type: 'docusign',
            data: {
                signers: data,
                files: filesTrimmed,
            },
        }
        setLoading(true)
        nudgeTransaction(transaction._id, payload)
            .then(() => loadData())
            .catch((err) => {
                setLoading(false)
                setServerError({
                    title: 'Error sending DocuSign envelope',
                    details: err,
                })
            })
    }

    if (['sent', 'declined'].includes(status)) {
        content = (
            <Stack tokens={{ childrenGap: 10 }} horizontalAlign='center'>
                <h1>Document sent via DocuSign</h1>
                <p>Please check back later</p>
                <DocusignOverview docusignData={docusignData} />
                <Stack
                    style={{ color: '#aaa' }}
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign='center'>
                    <p>Envelope ID: {docusignData.envelopeId}</p>
                    {docusignData.updated ? (
                        <p>Last Updated: {currentStage.updated}</p>
                    ) : null}
                </Stack>
            </Stack>
        )
    } else {
        const currentRole = transaction.roles.find(
            ({ name }) => name === transaction.currentRole,
        )

        const isWithCurrentUser = currentRole
            ? userId === currentRole.user._id
            : false
        console.log('isWithCurrentUser', isWithCurrentUser)
        content = (
            <Stack tokens={{ childrenGap: 10 }} verticalAlign='center'>
                <Text>Documents in envelope:</Text>
                <div>
                    {files.map((document, i) => {
                        return (
                            <Document
                                key={i}
                                document={document}
                                signers={signers}
                            />
                        )
                    })}
                </div>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Text>Signers:</Text>
                    {signersData.map((signer, i) => {
                        return (
                            <Signer
                                key={i}
                                signer={signer}
                                roles={transaction.roles}
                                setValue={setValue}
                                getValue={getValue}
                                transaction={transaction}
                                loadData={loadData}
                                readonly={!isWithCurrentUser}
                            />
                        )
                    })}
                </Stack>
                {isWithCurrentUser ? (
                    <PrimaryButton disabled={loading} onClick={onSubmit}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            {loading ? (
                                <Spinner size={SpinnerSize.small} />
                            ) : null}
                            <span>{loading ? 'Sending' : 'Send'}</span>
                        </Stack>
                    </PrimaryButton>
                ) : null}
                {serverError ? (
                    <p style={{ color: 'red' }}>{serverError.title}</p>
                ) : null}
            </Stack>
        )
    }

    const actions = [
        {
            key: 'back',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: () => {
                navigate(-1)
            },
        },
        {
            key: 'rollback',
            text: 'Rollback',
            iconProps: { iconName: 'Rewind' },
            onClick: () => {
                setRollbackModelOpen(true)
            },
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {
                loadData()
            },
        },
    ]

    return (
        <>
            <CommandBar items={actions} />
            <Stack horizontalAlign='center' style={{ minHeight: 500 }}>
                <img
                    width='400'
                    src='https://www.docusign.co.uk/sites/default/files/docusign_logo_black_text_on_white_0.png'
                />
                {content}
            </Stack>
            <RollbackModal
                isOpen={rollbackModelOpen}
                onClose={() => setRollbackModelOpen(false)}
                transaction={transaction}
            />
        </>
    )
}

const Signer = ({
    signer,
    roles,
    setValue,
    getValue,
    transaction,
    loadData = () => {},
    readonly = false,
}) => {
    const title = capitalize(signer.who)
    const [assignPanelOpen, setAssignPanelOpen] = React.useState(false)

    const getRole = (role) => {
        return roles.find(({ name }) => name === role)
    }

    if (signer.role && signer.hasRole) {
        const role = getRole(signer.who)
        const user = role.user
        return (
            <Stack
                horizontal
                tokens={{ childrenGap: 10 }}
                horizontalAlign='space-between'>
                <Text variant='large'>{title}:</Text>
                <Persona
                    text={`${user.firstName} ${user.lastName}`}
                    size={PersonaSize.size24}
                />
            </Stack>
        )
    } else if (signer.role) {
        const role = getRolesData(transaction).find(
            (role) => role.role === signer.who,
        )
        const name = getValue(signer.who, 'name')
        const email = getValue(signer.who, 'email')

        const roleType = signer.roleType || 'any'
        const hasRoleTypeRole = ['any', 'role'].includes(roleType)
        const hasRoleTypeTmp = ['any', 'tmp'].includes(roleType)
        // console.log(
        //     'ROLES123',
        //     roles,
        //     signer,
        //     role,
        //     roleType,
        //     hasRoleTypeRole,
        //     hasRoleTypeTmp,
        // )
        return (
            <div>
                <Text variant='large' block>
                    {title}
                </Text>
                {!readonly ? (
                    <>
                        {hasRoleTypeRole ? (
                            <>
                                <DefaultButton onClick={setAssignPanelOpen}>
                                    Assign Role
                                </DefaultButton>
                                <RoleAssignPanel
                                    transactionId={transaction._id}
                                    open={assignPanelOpen}
                                    roles={[role]}
                                    setOpenFalse={() =>
                                        setAssignPanelOpen(false)
                                    }
                                    loadData={loadData}
                                />
                            </>
                        ) : null}

                        {hasRoleTypeTmp ? (
                            <>
                                <TextField
                                    label={title + ' Name'}
                                    style={{ width: 300 }}
                                    required
                                    value={name}
                                    onChange={(e, v) =>
                                        setValue(signer.who, 'name', v)
                                    }
                                />
                                <TextField
                                    label={title + ' Email'}
                                    style={{ width: 300 }}
                                    required
                                    value={email}
                                    onChange={(e, v) =>
                                        setValue(signer.who, 'email', v)
                                    }
                                />
                            </>
                        ) : null}
                    </>
                ) : null}
            </div>
        )
    } else if (signer.email) {
        return (
            <div>
                <Text variant='large'>{title}</Text>
            </div>
        )
    }

    return null
}

const documentStyle = {
    border: '2px solid lightgrey',
    padding: 10,
    marginBottom: 5,
    borderRadius: 5,
}

const Document = ({ document, signers }) => {
    const [filename, extension] = parseFilename(document.filename)

    return (
        <Stack style={documentStyle}>
            <File filename={filename} extension={extension} />
            {/* <SignersList document={document} signers={signers} /> */}
        </Stack>
    )
}

const SignersList = ({ document, signers }) => {
    const content = document.signers.map((signer, i) => {
        return <span key={i}>{capitalize(signers[signer].role)}</span>
    })
    return (
        <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign='center'>
            <Text variant='small'>Signed by: </Text>
            <Stack horizontal tokens={{ childrenGap: 5 }}>
                {content}
            </Stack>
        </Stack>
    )
}

const File = ({ filename, extension = 'txt' }) => {
    return (
        <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
            <Icon
                {...getFileTypeIconProps({
                    extension,
                    size: 24,
                    imageFileType: 'png',
                })}
            />
            <Text>{filename}</Text>
        </Stack>
    )
}

const parseFilename = (filename) => {
    const parts = filename.split('.')
    return [parts.slice(0, -1).join('.'), parts.slice(-1)[0]]
}

/*
<Stack key={i}>
    <Persona text={signer.name} size={PersonaSize.size48} />
</Stack>
*/

export default Docusign
