import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Panel, getId, IconButton } from '@fluentui/react'

import FieldPanel from './FieldPanel'
import FieldDetailsButton from './FieldDetailsButton'
import FieldHelpButton from './FieldHelpButton'
import HelpPanel from './HelpPanel'
import { useSettings } from '../../../hooks/useSettingsNew'
import FieldCalcButton from './FieldCalcButton'
import CalcPanel from './CalcPanel'

const FieldHelper = (_props) => {
    const [helpVisible, setHelpVisible] = React.useState(false)
    const [calcVisible, setCalcVisible] = React.useState(false)
    const [panelVisible, setPanelVisible] = React.useState(false)
    const { getSetting } = useSettings()
    const showFieldDetails = getSetting('showFieldDetails', false)
    const showFieldCalc = getSetting('showFieldCalc', false)
    const showFieldHelp = getSetting('showFieldHelp', true)

    const iconButtonId = getId('iconButton')
    const calcButtonId = getId('calcButton')
    const labelId = getId('help-title')
    const descriptionId = getId('help-text')
    const panelButtonId = getId('panel-button-id')
    const fieldId = getId('field-id')

    const onIconClick = () => setHelpVisible(!helpVisible)
    const onPanelClick = () => setPanelVisible(!panelVisible)
    const onCalcClick = () => setCalcVisible(!calcVisible)
    const onHelpDismiss = () => setHelpVisible(false)
    const onPanelDismiss = () => setPanelVisible(false)
    const onCalcDismiss = () => setCalcVisible(false)

    const { props, schema, defaultRender } = _props
    const { help, calc } = schema

    return (
        <>
            <Stack horizontal verticalAlign='center'>
                <span>{defaultRender(props)}</span>
                {showFieldHelp && help && (
                    <FieldHelpButton id={iconButtonId} onClick={onIconClick} />
                )}
                {showFieldDetails ? (
                    <FieldDetailsButton
                        id={panelButtonId}
                        onClick={onPanelClick}
                    />
                ) : null}
                {calc && showFieldCalc ? (
                    <FieldCalcButton id={calcButtonId} onClick={onCalcClick} />
                ) : null}
            </Stack>
            {helpVisible && (
                <HelpPanel
                    labelId={labelId}
                    descriptionId={descriptionId}
                    onDismiss={onHelpDismiss}
                    targetId={iconButtonId}
                    schema={schema}
                    fieldId={fieldId}
                />
            )}

            {calcVisible && calc ? (
                <CalcPanel
                    labelId={labelId}
                    descriptionId={descriptionId}
                    onDismiss={onCalcDismiss}
                    targetId={calcButtonId}
                    schema={schema}
                    fieldId={fieldId}
                />
            ) : null}
            <Panel
                headerText='Field details'
                isBlocking={false}
                isOpen={panelVisible}
                onDismiss={onPanelDismiss}
                closeButtonAriaLabel='Close'>
                <FieldPanel schema={schema} />
            </Panel>
        </>
    )
}

FieldHelper.propTypes = {
    props: PropTypes.object.isRequired,
    defaultRender: PropTypes.func.isRequired,
}

export default FieldHelper
