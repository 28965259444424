import React from 'react'
import { CommandBar } from '@fluentui/react'

const noop = () => {}
const item = (expr, item, other = null) => {
    return expr ? item : other
}

const TemplateContentCommandBar = ({
    onRefresh = noop,
    onSave = noop,
    onBack = noop,
    onCancel = noop,
    onDocument = noop,
    onSend = noop,
    onSubmit = noop,
    onApprove = noop,
    onRollback = noop,
    showCancel = false,
    showSave = false,
    showDocument = false,
    showSubmit = false,
    showSend = false,
    showApprove = false,
    showRollback = false,
    documentDisabled = false,
    submitDisabled = false,
    sendDisabled = false,
    approveDisabled = false,
    cancelDisabled = false,
    rollbackDisabled = false,
}) => {
    const commands = [
        {
            key: 'back',
            text: 'Back',
            iconProps: { iconName: 'Back' },
            onClick: onBack,
        },
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: onRefresh,
        },
        item(showSave, {
            key: 'save',
            text: 'Save',
            iconProps: { iconName: 'Save' },
            onClick: onSave,
        }),
        item(showCancel, {
            key: 'cancel',
            text: 'Cancel',
            iconProps: { iconName: 'Cancel' },
            disabled: cancelDisabled,
            onClick: onCancel,
        }),
        item(showDocument, {
            key: 'document',
            text: 'Open Document',
            iconProps: { iconName: 'WordDocument' },
            onClick: onDocument,
            disabled: documentDisabled,
        }),
        item(showSubmit, {
            key: 'submit',
            text: 'Submit',
            iconProps: { iconName: 'Play' },
            onClick: onSubmit,
            disabled: submitDisabled,
        }),
        item(showSend, {
            key: 'send',
            text: 'Send',
            iconProps: { iconName: 'Play' },
            onClick: onSend,
            disabled: sendDisabled,
        }),
        item(showApprove, {
            key: 'approve',
            text: 'Next Stage',
            iconProps: { iconName: 'DocumentApproval' },
            onClick: onApprove,
            disabled: approveDisabled,
        }),

        item(showRollback, {
            key: 'rollback',
            text: 'Rollback',
            iconProps: { iconName: 'Rewind' },
            onClick: onRollback,
            disabled: rollbackDisabled,
        }),
        // {
        //     key: 'recall',
        //     text: 'Recall',
        //     iconProps: { iconName: 'Undo' },
        //     disabled: true,
        // },
        // {
        //     key: 'archive',
        //     text: 'Archive',
        //     iconProps: { iconName: 'Delete' },
        //     disabled: true,
        // },
    ].filter((a) => !!a)

    return <CommandBar items={commands} style={{ flexGrow: 1 }} />
}

export default TemplateContentCommandBar
