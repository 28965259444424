/* global getProfiles loadProfile */
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useId } from '@fluentui/react-hooks'
import {
    Persona,
    Stack,
    DefaultButton,
    Callout,
    PersonaSize,
    Nav,
} from '@fluentui/react'

import { useAuth } from '../../hooks/useAuth'
import Notifications from './Notifications.js'

const styles = {
    root: {
        cursor: 'pointer',
    },
    primaryText: {
        color: 'white',
        textTransform: 'capitalize',
    },
}

const headerStyle = {
    background: '#000000',
    color: '#fff',
    overflow: 'hidden',
    borderBottom: '2px solid #ec008c',
    padding: '5px 0',
}

const Logo = () => (
    <Link to='/'>
        <img
            src='/images/logo-inverse.svg'
            alt='leasle Logo'
            style={{ width: 200 }}
        />
    </Link>
)

const Header = () => {
    const { loggedIn, firstName, lastName, currentAccount } = useAuth()
    const menuRef = React.useRef(null)

    const navigate = useNavigate()

    // Account name for header
    let accountName = null
    if (currentAccount?.account) {
        accountName = currentAccount.account.name
    }

    const [menuOpen, setMenuOpen] = React.useState(false)
    const toggleUserMenu = () => {
        setMenuOpen((menuOpen) => !menuOpen)
    }

    const goTo = (url) => {
        return () => {
            setMenuOpen(false)
            navigate(url)
        }
    }

    const loggedOutMenu = !loggedIn ? (
        <>
            <DefaultButton onClick={goTo('/login')}>Login</DefaultButton>
            <DefaultButton onClick={goTo('/signup')}>Signup</DefaultButton>
        </>
    ) : null

    const buttonId = useId('callout-button')

    const loggedInMenu = loggedIn ? (
        <>
            <Persona
                id={buttonId}
                ref={menuRef}
                text={`${firstName} ${lastName}`}
                secondaryText={accountName}
                styles={styles}
                onMouseUp={toggleUserMenu}
            />
            {menuOpen && (
                <UserCallout
                    target={`#${buttonId}`}
                    onDismiss={toggleUserMenu}
                />
            )}
            <Notifications />
        </>
    ) : null

    return (
        <div style={headerStyle}>
            <Stack
                verticalAlign='center'
                horizontal
                tokens={{ padding: '10px 20px' }}>
                <Stack>
                    <Logo />
                </Stack>
                <Stack grow />
                <Stack horizontal>
                    {loggedOutMenu}
                    {loggedInMenu}
                </Stack>
            </Stack>
        </div>
    )
}

const calloutStyle = {
    minWidth: 300,
}

const UserCallout = ({ target, onDismiss }) => {
    const auth = useAuth()

    const profiles = getProfiles().map((name) => ({
        key: `profile-${name}`,
        name: `Profile ${name}`,
        onClick: () => loadProfile(name),
        icon: 'Contact',
    }))

    const onClickAccount = (i) => {
        return () => {
            auth.setCurrentAccount(auth.accountGroups[i].account._id)
        }
    }

    const navLinkGroups = [
        {
            links: auth.accountGroups.map((accountGroup, i) => {
                return {
                    name: accountGroup.account.name,
                    key: `account-${i}`,
                    onClick: onClickAccount(i),
                }
            }),
        },
        {
            links: [
                {
                    name: 'Profile & Settings',
                    key: 'profile',
                    url: '/profile',
                    icon: 'Settings',
                },
                {
                    name: 'Logout',
                    key: 'logout',
                    url: '/logout',
                    icon: 'Leave',
                },
                ...profiles,
            ],
        },
    ]

    return (
        <Callout
            role='dialog'
            gapSpace={0}
            target={target}
            onDismiss={onDismiss}
            setInitialFocus
            style={calloutStyle}>
            <Stack style={{ padding: 20 }} tokens={{ childrenGap: 10 }}>
                <Persona
                    text={`${auth.firstName} ${auth.lastName}`}
                    secondaryText={auth.email}
                    size={PersonaSize.size56}
                />
                <Nav
                    selectedKey='key3'
                    groups={navLinkGroups}
                    styles={{ groupContent: { marginBottom: 0 } }}
                />
            </Stack>

            {/* <pre>{JSON.stringify(auth, null, 4)}</pre> */}
        </Callout>
    )
}

export default Header
