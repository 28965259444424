import React from 'react'
import { IconButton, getTheme } from '@fluentui/react'

const theme = getTheme()

const FieldCalcButton = ({ id, onClick = () => {} }) => (
    <IconButton
        id={id}
        iconProps={{ iconName: 'DataManagementSettings' }}
        tabIndex='-1'
        title='Calculation details'
        ariaLabel='Calculation details'
        onClick={onClick}
        styles={{
            root: {
                marginBottom: -3,
                color: theme.palette.themeLight,
            },
        }}
    />
)

export default FieldCalcButton
