import React, { useCallback, useEffect } from 'react'

const useUnload = (onUnload = () => {}) => {
    const callback = useCallback((event) => {
        event.preventDefault()

        onUnload()

        return false
    }, [])

    useEffect(() => {
        document.addEventListener('beforeunload', callback)

        return () => {
            document.removeEventListener('beforeunload', callback)
        }
    }, [callback])
}

export default useUnload
