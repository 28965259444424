import React, { useCallback, useEffect } from 'react'

const useCtrlS = (onCtrlS = () => {}) => {
    const callback = useCallback((event) => {
        // if (event.target !== document.body) return
        if (event.key && event.key.toLowerCase() !== 's') return
        if (!event.metaKey) return
        event.preventDefault()

        onCtrlS()

        return false
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', callback)

        return () => {
            document.removeEventListener('keydown', callback)
        }
    }, [callback])
}

export default useCtrlS
