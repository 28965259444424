export default {
    render: (args) => {
        const { x = 0, y = 0, color = 'lime', data = {} } = args
        const { status } = data
        const points = [
            [-8, 8],
            [8, 8],
            [0, -8],
        ]
        const relativePoints = points
            .map((point) => `${x + point[0]},${y + point[1]}`)
            .join(' ')

        const extraStyle = status === 'skipped' ? 'stroke: none;' : ''
        return `<polygon points="${relativePoints}" style="fill:${color}; ${extraStyle}" />`
    },
}
