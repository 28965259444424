import React from 'react'
import { CommandBar } from '@fluentui/react'

const noop = () => {}

const ActionBar = ({
    entity = null,
    showBack = true,
    showView = true,
    showTransactions = true,
    showCreate = true,
    showAdd = true,
    showEdit = true,
    // Disable archive for now
    showArchive = false,
    onClickBack = noop,
    onClickView = noop,
    onClickTransactions = noop,
    onClickCreate = noop,
    onClickCreateAction = noop,
    onClickAdd = noop,
    onClickEdit = noop,
    onClickArchive = noop,
    actions = [],
    style = {},
    styles = {},
}) => {
    const disabled = !entity
    const items = [
        showBack
            ? {
                  key: 'back',
                  text: 'Back',
                  iconProps: { iconName: 'Back' },
                  onClick: (...args) => {
                      onClickBack()
                  },
              }
            : null,
        showView
            ? {
                  key: 'view',
                  text: 'View',
                  iconProps: { iconName: 'View' },
                  disabled,
                  onClick: (...args) => {
                      onClickView()
                  },
              }
            : null,
        showTransactions
            ? {
                  key: 'transactions',
                  text: 'Show Transactions',
                  iconProps: { iconName: 'OpenSource' },
                  disabled,
                  onClick: (...args) => {
                      onClickTransactions()
                  },
              }
            : null,
        showCreate
            ? {
                  key: 'startTransaction',
                  text: 'Start Transaction',
                  iconProps: { iconName: 'Play' },
                  disabled,
                  onClick: (...args) => {
                      onClickCreate()
                  },
                  split: true,
                  subMenuProps: actions.length
                      ? {
                            items: actions.map((action) => {
                                return {
                                    iconProps: { iconName: 'Play' },
                                    ...action,
                                    onClick: (e) => {
                                        e.preventDefault()
                                        onClickCreateAction(action)
                                        return false
                                    },
                                }
                            }),
                        }
                      : undefined,
              }
            : null,
        showAdd
            ? {
                  key: 'add',
                  text: 'Add',
                  iconProps: { iconName: 'Add' },
                  disabled: false,
                  onClick: (...args) => {
                      onClickAdd()
                  },
              }
            : null,
        showEdit
            ? {
                  key: 'edit',
                  text: 'Edit',
                  iconProps: { iconName: 'Edit' },
                  disabled,
                  onClick: (...args) => {
                      onClickEdit()
                  },
              }
            : null,
        showArchive
            ? {
                  key: 'archive',
                  text: 'Archive',
                  iconProps: { iconName: 'Delete' },
                  disabled,
                  onClick: (...args) => {
                      onClickArchive()
                  },
              }
            : null,
    ].filter((a) => !!a)

    if (!items.length) {
        return null
    }

    return <CommandBar items={items} {...{ styles, style }} />
}

export default ActionBar
