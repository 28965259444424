import React from 'react'

import TabContent from './TabContent'
import SubTitle from '../pageparts/Subtitle'
import FieldInput from '../controls/FieldInput'
import ListTemplate from './ListTemplate'
import HTMLTemplate from './HTMLTemplate'

import { useStateMachine } from '../../hooks/useStateMachine.js'
import { useRef } from 'react'

const CONFIG_USE_NEW_TEMPLATE = false

const Template = (props) => {
    const { style = {} } = props
    const sm = useStateMachine()
    const rootRef = useRef()
    if (CONFIG_USE_NEW_TEMPLATE) {
        React.useEffect(() => {
            console.log('Im here', rootRef, sm)
            rootRef.current.replaceChildren(sm.renderTemplate())
        }, [])
        return (
            <div id='template-root' ref={rootRef}>
                Hello
            </div>
        )
    } else {
        return (
            <div style={Object.assign({}, { position: 'relative' }, style)}>
                {props.contents.map((content, i) => (
                    <TemplateItem key={i} {...props} content={content} />
                ))}
            </div>
        )
    }
}

const calcPermissions = (roles = [], perms = { '*': 'edit' }) => {
    if (roles.length === 0) return 'edit'

    const foundPermissions = []
    roles.forEach((role) => {
        if (role in perms) {
            foundPermissions.push(perms[role])
        }
    })

    if (foundPermissions.length) {
        if (foundPermissions.includes('edit')) return 'edit'
        if (foundPermissions.includes('view')) return 'view'
        if (foundPermissions.includes('hide')) return 'hide'
    } else {
        // Check for catch all
        if ('*' in perms) {
            return perms['*']
        }
    }

    // All else fails ?? hide?
    return 'hide'
}

const TemplateItem = (props) => {
    const { content, fields, readonly = false } = props
    const sm = useStateMachine()
    let permission = 'edit'
    // console.log('CONTENT', content)
    if (content.perms) {
        const { perms } = content
        permission = calcPermissions(sm._state.roles, perms)
        console.log(sm._state.roles, perms, permission)
        if (permission === 'hide') {
            return null
        }
    }

    if (content.type == 'FIELD') {
        const fieldDef = sm.getFieldDef(content.definitionId)
        return (
            <FieldInput
                schema={fieldDef}
                readonly={readonly || permission === 'view'}
            />
        )
    }

    if (content.type == 'TABS') {
        return (
            <TabContent content={content} fields={fields} readonly={readonly} />
        )
    }

    if (content.type == 'LIST') {
        return <ListTemplate {...props} />
    }

    if (content.type == 'HTML') {
        return <HTMLTemplate content={content.content} />
    }

    return (
        <div>
            <SubTitle title={content.title} subtitle={content.subtitle} />
            <Template
                contents={content.contents}
                fields={fields}
                readonly={readonly}
            />
        </div>
    )
}

export default Template
