import React from 'react'
import { Text, Toggle, Stack, TextField, Spinner } from '@fluentui/react'
import { useSettings } from '../../hooks/useSettingsNew'
import { useSetting } from '../../hooks/useSetting'
import { useAuth } from '../../hooks/useAuth'
import { getUser } from '../../api'

export const ApiSection = () => {
    const { getSetting, setSetting } = useSettings()
    const { userId } = useAuth()

    const [loading, setLoading] = React.useState(true)

    const [user, setUser] = React.useState(null)

    React.useEffect(() => {
        setLoading(true)
        getUser(userId)
            .then((user) => {
                console.log('user', user)
                setUser(user)
            })
            .then(() => setLoading(false))
    }, [userId])

    if (loading) {
        return <Spinner />
    }

    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <Text variant='xxLarge'>Api Settings</Text>

            <Stack>
                <Text variant='xLarge'>General</Text>
                <TextField
                    label='Api Key'
                    readOnly
                    value={user.apiKey}
                    style={{ width: 350 }}
                />
            </Stack>
        </Stack>
    )
}
